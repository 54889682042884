import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { Api, Constants, Session } from "fsy.common-library";
import {
  CRITERION_TYPE_DTE,
  CRITERION_TYPE_LOC,
  CRITERION_TYPE_NUM,
  CRITERION_TYPE_OBG,
  CRITERION_TYPE_TXT,
  HTTP_NO_CONTENT,
  HTTP_OK,
  SIMULATION_STATUS_COMPLETED,
  SIMULATION_STATUS_IN_PROGRESS,
} from "fsy.common-library/lib/env/Constants";
import Helper from "../../services/Helper";
import SessionHelper from "fsy.common-library/lib/helpers/SessionHelper";
import { routes as Routing } from "../../services/RoutesHelper";
import {
  COOKIE_QUESTION_AIDS_COUNT,
  COOKIE_RESULTS_SIMULATION,
  CRITERIA_CRIPLAN01,
  CRITERION_TYPE_SPE,
  ERR_MSG_SIMULATION_LOADING,
  ERR_MSG_SIMULATION_SAVING,
  STORAGE_ORG_UUID,
} from "../../services/Constants";
import _ from "lodash";
import moment from "moment";
import { Loading, LoadingOverlay } from "../general/form/Loading";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import BackspaceIcon from "@mui/icons-material/Backspace";
import PageviewIcon from "@mui/icons-material/Pageview";
import SimulatorQuestion from "./SimulatorQuestion";
import SimulatorMenu from "./SimulatorMenu";
import ProgressBar from "./ProgressBar";
import HomeCheckBox from "../home/HomeCheckBox";

import "./simulator.css";
import { toast } from "react-toastify";
import SimulatorError from "./simulatorError/SimulatorError";

/*
 * Fonctionnement du simulateur :
 *
 * 1. paramètre "resSim" dans l'url : utilisé pour reprendre la simulation automatiquement (sans prompt la fenêtre modale)
 * 2. UseEffect : récupère la dernière version du simulateur
 *  2.1. Si resSim -> lancer _resumeSimulation()
 *  2.1. Sinon -> lancer _startNewSimulation() ou afficher le modal (state resumeDialog=true)
 *
 * 3. si on reprends une simulation en cours :
 *  3.1. _startNewSimulation() : Créer la simulation via l'api et appelle _initializeSimulator()
 *  3.2. _initializeSimulator() : Charge le "step" et la question à afficher dans state.currentStep et state.currentQuestion
 *   3.2.1 si _initializeSimulator() n'a aucun paramètre : on charge la question 1 du step 1, sinon on va directement à la question indiquée
 *
 * 4. si on reprends une simulation en cours :
 *  4.1 _resumeSimulation() : récupère la simulation via l'api
 *  4.2 mets à jour la liste des questions (dépendances entre les questions) grâce à _updateDependencies()
 *  4.3 lance _initializeSimulator() avec le numéro de question à laquelle l'utilisateur s'est arrêté
 *
 * 5. Passage à une autre question
 *  5.1 Précédente : _goToQuestion("backward") - Aucun enregistrement, on récupère la question précédente qui peut être dans le step actuel ou la dernière du step précédent (si aucun n'est trouvée)
 *  5.2 Suivante : _nextQuestion() - Sauvegarde la question (_saveAnswer()), Mets à jour les dépendances avec _updateDependencies() et lance _goToQuestion("forward")
 *  5.3 _saveAnswer() : soit créer la réponse, soit mettre à jour (si existe en BDD)
 *
 * 6. _submitSimulation() - Arrivé à la fin des questions : mettre à jour la simulation (status "finished") et lancer la recherche des aides
 *  6.1 Une fois la recherche terminée : rediriger vers la page des résultats (grâce au state "simulationFinished"=true)
 *  6.2 L'id de simulation est transmis à la page de resultats via le cookie "COOKIE_RESULTS_SIMULATION"
 *
 * Notes:
 * ------
 * simulator: contient l'entité simulateur complète et NON MODIFIÉE
 * currentQuestionsList : contient la liste des questions modifiées en fonctions des réponses (dépendances entre les questions)
 * _updateDependencies() : copie la liste des questions depuis state.simulator et vérifie pour chaque question si les dépendances sont satisfaites par les réponses actuelles
 *                         Si oui, la question est copiée dans la liste filtrée, si non on passe à la suivante
 * _checkDependencies() : vérifie les dépendances d'une question. Un code spécifique à chaque type de réponse (TXT,OBG,NUM)
 *
 * _goToQuestion() : Une seule fonction utilisée pour avancer et reculer dans les question, d'où la complexité
 *
 * A plusieurs endroit on utilise Helper.saveRunningSimulation() -> cela mets à jour le cookie permettant de quitter et reprendre la simulation plus tard
 *
 * currentQuestion et currentStep : sont les objets utilisés pour le rendu du composant
 * currentStepPosition et currentQuestionPosition : sont des "pointeurs" permettant de savoir où on se trouve dans la liste des questions
 * currentQuestionCounter : sert à afficher le numéro de la question actuelle à l'utilisateur
 *
 */

export default function Simulator(props) {
  const [state, setState] = useState({
    loading: true,
    loadingMessage: null,
    testLoading: false,
    saving: false,
    savingOnPrevious: false,
    simulationFinished: false,
    resumeDialog: false,
    simulator: null,
    simulation: null,
    currentQuestionsList: [],
    currentQuestionPosition: 0,
    currentQuestionCounter: 0,
    currentStepPosition: 1,
    currentQuestion: null,
    currentStep: null,
    isFirstQuestion: true,
    isLastQuestion: false,
    answers: [],
    currentAnswer: null,
    answersListCount: 1,
    questionsListLength: 0,
    mandatoryQuestions: [],
    maxStepAvailable: 1,
    maxQuestionAvailable: { step: 1, question: 1 },
    isChecked: false,
    answerChanged: false,
    hasAnswered: false,
    aidsCount: null,
    aidsCountLoading: false,
    hasAnsweredToPreviousQuestion: true,
    questionAidsCount: props.withCookie
      ? Session.getCookie(COOKIE_QUESTION_AIDS_COUNT) ?? {}
      : {},
    aidsCountPositionFromRight: 0,
    noSimulator: false,
    answeredOptionalQuestionsPercentage: null,
    simulatorQuestionsCount: 0,
  });
  const allAnswersContentRef = useRef(null);
  const answersFormContentRef = useRef(null);
  const user = SessionHelper.getSessionUser();
  const uuid = Session.getLocalStorage(STORAGE_ORG_UUID);
  const runningSimulation = Helper.getRunningSimulation();
  const simulatorUuid = props.simulatorUuid;
  const userIdInSandboxMode = props.userId; // Cette variable est utilisée à la fois en mode BAC à SABLE et TEST
  const organizationUuidInSandboxMode = props.organizationUuid; // Cette variable est utilisée à la fois en mode BAC à sable et TEST

  // Mode d'utilisation du SIMULATEUR
  const isSandboxMode = props.mode === Constants.SIMULATOR_SANDBOX_MODE;
  const isTestMode = props.mode === Constants.SIMULATOR_TEST_MODE;
  const isOthersMode = isSandboxMode || isTestMode;

  const urlParams = new URLSearchParams(window.location.search);
  const resSim = urlParams.get("resSim");
  const simulationIdInSandboxMode = urlParams.get("simulationId");

  const simulatorStepsLength = Object.values(
    state.simulator?.steps || []
  ).length;

  if (!isOthersMode) {
    window.history.replaceState({}, "", Routing.simulator);
  }

  const nextButtonRef = useRef(null);

  const _setState = (values, callback = null) => {
    setState((previousState) => {
      return { ...previousState, ...values };
    });
    if (callback !== null && typeof callback === "function") {
      callback();
    }
  };

  const _initializeSimulator = (
    simulatorSteps,
    stepNumber = 1,
    questionNumber = 1,
    answers = []
  ) => {
    let step = _.find(simulatorSteps, { position: stepNumber ?? 1 });
    let questionIndex = _.findIndex(step?.questions, {
      position: questionNumber ?? 1,
    });

    let answersCount = questionIndex + 1;
    if (stepNumber > 1) {
      for (let i = 0; i < stepNumber; i++) {
        answersCount += simulatorSteps[i]?.questions.length;
      }
    }

    const currentQuestion = step?.questions[questionIndex];

    if (currentQuestion && answers.length) {
      currentQuestion.availableAnswers = _getAvailableAnswers(
        currentQuestion,
        answers
      );
    }

    let questionsCount = 0;
    if (state.currentQuestionsList?.length === 0) {
      Object.values(simulatorSteps)?.map((step) => {
        questionsCount += step?.questions?.length;

        return null;
      });
    } else {
      questionsCount = _getQuestionsListLength();
    }

    _setState({
      currentStepPosition: stepNumber ?? 1,
      currentQuestionPosition: questionNumber ?? 1,
      currentQuestionCounter: questionIndex + 1,
      currentQuestion: currentQuestion,
      currentStep: step,
      loading: false,
      answersListCount: answersCount,
      questionsListLength: questionsCount,
      mandatoryQuestions: _getMandatoryQuestions(),
      maxStepAvailable: stepNumber,
      maxQuestionAvailable: {
        step: stepNumber ?? 1,
        question: questionNumber ?? 1,
      },
    });
  };

  useEffect(() => {
    // En mode normal
    if (!isOthersMode) {
      // isSansboxMode
      Api.simulator.getPublishedSimulator().then((r) => {
        const simulator = Helper.isValidResponse(r);
        if (!simulator) {
          Helper.displayErrorMessage(ERR_MSG_SIMULATION_LOADING);
          _setState({ simulator: null, simulation: null, loading: false });
        } else {
          _setState(
            { simulator: simulator, currentQuestionsList: simulator.steps },
            () => {
              //Check "resSim" url parameter and load simulation if set to "1". It used to skip resume dialog
              if (resSim === "1") {
                _resumeSimulation(simulator);
                return;
              }

              // start a new simulation if :
              // there is no saved simulation or simulation is already finished or simulation was made on a other simulator version
              if (
                runningSimulation?.id === null ||
                runningSimulation?.state !== SIMULATION_STATUS_IN_PROGRESS ||
                simulator?.versionNumber !== runningSimulation.simulator.version
              ) {
                _startNewSimulation(simulator);
              } else {
                _setState({ resumeDialog: true });
              }
            }
          );
        }
      });
    } else {
      // Simulateur en mode bac à sable
      Api.simulator
        .getSimulatorByUuid(simulatorUuid)
        .then((response) => {
          const simulator = Helper.isValidResponse(response);

          if (simulator && !_.isEqual(simulator, {})) {
            _setState(
              { simulator, currentQuestionsList: simulator.steps },
              () => {
                if (simulationIdInSandboxMode) {
                  _resumeSimulation(simulator, simulationIdInSandboxMode);
                  return;
                } else {
                  _startNewSimulation(simulator, true);
                }
              }
            );
          } else {
            _setState({ loading: false, noSimulator: true });
          }
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.currentQuestion?.id) {
      if (state.hasAnsweredToPreviousQuestion) {
        _getAidsCount();
      } else {
        const { questionAidsCount } = state;

        if (!questionAidsCount.hasOwnProperty(`${state.currentQuestion.id}`)) {
          questionAidsCount[state.currentQuestion.id] = state.aidsCount;

          _setState({ questionAidsCount });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentQuestion?.id, state.hasAnsweredToPreviousQuestion]);

  const _startNewSimulation = (simulator, sandbox = false) => {
    // Session.removeSessionStorage(COOKIE_RESULTS_SIMULATION)
    Api.simulation
      .createSimulation(
        simulator?.id,
        sandbox ? userIdInSandboxMode : user?.id,
        sandbox ? organizationUuidInSandboxMode : uuid,
        sandbox
      )
      .then((r) => {
        const simulation = Helper.isValidResponse(r);
        if (!simulation) {
          Helper.displayErrorMessage(ERR_MSG_SIMULATION_LOADING);
        } else {
          _setState({ simulation: simulation, questionAidsCount: {} });
          if (!sandbox && props.withCookie) {
            Helper.saveRunningSimulation(
              simulation.id,
              simulator,
              simulation.date
            );
            Session.setCookie(COOKIE_QUESTION_AIDS_COUNT, {});
          }

          _initializeSimulator(simulator.steps);
        }
      });
  };

  const _resumeSimulation = (simulator, idSimulation = null) => {
    Api.simulation
      .getSimulation(idSimulation ?? runningSimulation.id)
      .then((r) => {
        const simulation = Helper.isValidResponse(r);
        if (!simulation) {
          Helper.displayErrorMessage(ERR_MSG_SIMULATION_LOADING);
          //TODO: si la simulation n'existe plus (supprimée?), afficher un message et rediriger vers une nouvelle simulation
          return;
        }
        // Copy old answers to continue the simulation
        const lastAnswers = [];
        _.each(simulation?.simulationAnswers, (sa) => {
          lastAnswers[sa.question.id] = {
            value: sa.value,
            answer: {
              ...sa,
              questionId: sa.question.id,
              specific: sa.question.criterion.specific,
            },
            id: sa.id,
          };
        });

        _updateDependencies(lastAnswers, simulator).then(
          (filteredQuestions) => {
            // Get the last step from the saved simulation
            const simStep = _.find(simulator.steps, {
              position: !idSimulation ? runningSimulation?.step : 1,
            });

            // Get the answer corresponding to the last question (and step) in the saved simulation
            const currAnswer = _.find(lastAnswers, (a) => {
              return !idSimulation
                ? a?.answer.question.position === runningSimulation?.question &&
                    a?.answer.question.step.position === runningSimulation?.step
                : a?.answer.question.position === 1 &&
                    a?.answer.question.step.position === 1;
            });

            _setState({
              answers: lastAnswers,
              currentAnswer: currAnswer, //copy last answer to the current displayed question
              hasAnswered: hasAnswered(currAnswer),
              resumeDialog: false,
              simulation: simulation,
              isFirstQuestion: !idSimulation
                ? runningSimulation?.step === 1 &&
                  runningSimulation?.question === 1
                : true,
              isLastQuestion: !idSimulation
                ? runningSimulation?.step === simulator.steps.length &&
                  runningSimulation?.question === simStep?.questions.length
                : false,
            });
            //TODO: filtrer les questions en fonction des précédentes réponses (simulator => simulatorSteps)
            _initializeSimulator(
              filteredQuestions,
              !idSimulation ? runningSimulation?.step : 1,
              !idSimulation ? runningSimulation?.question : 1,
              lastAnswers
            );
          }
        );
      });
  };

  /* handle resume dialog choice (resume or new simulation) */
  const _handleResume = (resume) => {
    if (resume) {
      _resumeSimulation(state.simulator);
    } else {
      _setState({ resumeDialog: false });
      Helper.saveRunningSimulation(null);
      _startNewSimulation(state.simulator);
    }
  };

  const _changeQuestion = (questionId) => {
    const [step, question, questionIndex] = _getQuestionById(questionId);
    if (question !== null) {
      _setState({
        currentStepPosition: step.position,
        currentQuestionPosition: question.position,
        currentQuestionCounter: questionIndex + 1,
        isFirstQuestion: step.position === 1 && question.position === 1,
        isLastQuestion:
          _.last(_.last(state.currentQuestionsList)?.questions)?.id ===
          questionId,
        currentQuestion: question,
        currentStep: step,
        answersListCount:
          _getQuestionsCountByStep(step.position) + question.position - 1,
        currentAnswer: state.answers[questionId],
      });
    } else {
      Helper.displayErrorMessage(
        "Impossible de trouver la question correspondante",
        "warning"
      );
    }
  };

  const _getQuestionById = (id) => {
    let step = null,
      question = null,
      index = null;
    _.each(state.currentQuestionsList, (qStep) => {
      const i = _.findIndex(qStep.questions, { id: id });
      if (i !== -1) {
        question = qStep.questions[i];
        step = qStep;
        index = i;
      }
    });
    return [step, question, index];
  };

  const _getQuestionsCountByStep = (stepNumber) => {
    let answersCount = 1;
    if (stepNumber > 1) {
      for (let i = 0; i < stepNumber; i++) {
        answersCount += state.currentQuestionsList[i]?.questions.length;
      }
    }
    return answersCount;
  };

  const _getCurrentStep = (questions, answers, position) => {
    position = position ?? state.currentStepPosition;
    questions = questions ?? state.currentQuestionsList;

    const positionSearch = { position: position };
    const questionsInState =
      _.find(state.currentQuestionsList, positionSearch)?.questions || [];
    const questionsInParameter =
      _.find(questions, positionSearch)?.questions || [];

    if (questionsInState.length > questionsInParameter.length) {
      const savedAnswers = answers;

      // eslint-disable-next-line array-callback-return
      questionsInState.map((question) => {
        let { id } = question;
        let foundQuestion = _.find(questionsInParameter, { id });

        if (!foundQuestion) {
          let answerId = _.find(answers, { answer: { questionId: id } })?.id;

          if (answerId) {
            Api.simulation.deleteSimulationAnswer(answerId).then((response) => {
              if (response.status === HTTP_NO_CONTENT) {
                delete savedAnswers[id];
              }
            });
          }
        }
      });

      _setState({ answers: savedAnswers });
    }

    return _.find(questions, positionSearch);
  };

  // const _getCurrentQuestion = (stepPosition, questionPosition) => {
  //     stepPosition = stepPosition ?? state.currentStepPosition
  //     questionPosition = questionPosition ?? state.currentQuestionPosition
  //     const currentStep = _getCurrentStep(stepPosition)
  //     let currentQuestion = _.find(currentStep.questions, {"position": questionPosition})
  //     // if wanted "position" can't be found -> load the first question of the array
  //     if (currentQuestion === undefined) {
  //         currentQuestion = currentStep.questions[0]
  //     }
  //     return currentQuestion
  // }

  /**
   *
   * @param questionDependencies {array}
   * @param answers {array}
   * @return boolean
   * @private
   */
  function _checkDependencies(questionDependencies, answers) {
    let dependencyCheck = true;
    _.each(questionDependencies, (dep) => {
      if (dep.type === CRITERION_TYPE_TXT) {
        // answer id is in dependency answers array
        if (dep.target.criterion.multi) {
          let found = false;
          if (answers[dep.target.id]) {
            _.each(answers[dep.target.id].value.answers, (a) => {
              found |= _.includes(dep.value.answers, a);
            });
          }
          dependencyCheck &= found;
        } else {
          dependencyCheck &= _.includes(
            dep.value.answers,
            answers[dep.target.id]?.value.answer
          );
        }
      }
      if (dep.type === CRITERION_TYPE_OBG) {
        // answer id is the same that the dependency answers
        dependencyCheck &=
          dep.value.answer === answers[dep.target.id]?.value.answer;
      }
      if (dep.type === CRITERION_TYPE_NUM) {
        // answer value is between min and max
        dependencyCheck &= Helper.isBetween(
          answers[dep.target.id]?.value.value,
          dep.valueMin,
          dep.valueMax
        );
      }
    });

    return Boolean(dependencyCheck);
  }

  function _getAvailableAnswers(question, answers) {
    const questionDependencies = question.questionDependencies;
    const criterionValues = question.criterion.criterionValues;
    let availableAnswers = criterionValues;

    if (questionDependencies.length) {
      _.each(questionDependencies, (dep) => {
        if (dep.availableAnswers && dep.availableAnswers.length) {
          if (dep.type === CRITERION_TYPE_TXT) {
            let prevQuestionAnswers = [];
            if (dep.target.criterion.multi) {
              prevQuestionAnswers = answers[dep.target.id].value.answers;
            } else {
              prevQuestionAnswers = [answers[dep.target.id].value.answer];
            }

            _.each(prevQuestionAnswers, (a) => {
              if (_.includes(dep.value.answers, a)) {
                let answerValues = [];
                _.each(dep.availableAnswers, (av) => {
                  if (av.answers.includes(a)) {
                    answerValues = av.value;
                  }
                });

                if (answerValues.length) {
                  availableAnswers = criterionValues.filter((cv) => {
                    return answerValues.includes(cv.id);
                  });
                }
              }
            });
          }
        }
      });
    }

    return availableAnswers;
  }

  const _goToQuestion = (direction = "forward") => {
    const increment = direction === "forward" ? 1 : -1; //+1 ou +(-1) en fonction de la direction
    const questions = _getFilteredQuestions(state.answers);

    let currentStep = _getCurrentStep(questions, state.answers);
    let nextStep = state.currentStepPosition;
    let nextQuestion = state.currentQuestionPosition;
    let questionCounter = state.currentQuestionCounter;
    let nextQuestionObject;

    let nextPosition = nextQuestion + increment;

    // Return first object that have position superior or equals (or inferior if direction is "backward")
    // It's useful because array can be truncated by dependncies check so position won't always follow
    nextQuestionObject =
      direction === "forward"
        ? _.find(currentStep.questions, (o) => o.position >= nextPosition)
        : _.findLast(currentStep.questions, (o) => o.position <= nextPosition);

    nextPosition = nextQuestionObject?.position ?? nextPosition; // save nextPositionNumber (if found)

    // La question précédente a été trouvée dans la catégorie actuelle
    if (nextQuestionObject !== undefined) {
      nextQuestion = nextPosition;
      questionCounter = questionCounter + increment;
    } else {
      //on cherche à récupérer la catégorie précédente/suivante
      currentStep = _.find(state.currentQuestionsList, {
        position: nextStep + increment,
      });

      // Une catégorie a été trouvée : passage à la précédente/suivante
      if (currentStep !== undefined) {
        const [lastQuestionInCurrentStep] = currentStep.questions.slice(-1);

        nextStep = nextStep + increment;
        // selection de la dernière ou première question de la catégorie en fonction de la direction
        nextQuestion =
          direction === "forward" ? 1 : lastQuestionInCurrentStep.position;
        questionCounter =
          direction === "backward" && state.currentQuestionCounter === 1
            ? currentStep.questions.length
            : nextQuestion;
        // Return first object that have position superior or equals (or inferior if direction is "backward")
        nextQuestionObject =
          direction === "forward"
            ? _.find(currentStep.questions, (o) => o.position >= nextQuestion)
            : _.findLast(
                currentStep.questions,
                (o) => o.position <= nextQuestion
              );
        nextPosition = nextQuestionObject?.position ?? nextQuestion;
        nextQuestion = nextPosition;
      }
    }

    // calcul de l'avancement des questions disponibles pour le menu
    let maxAvailableStep = state.maxQuestionAvailable.step;
    let maxAvailableQuestion = state.maxQuestionAvailable.question;
    if (nextStep >= maxAvailableStep) {
      maxAvailableStep = nextStep;
      if (
        nextQuestion >= maxAvailableQuestion ||
        nextStep > state.currentStepPosition
      ) {
        maxAvailableQuestion = nextQuestion;
      }
    }

    if (nextQuestionObject && state.answers.length) {
      nextQuestionObject.availableAnswers = _getAvailableAnswers(
        nextQuestionObject,
        state.answers
      );
    }

    // mise à jour de l'avancement
    _setState({
      isFirstQuestion: nextStep === 1 && nextQuestion === 1,
      isLastQuestion:
        nextStep === state.currentQuestionsList.length &&
        nextQuestion === currentStep?.questions.length,
      currentStepPosition: nextStep,
      currentQuestionPosition: nextQuestion,
      currentQuestionCounter: questionCounter,
      currentQuestion: nextQuestionObject,
      currentStep: currentStep,
      currentAnswer: state.answers[nextQuestionObject?.id],
      [`saving${direction === "backward" ? "OnPrevious" : ""}`]: false,
      answersListCount: state.answersListCount + increment,
      questionsListLength: _getQuestionsListLength(),
      mandatoryQuestions: _getMandatoryQuestions(),
      maxStepAvailable: nextStep,
      hasAnswered: hasAnswered(state.answers[nextQuestionObject?.id]),
      maxQuestionAvailable: {
        step: maxAvailableStep,
        question: maxAvailableQuestion,
      },
    });
  };

  const _saveCurrentAnswer = (answer) => {
    const value = formatAnswerValue(answer);
    const curAnswer = { value: value, answer: answer };
    _setState({
      hasAnswered: hasAnswered(curAnswer),
      currentAnswer: curAnswer,
    });
  };

  const _setSavingState = (direction = "forward", status = true) => {
    _setState({
      [`saving${direction === "backward" ? "OnPrevious" : ""}`]: status,
    });
  };

  const _getAidsCount = async () => {
    const simulation = isOthersMode
      ? state.simulation
      : Helper.getRunningSimulation() ?? state.simulation;
    let { aidsCount, questionAidsCount } = state;

    _setState({ aidsCountLoading: true });
    const aidsCountData = await Api.simulation.getAidsCountByAnswers(
      simulation?.id,
      props.mode !== Constants.SIMULATOR_TEST_MODE
    );

    if (aidsCountData?.status === HTTP_OK) {
      aidsCount = aidsCountData.data || 0;
    }

    const exceptionCase =
      aidsCount === 0 && questionAidsCount[state.currentQuestion?.id] > 0;

    if (!exceptionCase) {
      // On enregistre le nombre d'aides sélectionnées à chaque question
      questionAidsCount[state.currentQuestion.id] = aidsCount;

      if (!isOthersMode && props.withCookie) {
        Session.setCookie(COOKIE_QUESTION_AIDS_COUNT, questionAidsCount);
      }
    }

    _setState({
      aidsCount,
      aidsCountLoading: false,
      questionAidsCount,
    });
  };

  const _manageAvailableAnswersUselessSimulationAnswers = (
    currentAnswer,
    questionDependencyAvailableAnswers,
    element
  ) => {
    let valueToUpdate = null;
    let result = null;

    if (currentAnswer) {
      if (typeof currentAnswer === "number") {
        currentAnswer = [currentAnswer];
      }

      const values = [];
      currentAnswer.map((answer, index) => {
        let isLastAnswer = index === currentAnswer.length - 1;
        let availableAnswer = questionDependencyAvailableAnswers.find(
          (element) => element.answers[0] === answer
        );

        if (availableAnswer?.value?.length > 0) {
          let availableAnswers = availableAnswer.value;

          // Les réponses multiples => Sur les réponses à supprimer mais pas la question courante
          if (
            element.value.hasOwnProperty("answers") &&
            element.value.answers?.length > 0
          ) {
            let answers = element.value.answers;
            let newAnswers = [];

            answers.map((answer) => {
              if (availableAnswers.includes(answer)) {
                newAnswers.push(answer);
              }

              return null;
            });

            if (answers.length !== newAnswers.length) {
              answers.map((val) => {
                if (
                  availableAnswers.includes(val) &&
                  !values.find((value) => value === val)
                ) {
                  values.push(val);
                }

                return null;
              });

              if (isLastAnswer) {
                element.value.answers = values;

                if (element.value.answers?.length > 0) {
                  valueToUpdate = {
                    id: element.id,
                    value: {
                      answers: values,
                    },
                  };
                } else {
                  result = true;
                }
              }
            }
          }

          // Réponse unique => Sur la réponse à supprimer mais pas la question courante
          if (element.value.hasOwnProperty("answer") && element.value.answer) {
            values.push(!availableAnswers.includes(element.value.answer));

            if (isLastAnswer) {
              result = values.filter((elem) => elem === true)?.length > 0;
            }
          }
        }

        return null;
      });
    }

    return { valueToUpdate, result };
  };

  const _getUselessSimulationAnswers = (direction, savedAnswers) => {
    let result = {
      values: [],
      savedAnswersToDeleteIndex: [],
      savedAnswersToUpdate: [],
    };

    const savedAnswersToUpdate = [];

    if (direction === "forward") {
      const currentQuestionId = state.currentQuestion.id;
      const criterionType = state.currentQuestion.criterion.type.shortName;
      const previousQuestionAnswer = savedAnswers[currentQuestionId].value;
      let previousAnswer = null;
      let currentAnswer = null;

      if ([CRITERION_TYPE_LOC, CRITERION_TYPE_TXT].includes(criterionType)) {
        previousAnswer = previousQuestionAnswer.answer;
        currentAnswer = state.currentAnswer?.value?.answer;

        if (criterionType === CRITERION_TYPE_TXT) {
          if (
            previousQuestionAnswer.hasOwnProperty("answers") &&
            !previousQuestionAnswer.hasOwnProperty("answer")
          ) {
            previousAnswer = previousQuestionAnswer.answers;
          }

          if (
            state.currentAnswer?.value.hasOwnProperty("answers") &&
            !state.currentAnswer?.value.hasOwnProperty("answer")
          ) {
            currentAnswer = state.currentAnswer?.value?.answers;
          }
        }
      }

      if ([CRITERION_TYPE_NUM, CRITERION_TYPE_OBG].includes(criterionType)) {
        const prop = criterionType === CRITERION_TYPE_NUM ? "value" : "answer";
        previousAnswer = previousQuestionAnswer[prop];
        currentAnswer = state.currentAnswer?.value?.[prop];
      }

      if (!_.isEqual(currentAnswer, previousAnswer)) {
        const questions = [];
        Object.values(state.simulator?.steps)?.map((step) => {
          questions.push(...step.questions);

          return null;
        });

        let values = savedAnswers.filter((value) => {
          let index = questions.findIndex(
            (element) => element.id === value.answer.questionId
          );

          if (index !== -1) {
            let questionDependencies = questions[
              index
            ].questionDependencies.map(
              (questionDependencie) => questionDependencie.target.id
            );

            return questionDependencies.includes(currentQuestionId);
          }

          return false;
        });

        if (values.length > 0) {
          const ids = values
            .filter((element) => {
              let questionIndex = questions.findIndex(
                (question) => question.id === element.answer.questionId
              );

              if (questionIndex !== -1) {
                let questionDependency = questions[
                  questionIndex
                ].questionDependencies.find(
                  (qd) => qd.target.id === currentQuestionId
                );

                if (questionDependency) {
                  if (criterionType === CRITERION_TYPE_TXT) {
                    let result =
                      questionDependency.value?.answers?.includes(
                        previousAnswer
                      ) &&
                      !questionDependency.value?.answers?.includes(
                        currentAnswer
                      );

                    // Début de modification sur la prise en compte de AVAILABLE ANSWERS
                    /*if (!result && questionDependency.availableAnswers) {
                      let availableAnswer =
                        questionDependency.availableAnswers.find(
                          (element) => element.answers[0] === currentAnswer
                        );

                      if (availableAnswer?.value?.length > 0) {
                        let availableAnswers = availableAnswer.value;

                        // Les réponses multiples
                        if (
                          element.value.hasOwnProperty("answers") &&
                          element.value.answers?.length > 0
                        ) {
                          let answers = element.value.answers;
                          let newAnswers = [];

                          answers.map((answer) => {
                            if (availableAnswers.includes(answer)) {
                              newAnswers.push(answer);
                            }

                            return null;
                          });

                          if (answers.length !== newAnswers.length) {
                            element.value.answers = answers.filter((val) =>
                              availableAnswers.includes(val)
                            );

                            if (element.value.answers?.length > 0) {
                              savedAnswersToUpdate.push({
                                id: element.id,
                                value: {
                                  answers: newAnswers,
                                },
                              });
                            } else {
                              result = true;
                            }
                          }
                        }

                        // Réponse unique
                        if (
                          element.value.hasOwnProperty("answer") &&
                          element.value.answer
                        ) {
                          result = !availableAnswers.includes(
                            element.value.answer
                          );
                        }
                      }
                    }*/
                    // Fin de modification sur la prise en compte de AVAILABLE ANSWERS

                    if (currentAnswer) {
                      if (
                        typeof previousAnswer === "object" &&
                        typeof currentAnswer === "object" &&
                        previousAnswer?.length > 0 &&
                        currentAnswer?.length > 0
                      ) {
                        let statusOnPreviousAnswer = false;
                        let statusOnCurrentAnswer = false;
                        let answers = [];

                        previousAnswer.map((answer) => {
                          if (
                            !statusOnPreviousAnswer &&
                            questionDependency.value?.answers?.includes(answer)
                          ) {
                            statusOnPreviousAnswer = true;
                          }

                          return null;
                        });

                        currentAnswer.map((answer) => {
                          answers.push(
                            !questionDependency.value?.answers?.includes(answer)
                          );

                          return null;
                        });

                        statusOnCurrentAnswer =
                          answers.filter((element) => !element)?.length === 0;

                        result =
                          statusOnPreviousAnswer && statusOnCurrentAnswer;
                      }
                    } else {
                      result = true;
                    }

                    // Début de prise en considération de AVAILABLE ANSWERS => Question à choix multiples
                    if (!result && questionDependency.availableAnswers) {
                      let resultWithAvailableAnswers =
                        _manageAvailableAnswersUselessSimulationAnswers(
                          currentAnswer,
                          questionDependency.availableAnswers,
                          element
                        );

                      if (resultWithAvailableAnswers.result !== null) {
                        result = resultWithAvailableAnswers.result;
                      }

                      if (resultWithAvailableAnswers.valueToUpdate !== null) {
                        savedAnswersToUpdate.push(
                          resultWithAvailableAnswers.valueToUpdate
                        );
                      }
                    }
                    // Fin de prise en considération de AVAILABLE ANSWERS

                    return result;
                  }

                  if (criterionType === CRITERION_TYPE_OBG) {
                    return questionDependency.value?.answer !== currentAnswer;
                  }

                  if (criterionType === CRITERION_TYPE_NUM) {
                    return (
                      Helper.isBetween(
                        previousAnswer,
                        questionDependency.value?.min,
                        questionDependency.value?.max
                      ) &&
                      !Helper.isBetween(
                        currentAnswer,
                        questionDependency.value?.min,
                        questionDependency.value?.max
                      )
                    );
                  }
                }

                return false;
              }

              return false;
            })
            ?.map((element) => element.id);

          const savedAnswersIndexToDelete = [];
          ids.map((id) => {
            let index = savedAnswers.findIndex((element) => element?.id === id);

            if (index !== -1) {
              savedAnswersIndexToDelete.push(index);
            }

            return null;
          });

          result = {
            values: ids,
            savedAnswersToDeleteIndex: savedAnswersIndexToDelete,
            savedAnswersToUpdate,
          };
        }
      }
    }

    return result;
  };

  const _saveAnswer = async (checkMandatory = false, direction = "forward") => {
    return new Promise((resolve) => {
      // currentAnswer = null or undefined, then check if current question is required before going to the next question
      if (
        checkMandatory &&
        state.currentQuestion.mandatory &&
        !hasAnswered(state.currentAnswer)
      ) {
        Helper.displayErrorMessage(
          "Cette question est obligatoire ! Veuillez rentrer une valeur avant de passer à la suivante"
        );
        resolve(false);
        return;
      }

      // Answer already created : update current value
      if (state.answers.hasOwnProperty(state.currentQuestion.id)) {
        const type = state.currentAnswer?.answer?.type;
        let key = "";

        // OBG - TXT - DTE - SPE => 'answer' (Pour les questions à choix multiples c'est 'answers')
        if (
          [
            CRITERION_TYPE_TXT,
            CRITERION_TYPE_OBG,
            CRITERION_TYPE_DTE,
            CRITERION_TYPE_SPE,
          ].includes(type)
        ) {
          key = "answer";

          if (
            type === CRITERION_TYPE_SPE ||
            (type === CRITERION_TYPE_TXT &&
              state.currentQuestion?.criterion?.multi)
          ) {
            key += "s";
          }
        }

        // NUM => 'value'
        if (type === CRITERION_TYPE_NUM) {
          key = "value";
        }

        const answer = state.currentAnswer?.value?.[key];

        const conditionDoNotUpdate =
          (type === CRITERION_TYPE_NUM && answer === "") ||
          ([
            CRITERION_TYPE_TXT,
            CRITERION_TYPE_OBG,
            CRITERION_TYPE_DTE,
            CRITERION_TYPE_SPE,
          ].includes(type) &&
            !answer);

        let savedAnswers = state.answers;
        const uselessSimulationAnswers = _getUselessSimulationAnswers(
          direction,
          savedAnswers
        );

        const requests = [
          conditionDoNotUpdate
            ? Api.simulation.deleteSimulationAnswer(
                state.answers[state.currentQuestion.id].id
              )
            : Api.simulation.updateSimulationAnswer(
                state.answers[state.currentQuestion.id].id,
                { value: state.currentAnswer?.value }
              ),
        ];

        const withUselessSimulationAnswersDelete =
          uselessSimulationAnswers.values.length > 0 &&
          uselessSimulationAnswers.savedAnswersToDeleteIndex.length > 0;

        const withSimulationAnswersUpdate =
          uselessSimulationAnswers.savedAnswersToUpdate.length > 0;

        if (withUselessSimulationAnswersDelete) {
          requests.push(
            Api.simulation.deleteMultipleSimulationAnswers(
              uselessSimulationAnswers.values
            )
          );
        }

        if (withSimulationAnswersUpdate) {
          requests.push(
            Api.simulation.updateMultipleSimulationAnswers(
              uselessSimulationAnswers.savedAnswersToUpdate
            )
          );
        }

        Promise.all(requests)
          .then((responses) => {
            const stateObject = {};
            const response = conditionDoNotUpdate
              ? responses[0]
              : Helper.isValidResponse(responses[0]);
            let noError = true;

            if (conditionDoNotUpdate && response?.status === HTTP_NO_CONTENT) {
              delete savedAnswers[state.currentQuestion.id];

              stateObject.answers = savedAnswers;
              stateObject.hasAnsweredToPreviousQuestion = true;
              noError = false;
            }

            if (!conditionDoNotUpdate) {
              if (!response) {
                Helper.displayErrorMessage(ERR_MSG_SIMULATION_SAVING);
                _setSavingState(direction, false);
                resolve(false);
              } else {
                savedAnswers[state.currentQuestion.id] = {
                  value: response.value,
                  answer: state.currentAnswer?.answer,
                  id: response.id,
                };

                stateObject.answers = savedAnswers;
                stateObject.hasAnsweredToPreviousQuestion = true;
                noError = false;
              }
            }

            if (responses?.[1]?.status === HTTP_NO_CONTENT) {
              uselessSimulationAnswers.savedAnswersToDeleteIndex.map((id) => {
                delete savedAnswers[id];

                return null;
              });

              stateObject.answers = savedAnswers;
            }

            _setState(stateObject);
            if (!noError) {
              resolve(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // currentAnswer = null or undefined => Simply go to the next/previous question
        if (!hasAnswered(state.currentAnswer)) {
          _setState({ hasAnsweredToPreviousQuestion: false });
          resolve(true);
          return;
        }

        // Create a new Answer entry
        Api.simulation
          .addSimulationAnswer(
            state.currentQuestion.criterion.type.shortName,
            state.currentQuestion.id,
            state.simulation?.id,
            state.currentAnswer?.value
          )
          .then(async (r) => {
            const response = Helper.isValidResponse(r);
            if (!response) {
              Helper.displayErrorMessage(ERR_MSG_SIMULATION_SAVING);
              _setSavingState(direction, false);
              resolve(false);
            } else {
              // Save (or update) answer in state
              let savedAnswers = state.answers;
              savedAnswers[state.currentQuestion.id] = {
                ...state.currentAnswer,
                id: response?.id,
              };

              _setState({
                answers: savedAnswers,
                hasAnsweredToPreviousQuestion: true,
              });
              resolve(true);
            }
          });
      }
    });
  };

  const _nextQuestion = (direction = "forward") => {
    // La condition suivant est uniquement pour "criPlan_01" quand l'utilisateur tente de faire suivant et si la somme des pourcentages est !== 100%
    if (
      state.currentQuestion?.criterion?.shortName === "criPlan_01" &&
      direction === "forward"
    ) {
      let total = 0;

      state.currentAnswer?.value?.answers?.map((answer) => {
        total += answer.percent;

        return null;
      });

      if (total !== 100) {
        toast.error(
          "La somme des pourcentages des essences doit être égale à 100%."
        );
        return;
      }
    }

    _setSavingState(direction);
    _saveAnswer(direction === "forward", direction).then((success) => {
      if (success) {
        _updateDependencies().then(() =>
          _saveAnswerAndGoToNextQuestion(direction)
        );
      } else {
        _setSavingState(direction, false);
      }
    });
  };

  const _getFilteredQuestions = (answers, simulator = state.simulator) => {
    let filteredQuestions = [];
    _.each(simulator.steps, (s) => {
      const l = filteredQuestions.push({ ...s, questions: [] });
      _.each(s.questions, (q) => {
        if (_checkDependencies(q.questionDependencies, answers)) {
          filteredQuestions[l - 1].questions.push(q);
        }
      });
    });

    return filteredQuestions;
  };

  const _updateDependencies = async (
    answers = state.answers,
    simulator = state.simulator
  ) => {
    let filteredQuestions = [];
    _.each(simulator.steps, (s) => {
      const l = filteredQuestions.push({ ...s, questions: [] });
      _.each(s.questions, (q) => {
        // Add question if dependencies are Ok
        if (_checkDependencies(q.questionDependencies, answers)) {
          filteredQuestions[l - 1].questions.push(q);
        }
      });
    });

    await _setState({ currentQuestionsList: filteredQuestions });
    return filteredQuestions;
  };

  const _getAnswersLength = () => {
    return state.answers.filter(Boolean).length;
  };

  const _getQuestionsListLength = () => {
    let count = 0;
    _.map(state.currentQuestionsList, (step) => {
      count += step.questions.length;
    });
    return count;
  };

  const _getMandatoryQuestions = () => {
    let result = [];
    _.map(state.currentQuestionsList, (step) => {
      result.push(_.filter(step.questions, { mandatory: true }));
    });
    return result;
  };

  const _saveAnswerAndGoToNextQuestion = (direction = "forward") => {
    if (!isOthersMode && props.withCookie) {
      Helper.saveRunningSimulation(
        state.simulation?.id,
        state.simulator,
        moment().format(),
        _getAnswersLength(),
        state.currentStepPosition,
        state.currentQuestionPosition
      );
    }
    _goToQuestion(direction);
  };

  const _submitSimulation = () => {
    _setSavingState();
    _saveAnswer().then((success) => {
      if (success) {
        const askedQuestions = [];
        let answeredOptionalQuestionsCount = 0;

        state.currentQuestionsList?.map((step) => {
          askedQuestions.push(...step.questions);

          return null;
        });

        const optionalQuestions = askedQuestions.filter(
          (element) => !element.mandatory
        );

        optionalQuestions.map((question) => {
          if (state.answers?.[question.id]) {
            answeredOptionalQuestionsCount += 1;
          }

          return null;
        });

        const answeredOptionalQuestionsPercentage =
          (100 * answeredOptionalQuestionsCount) / optionalQuestions.length;

        _setState({
          loading: true,
          loadingMessage: "Recherche des aides en cours ...",
          answeredOptionalQuestionsPercentage:
            answeredOptionalQuestionsPercentage || 0,
        });

        if (!isOthersMode && props.withCookie) {
          Helper.saveRunningSimulation(
            state.simulation?.id,
            state.simulator,
            moment().format(),
            _getAnswersLength(),
            state.currentStepPosition,
            state.currentQuestionPosition,
            SIMULATION_STATUS_COMPLETED
          );
        }

        // update simulation status to "completed"
        Api.simulation
          .setSimulationCompleted(state.simulation?.id)
          .then((r) => {
            const response = Helper.isValidResponse(r);
            if (response !== false) {
              if (!isOthersMode && props.withCookie) {
                // save simulation id in a cookie (to be reused after reload)
                Session.setCookie(COOKIE_RESULTS_SIMULATION, {
                  id: state.simulation.id,
                  simulator: {
                    id: state.simulator?.id,
                    version: state.simulator?.versionNumber,
                  },
                  state: SIMULATION_STATUS_COMPLETED,
                });
              }

              Api.simulation
                .searchAids(
                  state.simulation.id,
                  props.mode !== Constants.SIMULATOR_TEST_MODE
                )
                .then((r) => {
                  const aids = Helper.isValidResponse(r);
                  if (aids) {
                    if (!isOthersMode && props.withCookie) {
                      Session.setCookie(COOKIE_QUESTION_AIDS_COUNT, {});
                    }

                    // will trigger navigation to results page
                    _setState({
                      simulationFinished: true,
                      questionAidsCount: {},
                    });
                  } else {
                    Helper.displayErrorMessage(ERR_MSG_SIMULATION_SAVING);
                    _setState({
                      saving: false,
                      loading: false,
                      loadingMessage: null,
                    });
                  }
                });
            } else {
              Helper.displayErrorMessage(ERR_MSG_SIMULATION_SAVING);
              _setState({
                saving: false,
                loading: false,
                loadingMessage: null,
              });
            }
          });
      } else {
        _setState({ saving: false, loading: false, loadingMessage: null });
      }
    });
  };

  const _configureAidsCountPosition = () => {
    const allAnswersContentWidth = allAnswersContentRef.current?.offsetWidth;
    const answersFormContentWidth = answersFormContentRef.current?.offsetWidth;

    if (allAnswersContentWidth && answersFormContentWidth) {
      const aidsCountPositionFromRight =
        allAnswersContentWidth - answersFormContentWidth;

      _setState({ aidsCountPositionFromRight });
    }
  };

  const _handleAchieveTest = () => {
    _setState({ testLoading: true });
    Api.simulator
      .achieveSandboxTest(simulatorUuid)
      .then((response) => {
        _setState({ testLoading: false });
        window.close();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    window.addEventListener("resize", _configureAidsCountPosition);

    return () => {
      window.removeEventListener("resize", _configureAidsCountPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _configureAidsCountPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentQuestion?.id,
    allAnswersContentRef.current?.offsetWidth,
    answersFormContentRef.current?.offsetWidth,
  ]);

  useEffect(() => {
    const steps = Object.values(state.simulator?.steps || []);

    if (steps.length > 0 && state.currentQuestion?.id) {
      const filteredQuestions = _getFilteredQuestions(
        state.answers,
        state.simulator
      );
      let questionsCount = 0;

      filteredQuestions?.map((data) => {
        questionsCount += data.questions.length;

        return null;
      });

      _setState({ simulatorQuestionsCount: questionsCount });
    }
    // eslint-disable-next-line
  }, [simulatorStepsLength, state.currentQuestion?.id]);

  return (
    <section className="simulator flex align-items-start">
      <img
        src="/img/fond-foret.png"
        className="forest-background"
        alt="brown forest illustration"
      />

      {state.loading && <LoadingOverlay message={state.loadingMessage} />}
      {state.simulationFinished && (
        <Navigate
          to={`${`${Routing.simulator_results}?answeredOptionalQuestionsPercentage=${state.answeredOptionalQuestionsPercentage}`}${
            isOthersMode
              ? `&mode=${props.mode}&simulationId=${state.simulation.id}&uuid=${simulatorUuid}&answeredOptionalQuestionsPercentage=${state.answeredOptionalQuestionsPercentage}`
              : `${
                  !props.withCookie
                    ? `&simulationIdWithoutCookieMode=${state.simulation.id}`
                    : ""
                }`
          }`}
          replace={true}
        />
      )}
      {state.testLoading && <Loading />}

      {state.resumeDialog && (
        <Dialog
          open={state.resumeDialog}
          disableEscapeKeyDown={true}
          onClose={null}
          className="resume-dialog"
          aria-labelledby="resume-dialog-title"
          aria-describedby="resume-dialog-description"
        >
          <DialogTitle id="resume-dialog-title">
            Reprendre la simulation
          </DialogTitle>
          <DialogContent className="resume-dialog-content">
            <div>
              Vous avez une simulation en cours. Souhaitez vous la reprendre ou
              en démarrer une nouvelle ?
            </div>
            <br />
            <div className="flex-start flex-column align-items-start resume-dialog-info">
              <u>Informations concernant la simulation</u>
              <span>
                Date de la simulation :{" "}
                <span className="bold">
                  {moment(runningSimulation.date).format("DD-MM-YYYY HH:mm")}
                </span>
              </span>
              <span>
                Nombre de réponses :{" "}
                <span className="bold">{runningSimulation.nbQuestion}</span>
              </span>
            </div>
            <br />
            <HomeCheckBox
              isChecked={state.isChecked}
              onRequestCheck={() => {
                setState((prevState) => {
                  return {
                    ...prevState,
                    isChecked: !state.isChecked,
                  };
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Tooltip
              title={
                !props.withCookie
                  ? "Pour accéder à cette fonctionnalité, vous devez accepter les cookies."
                  : ""
              }
            >
              <div>
                <Button
                  disabled={!state.isChecked || !props.withCookie}
                  onClick={() => _handleResume(false)}
                >
                  Nouvelle simulation
                </Button>
              </div>
            </Tooltip>

            <Tooltip
              title={
                !props.withCookie
                  ? "Pour accéder à cette fonctionnalité, vous devez accepter les cookies."
                  : ""
              }
            >
              <div>
                <Button
                  disabled={!state.isChecked || !props.withCookie}
                  onClick={() => _handleResume(true)}
                >
                  Reprendre
                </Button>
              </div>
            </Tooltip>
          </DialogActions>
        </Dialog>
      )}
      {!state.loading && !state.resumeDialog && !state.noSimulator && (
        <>
          <ProgressBar
            value={state.answersListCount}
            max={state.simulatorQuestionsCount}
          />
          <SimulatorMenu
            simulatorSteps={state.currentQuestionsList}
            currentStep={state.currentStepPosition}
            onQuestionClick={_changeQuestion}
            mandatoryQuestions={state.currentQuestionsList}
            //TODO: if you want to only display mandatory questions : mandatoryQuestions={state.mandatoryQuestions}
            maxQuestionAvailable={state.maxQuestionAvailable}
            maxStepAvailable={state.maxStepAvailable}
            loading={state.testLoading}
            isSandboxMode={isOthersMode}
            onRequestAchieveTest={_handleAchieveTest}
            currentQuestionIndex={state.answersListCount}
            // questionsCount={state.questionsListLength}
            questionsCount={state.simulatorQuestionsCount}
          />
          <div className="simulator-content flex-start flex-column">
            <div className="simulator-title">
              {isOthersMode && (
                <div
                  style={{
                    width:
                      answersFormContentRef.current?.offsetWidth ??
                      allAnswersContentRef.current?.offsetWidth,
                  }}
                  className="sandbox-content"
                >
                  <Alert
                    icon={false}
                    severity="warning"
                    className="sandbox-banner"
                  >
                    {props.mode === Constants.SIMULATOR_SANDBOX_MODE
                      ? `Simulateur de test Version ${state.simulator?.versionNumber}`
                      : "Test des versions d'aides non publiées"}
                  </Alert>
                </div>
              )}
              {state.currentStep?.name !== null ? (
                <h1>
                  {state.currentStep?.name}{" "}
                  <span className="title-step">{`(${state.currentQuestionCounter}/${state.currentStep?.questions.length})`}</span>
                </h1>
              ) : (
                <h1>Ma simulation</h1>
              )}
              <div
                className="aids-count-content"
                style={{
                  position: "absolute",
                  right: state.aidsCountPositionFromRight,
                }}
              >
                <span className="aids-count-label">Aides possibles :</span>
                {state.aidsCountLoading ? (
                  <CircularProgress color="success" size={15} />
                ) : (
                  <span className="aids-count-value">{state.aidsCount}</span>
                )}
              </div>
            </div>
            <div ref={allAnswersContentRef} className="simulator-question">
              <SimulatorQuestion
                question={state.currentQuestion}
                onAnswer={_saveCurrentAnswer}
                defaultValue={state.currentAnswer?.value}
                nextButtonRef={nextButtonRef}
                answersFormContentRef={answersFormContentRef}
              />
            </div>
            <div className="simulator-button flex-start w-100">
              {!state.isFirstQuestion &&
                (state.currentQuestion?.criterion?.shortName === "loca_01"
                  ? !state.currentAnswer?.value?.answer ||
                    state.currentAnswer?.value?.answer === "" ||
                    state.currentAnswer?.value?.answer?.length === 5
                  : true) && (
                  <LoadingButton
                    variant="outlined"
                    size="large"
                    name="previousQuestionButton"
                    startIcon={<BackspaceIcon />}
                    title="Question précédente"
                    onClick={() => _nextQuestion("backward")}
                    loading={state.savingOnPrevious}
                    loadingPosition="start"
                  >
                    Précédent
                  </LoadingButton>
                )}
              {state.isLastQuestion && (
                <LoadingButton
                  title="Consulter les résultats"
                  size="large"
                  variant="contained"
                  endIcon={<PageviewIcon />}
                  loading={state.saving}
                  loadingPosition="end"
                  onClick={_submitSimulation}
                >
                  Consulter les résultats
                </LoadingButton>
              )}
              {/* Display button if not the las question and question is not mandotory or mandatory and answered */}
              {!(state.currentQuestion?.mandatory && !state.hasAnswered) &&
                !state.isLastQuestion &&
                !state.aidsCountLoading &&
                (state.aidsCount > 0 ||
                  state.questionAidsCount[state.currentQuestion?.id] > 0) &&
                (state.currentQuestion?.criterion?.shortName === "loca_01"
                  ? state.currentAnswer?.value?.answer?.length === 5
                  : true) && (
                  <LoadingButton
                    ref={nextButtonRef}
                    title="Question suivante"
                    variant="contained"
                    size="large"
                    endIcon={<SendIcon />}
                    loading={state.saving}
                    loadingPosition="end"
                    onClick={() => _nextQuestion("forward")}
                  >
                    {state.hasAnswered || state.currentQuestion?.mandatory
                      ? "Suivant"
                      : "Je ne sais pas"}
                  </LoadingButton>
                )}
            </div>
            {state.aidsCount === 0 && !state.aidsCountLoading && (
              <div className="warning-message aids-count-message">
                <Alert severity="warning">
                  <small>
                    Plus aucune aide n'est possible, veuillez modifier vos
                    critères.
                  </small>
                </Alert>
              </div>
            )}
          </div>
        </>
      )}
      {state.noSimulator && (
        <SimulatorError
          title="OOPS ! Une erreur est survenue lors de la récupération des données du simulateur"
          secondTitle="Impossible de récupérer les données du simulateur"
          description="Les informations du simulateur fournies ont peut être été modifiées ou supprimées"
        />
      )}
    </section>
  );
}

/* ================================================================================================================= */
/* =============================================== GLOBALS FUNCTIONS =============================================== */

/* ================================================================================================================= */

function formatAnswerValue(answer) {
  // Specific data
  if (answer?.shortName === CRITERIA_CRIPLAN01) {
    return { answers: answer.answers };
  }
  // Generic criterion
  switch (answer?.type) {
    case "TXT":
      return answer?.isMulti
        ? { answers: answer.answers }
        : { answer: answer.answers };
    case "NUM":
      return { value: answer.answers };
    default:
      return { answer: answer.answers };
  }
}

/**
 * Check if the currentAnswer of a question is empty or not
 *
 * @param answer
 * @return {boolean}
 */
function hasAnswered(answer) {
  let answered = true;

  if (answer == null) {
    return false;
  }
  if (
    answer.answer?.specific &&
    answer.answer?.type !== CRITERION_TYPE_LOC &&
    answer.answer?.type !== CRITERION_TYPE_DTE
  ) {
    answered = answer.value?.answers != null && answer.value?.answers !== "";
  } else if (
    answer.answer?.type === CRITERION_TYPE_TXT &&
    answer.answer?.isMulti
  ) {
    answered = answer.value?.answers != null && answer.value?.answers !== "";
  } else if (answer.answer?.type === CRITERION_TYPE_NUM) {
    answered = answer.value?.value != null && answer.value?.value !== "";
  } else {
    answered = answer.value?.answer != null && answer.value?.answer !== "";
  }

  return answered;
}
