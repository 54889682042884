import React, { useEffect, useRef, useState } from "react";
import "./aidRow.css";
import { Button } from "@mui/material";
import AidDetailDialog from "../../AidDetailDialog";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import _ from "lodash";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";
import {
  AID_FUNDING_ESTIM_BILL,
  AID_FUNDING_SCALE,
} from "fsy.common-library/lib/env/Constants";
import { toast } from "react-toastify";

const InfoItem = ({ title, content }) => {
  return (
    <div className="info-element">
      <div>
        <CheckIcon className="check-icon" />
        <span>{`${title} : `}</span>
      </div>
      <div>{content}</div>
    </div>
  );
};

const AidRow = ({
  aid,
  height,
  species,
  isOutOfFolder, // Aide qui n'a rien avoir avec les dossiers et sous-dossiers
  isInFolder, // Aide qui se trouve dans un dossier UNIQUEMENT, pas les sous-dossiers
  isInSubfolder,
  isResult,
  onRequestGetAidRow,
}) => {
  const aidRowRef = useRef(null);

  const [state, setState] = useState({
    isOpened: false,
    projectType: "",
    amount: null,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleModalDisplay = () => {
    _setState({ isOpened: !state.isOpened });
  };

  const _handleOpenDocument = () => {
    if (aid.documentUrl) {
      let url = aid.documentUrl;

      if (!url.includes("https://")) {
        url = `https://${url}`;
      }

      window.open(url, "_blank");
    } else {
      toast.warning(
        "Aucun URL renseigné pour le document descriptif de l'aide.",
        GenericHelper.getToastOptions()
      );
    }
  };

  // Type de projet dans les catalogues
  useEffect(() => {
    if (!isResult && aid?.id) {
      let projectTypeCriterion =
        aid?.aidCriterions?.find(
          (element) => element.criterion.shortName === "general_02"
        )?.value?.answers || [];
      projectTypeCriterion = projectTypeCriterion.map(
        (element) => element.name
      );
      const text = projectTypeCriterion.join(", ");

      _setState({ projectType: text });
    }
    // eslint-disable-next-line
  }, [isResult, aid?.id]);

  // Montant estimé de l'aide dans les résultats
  useEffect(() => {
    if (isResult && aid?.amount) {
      const amountValue = aid.amount.value;
      const unit = aid.amount.unit;

      let amount = "";

      if (_.isObject(amountValue)) {
        const minValue = `${GenericHelper.digitWithDecimalPrecision(
          amountValue.min
        )} ${unit ?? "€"}`;
        const maxValue = `${GenericHelper.digitWithDecimalPrecision(
          amountValue.max
        )} ${unit ?? "€"}`;

        if (amountValue.min === 0 && amountValue.max > 0) {
          amount = `Jusqu'à ${maxValue}`;
        } else {
          if (aid.funding.shortName === AID_FUNDING_SCALE && unit === "%") {
            amount = `${minValue} à ${maxValue} ${
              amountValue.hasOwnProperty("baseAmount")
                ? `de ${GenericHelper.digitWithDecimalPrecision(
                    amountValue.baseAmount.amount
                  )} ${amountValue.baseAmount.unit}`
                : "du montant de base"
            }`;
          } else if (
            aid.funding.shortName === AID_FUNDING_ESTIM_BILL &&
            unit === "%"
          ) {
            amount = `${minValue} à ${maxValue} du devis/facture`;
          } else {
            amount = `${minValue} à ${maxValue}`;
          }
        }
      } else {
        amount =
          amountValue > 0
            ? `${GenericHelper.digitWithDecimalPrecision(amountValue)} ${
                unit ?? "€"
              }`
            : "Non calculable";
      }

      _setState({ amount });
    }
    // eslint-disable-next-line
  }, [isResult, aid?.amount]);

  useEffect(() => {
    if (aidRowRef.current && typeof onRequestGetAidRow === "function") {
      onRequestGetAidRow({
        id: aid.id,
        height: aidRowRef.current.offsetHeight,
      });
    }
    // eslint-disable-next-line
  }, [aidRowRef.current]);

  return (
    <>
      <AidDetailDialog
        isOpened={state.isOpened}
        aidDetail={aid}
        species={species}
        onRequestDialogClose={_handleModalDisplay}
      />
      <div
        ref={aidRowRef}
        className={`${!isResult ? "aid-row-root" : "aid-result-row-root"}${
          isOutOfFolder ? " aid-row-out-of-folder" : ""
        } ${isInFolder ? " normal-aid-row" : ""} ${
          isInSubfolder ? " subfolder-aid-row" : ""
        }
        `}
        style={{ height }}
      >
        {!isResult ? (
          <>
            <div className="aid-title-content">
              <img src="/img/red-aid-icon.svg" alt="Icône d'aide" />
              <div className="aid-title">
                <div title={aid.name}>{aid.name}</div>
                {aid.label && <span>{aid.label}</span>}
              </div>
            </div>
            <div className="aid-funding">
              <span>{state.projectType || "-"}</span>
              <span>{`${aid?.form?.name || "-"} - ${
                aid?.funding?.label || "-"
              }`}</span>
            </div>
          </>
        ) : (
          <div className="aid-result-content">
            <div
              className={`aid-result-title ${
                !aid.active ? "no-published-aid" : ""
              }`}
              title={!aid.active ? "Aide non publiée" : "Aide publiée"}
            >
              <img src="/img/red-aid-icon.svg" alt="Icône d'aide" />
              <div>
                <div title={aid.name} className="aid-result-name">
                  {`${aid.name}${
                    !aid.active ? " - (version d'aide non publiée)" : ""
                  }`}
                </div>
                <div className="aid-result-amount">
                  {`Montant estimé d'après votre simulation : ${
                    state.amount || "-"
                  }`}
                </div>
              </div>
            </div>
            <div className="aid-result-info">
              <div className="basic-info-content">
                {/** 1ère ligne */}
                <div className="info-item-content">
                  <InfoItem title="Libellé" content={aid.label || "-"} />
                  <InfoItem
                    title="Date de dépôt"
                    content={
                      aid.depositDate
                        ? moment(aid.depositDate).format("DD/MM/YYYY")
                        : "-"
                    }
                  />
                </div>
                {/** 2ème ligne */}
                <div className="info-item-content">
                  <InfoItem
                    title="Dispositif"
                    content={aid.scheme?.name || "-"}
                  />
                  <InfoItem title="Forme" content={aid.form?.name || "-"} />
                </div>
                {/** 3ème ligne */}
                <div className="info-item-content">
                  <InfoItem
                    title="Organisme"
                    content={aid.organization?.name || "-"}
                  />
                  <InfoItem
                    title="Financement"
                    content={aid.funding?.label || "-"}
                  />
                </div>
              </div>
              <div className="remarks-content info-item-content">
                <InfoItem
                  title="Remarques"
                  content={aid.additionalRemark || "-"}
                />
              </div>
              <div className="buttons-content">
                <Button
                  variant="contained"
                  children="Détail de l'aide"
                  onClick={_handleModalDisplay}
                />
                <Button
                  variant="contained"
                  children="Cahier des charges"
                  onClick={_handleOpenDocument}
                />
              </div>
            </div>
          </div>
        )}
        {!isResult && (
          <div className="aid-detail-button-content">
            <Button variant="contained" onClick={_handleModalDisplay}>
              Détail de l'aide
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AidRow;
