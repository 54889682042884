import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AidDetail from './AidDetail'
import './aidDetailDialog.css'

const AidDetailDialog = ({
    isOpened,
    onRequestDialogClose,
    aidDetail,
    species
}) => {
    const [state, setState] = useState({
        aidDetailTitle: {
            folder: "",
            subfolder: "",
            name: ""
        }
    })

    const _setState = (newValue) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...newValue
            }
        })
    }

    useEffect(() => {
        let aid = aidDetail

        if(aidDetail?.hasOwnProperty('aid')){
            aid = aidDetail.aid
        }

        const aidDetailTitle = {
            folder: "",
            subfolder: "",
            name: aid?.name
        }

        if(aid?.folder){
            // Pour un dossier
            if(!aid.folder.folder){
                aidDetailTitle.folder = aid.folder.name
            }
            else{
                aidDetailTitle.folder = aid.folder.folder.name
                aidDetailTitle.subfolder = aid.folder.name
            }
        }

        _setState({ aidDetailTitle: { ...aidDetailTitle } })
    }, [ aidDetail ])

    return (
        <Dialog open={isOpened} onClose={onRequestDialogClose} fullWidth className="aid-detail-dialog">
            <DialogTitle className='aid-detail-dialog-title'>
                { state.aidDetailTitle.folder && <div>{state.aidDetailTitle.folder}</div> }
                { state.aidDetailTitle.subfolder && <div>{state.aidDetailTitle.subfolder}</div> }
                { state.aidDetailTitle.name && <div>{state.aidDetailTitle.name}</div> }
            </DialogTitle>
            <DialogContent className='aid-detail-dialog-content'>
                <AidDetail aidDetail={aidDetail} species={species}/>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={onRequestDialogClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AidDetailDialog