import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import "./table.css";

const DataTable = (props) => {
  const {
    height,
    columns,
    rows,
    rowsCount,
    rowsPerPage,
    onRequestRowUpdate,
    onRequestFormBlur /*, onRequestPageChange*/,
  } = props;
  const [page, setPage] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(rowsPerPage || 10);
  const elements = rows?.slice(
    page * tableRowsPerPage,
    page * tableRowsPerPage + tableRowsPerPage
  );

  const handlePageChange = (event, newPage) => {
    // onRequestPageChange(newPage)
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setTableRowsPerPage(parseInt(event.target.value, 10) || rowsPerPage || 10);
    // onRequestPageChange(0)
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer sx={{ height: height }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={`TableHeaderColumn${index + 1}`}
                  className={`${column.align || "left"}-label`}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {elements?.length > 0 && (
            <>
              <TableBody>
                {elements.map((row, index) => (
                  <TableRow
                    key={`TableRow${index + 1}`}
                    className="hoverable-row"
                  >
                    {columns.map((column, i) => (
                      <TableCell
                        key={`TableBodyCell${i + 1}`}
                        className={`${column.align || "left"}-label ${
                          column.bold ? "bold-label" : ""
                        }`}
                      >
                        {column.component ? (
                          column.component(row)
                        ) : column.editable ? (
                          <div className="editable-cell-content">
                            <TextField
                              color="success"
                              variant="outlined"
                              size="small"
                              value={row[column.field]}
                              onChange={(event) =>
                                onRequestRowUpdate(
                                  row,
                                  index,
                                  event.target.value
                                )
                              }
                              onBlur={(event) => onRequestFormBlur(event, row)}
                            />
                          </div>
                        ) : (
                          row[column.field]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
      <div style={{ position: "relative" }}>
        <TablePagination
          sx={{
            width: "100%",
            height: "52px",
            position: "absolute",
            top: 0,
            right: 0,
          }}
          count={rowsCount}
          page={page}
          rowsPerPage={tableRowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage="Lignes par pages"
        />
      </div>
    </Paper>
  );
};

export default DataTable;
