/* ------------------------ SPECIFIC CRITERION -------------------------- */
export const CRITERIA_CRIPLAN01 = "criPlan_01"
export const CRITERION_TYPE_SPE = "SPE"

/* ------------------------ CONSTANTS HERE -------------------------- */
export const COOKIE_RUNNING_SIMULATION = "RunSim"
export const COOKIE_RESULTS_SIMULATION = "ResSim"
export const COOKIE_RESULTS_SIMULATION_TEMP = "ResSimTemp"
export const COOKIE_CHOICE = "CookieChoice"
export const COOKIE_QUESTION_AIDS_COUNT = "QuestionAidsCount"
export const COOKIE_SESSION = "session"
export const STORAGE_ORG_UUID = "org"
export const SESSION_EXPIRATION_DAYS = 30

/* ------------------------ SIMULATION ERROR MESSAGES -------------------------- */
export const ERR_MSG_SIMULATION_LOADING = "Une erreur est survenue pendant le chargement du simulateur. Réessayez et veuillez avertir l'administrateur si le problème persiste"
export const ERR_MSG_SIMULATION_RESULTS_LOADING = "Une erreur est survenue pendant le chargement des résultats. Réessayez et veuillez avertir l'administrateur si le problème persiste"
export const ERR_MSG_SIMULATION_RESULTS_PDF = "Désolé ! Une erreur est survenue pendant la génération de votre fichier. Réessayez et veuillez avertir l'administrateur si le problème persiste"
export const ERR_MSG_SIMULATION_SAVING = "Une erreur est survenue pendant l'enregistrement de votre réponse. Réessayez et veuillez avertir l'administrateur si le problème persiste"

/* ------------------------ SPECIE TYPE -------------------------- */
export const SPECIE_TYPE_HARDWOOD = "hardwood"
export const SPECIE_TYPE_SOFTWOOD = "softwood"

/* ------------------------ OTHER -------------------------- */
export const MEDIAQUERY_MOBILE = "all and (max-width: 540px)"
export const ENTER_KEY_LABEL = "Enter"
export const BACKSPACE_KEY_LABEL = "Backspace"
export const ZERO_CHAR_CODE = 48
export const NINE_CHAR_CODE = 57
export const COMMA_CHAR_CODE = 44
export const POINT_CHAR_CODE = 46
export const PROJECT_TYPE_CRITERION_ID = 26
export const NATIONAL_LOCATION_ID_VALUE = "nationalLocation"
export const REGIONAL_LOCATION_ID_VALUE = "regionalLocation"
export const DEPARTEMENTAL_LOCATION_ID_VALUE = "departementalLocation"
export const PROJECT_ID_VALUE = "project"