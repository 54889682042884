import Login from "./Login";
import { Session } from "fsy.common-library";
import { Navigate } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";

import "./login.css";
import React from "react";

export function LoginPage(props) {
  return (
    <div
      className="login"
      style={{
        height:
          props.isCookieContentOpened && props.cookieContentHeight
            ? `calc(100vh - ${props.cookieContentHeight}px)`
            : undefined,
      }}
    >
      <div>
        <Login
          pageType={props.pageType}
          showSimulationLink={true}
          withCookie={props.withCookie}
          isCookieContentOpened={props.isCookieContentOpened}
          verifyCookieChoice={props.verifyCookieChoice}
        />
      </div>
    </div>
  );
}

export function LogoutPage() {
  Session.clearSessionAndRedirectToHome();
  return <Navigate to={Routing.app_home} replace />;
}
