import FrontRoutes from "./services/FrontRoutes"
import { Api, Constants, Session } from "fsy.common-library"
import { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router"
import Helper from "./services/Helper"
import Header from "./components/general/Header"
import { hasMenuRoute, routes as Routing } from "./services/RoutesHelper"
import {
    COOKIE_CHOICE,
    COOKIE_RESULTS_SIMULATION,
    COOKIE_RUNNING_SIMULATION,
    STORAGE_ORG_UUID
} from "./services/Constants"
import { Button } from "@mui/material"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
function App() {
    const urlParams = new URLSearchParams(window.location.search);
    const simulatorUuid = urlParams.get("uuid");
    const userIdInOthersMode = urlParams.get("userId");
    const organizationUuidInOthersMode = urlParams.get("organizationUuid")
    const mode = urlParams.get("mode")
    const isSandboxMode = mode === Constants.SIMULATOR_SANDBOX_MODE
    const isTestMode = mode === Constants.SIMULATOR_TEST_MODE
    const isOthersMode = isSandboxMode || isTestMode

    const user = Session.getSessionUser()
    const navigate = useNavigate()
    const location = useLocation()
    const [organization, setOrganization] = useState(null)
    const [isUserLoggedIn, setUserLoggedIn] = useState(Session.isLoggedIn())
    const [isCookieContentOpened, setIsCookieContentOpened] = useState(false)
    const [publishedVersion, setPublishedVersion] = useState(null)
    const [withCookie, setWithCookie] = useState(Session.getCookie(COOKIE_CHOICE) === "accept") // Only Cookie Choice
    const [hasSimulationCookie, setHasSimulationCookie] = useState({
        runningSimulation: Session.getCookie(COOKIE_RUNNING_SIMULATION) !== null,
        resultsSimulation: Session.getCookie(COOKIE_RESULTS_SIMULATION) !== null
    })
    const [userName, setUserName] = useState(user?.name)
    const [cookieContentHeight, setCookieContentHeight] = useState(0)
    const [footerWithMaroonBackground, setFooterWithMaroonBackground] = useState(true)
    const cookieDataContentRef = useRef(null)

    //nécessaire pour faire fonctionner les cookies dans une iframe
    /*
    document.hasStorageAccess().then(hasAccess => {
        if (hasAccess) {
            // storage access has been granted already.
        } else {
            // storage access hasn't been granted already;
            document.requestStorageAccess()
        }
    })*/

    const verifyCookieChoice = () => {
        if (!Session.getCookie(COOKIE_CHOICE)) {
            setIsCookieContentOpened(true)
        }
    }

    const handleCookieAction = (action) => {
        if (action === "accept") {
            Session.setCookie(COOKIE_CHOICE, action)
        } else {
            Session.expiresAllCookies(Constants.PREFIX_LOCAL_STORAGE)
        }

        setIsCookieContentOpened(false)
        setWithCookie(action === "accept")
        setHasSimulationCookie({
            runningSimulation: action === "accept" ? Session.getCookie(COOKIE_RUNNING_SIMULATION) !== null : false,
            resultsSimulation: action === "accept" ? Session.getCookie(COOKIE_RESULTS_SIMULATION) !== null : false
        })
    }

    const redirectToHomePageIfNoCookie = () => {
        if (!Session.getCookie(COOKIE_CHOICE)) {
            navigate(Routing.app_home)
        }
    }

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const uuid = urlParams.get(STORAGE_ORG_UUID) ?? Session.getLocalStorage(STORAGE_ORG_UUID)
        // Un uuid a été envoyé → stockage dans le local storage sinon récupération de l'uuid depuis le local storage
        if (uuid !== null) {
            Session.setLocalStorage(STORAGE_ORG_UUID, uuid)
            Promise.all([
                Api.organization.getOrganizationByUuid(isOthersMode ? organizationUuidInOthersMode : Session.getLocalStorage(STORAGE_ORG_UUID)),
                isOthersMode ? Api.simulator.getSimulatorByUuid(simulatorUuid) : Api.simulator.getPublishedSimulator()
            ])
                .then(([r, simulator]) => {
                    const org = Helper.isValidResponse(r)
                    const simulatorResult = Helper.isValidResponse(simulator)

                    setOrganization(org ? org : null)
                    setPublishedVersion(simulatorResult?.versionNumber)
                })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const height = cookieDataContentRef.current?.offsetHeight

        if (isCookieContentOpened && height > 0) {
            setCookieContentHeight(height)
        }
    }, [cookieDataContentRef.current?.offsetHeight, isCookieContentOpened])

    useEffect(() => {
        if (location?.pathname && !withCookie) {
            const { pathname } = location

            setIsCookieContentOpened([Routing.app_home, Routing.app_login].includes(pathname))
        }
        // eslint-disable-next-line
    }, [location?.pathname, withCookie])

    useEffect(() => {
        if (location?.pathname) {
            const elements = [
                Routing.app_home,
                Routing.simulator_help,
                Routing.user_account,
                Routing.simulator,
                Routing.app_login
            ]
            const status = elements.includes(location.pathname)

            setFooterWithMaroonBackground(status)
        }
    }, [location?.pathname])

    return <>
        {
            hasMenuRoute(window.location.pathname) &&
            !isOthersMode && (
                <header className="header">
                    <Header simulatorUuid={simulatorUuid} isLogged={isUserLoggedIn} user={user} userName={userName} />
                </header>
            )
        }
        <article className="content">
            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT}
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
                progress={undefined}
                theme="colored" />
            <FrontRoutes
                onLogin={() => setUserLoggedIn(true)}
                withCookie={withCookie}
                hasSimulationCookie={hasSimulationCookie}
                isCookieContentOpened={isCookieContentOpened}
                verifyCookieChoice={verifyCookieChoice}
                redirectToHomePageIfNoCookie={redirectToHomePageIfNoCookie}
                setUserName={setUserName}
                simulatorUuid={simulatorUuid}
                userIdInSandboxMode={userIdInOthersMode}
                organizationUuidInSandboxMode={organizationUuidInOthersMode}
                cookieContentHeight={cookieContentHeight}
                mode={mode}
            />
        </article>
        <footer className={`footer ${footerWithMaroonBackground ? "footer-with-maroon-background" : ""}`}>
            {
                isCookieContentOpened && (
                    <div ref={cookieDataContentRef} className="cookie-content flex center">
                        <p>En cliquant sur "Accepter", vous acceptez les cookies à usage technique nécessaires au bon
                            fonctionnement du Simulateur, ainsi que des cookies à des fins statistiques et de
                            personnalisation pour vous permettre d'utiliser au mieux le Simulateur.</p>
                        <Button variant="contained" size="small" children="Accepter"
                            onClick={() => handleCookieAction("accept")} />
                        <Button variant="contained" size="small" children="Refuser"
                            onClick={() => handleCookieAction("refuse")} className="no-cookie-button" />
                    </div>
                )
            }
            <div className="footer-content">
                <div className="cookie-management">
                    {(!isCookieContentOpened && [Routing.app_home, Routing.app_login, Routing.privacy_policy].includes(location.pathname)) && <span className="cookie-management-text" onClick={() => {
                        setIsCookieContentOpened(true)
                    }}>Gérer les cookies</span>}
                </div>
                <div className="footer-right-content">
                    {organization !== null &&
                        <div className="current-organization">
                            <span>{`Simulateur pour : ${organization?.name || ""} ${publishedVersion ? `V${publishedVersion}` : ''}`}</span>
                        </div>
                    }
                    <div className="privacy-policy">
                        <a href={Routing.privacy_policy} className="privacy-policy-text">Politique de confidentialité</a>
                    </div>
                    <div className="footer-version">Version {process.env.REACT_APP_VERSION}</div>
                </div>
            </div>
        </footer>
    </>
}

/*
//Parallax function
(function () {
    // Add event listener
    document.addEventListener("mousemove", parallax)
    const elem = document.querySelector("#parallax")

    // Magic happens here
    function parallax(e) {
        let _w = window.innerWidth / 2
        let _h = window.innerHeight / 2
        let _mouseX = e.clientX
        let _mouseY = e.clientY
        let _depth1 = `${50 - (_mouseX - _w) * 0.0}% ${50 - (_mouseY - _h) * 0.0}%`
        let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`
        let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`
        elem.style.backgroundPosition = `${_depth3}, ${_depth2}, ${_depth1}`
    }
})()
*/
export default App
