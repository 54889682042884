import React from 'react'
import { Checkbox as CheckBoxComponent, checkboxClasses } from '@mui/material'
import './checkBox.css'

const CheckBox = ({
    isChecked,
    onRequestCheck
}) => {
    return (
        <div className='checkbox-component'>
            <CheckBoxComponent 
                checked={isChecked} 
                onChange={onRequestCheck}
                sx={{
                    [`&, &.${checkboxClasses.checked}`]: {
                    color: '#0B3D21'
                    }
                }}
            />
        </div>
    )
}

export default CheckBox;