import { Routes, Route, Navigate } from "react-router-dom";
import { Session, Roles, Constants } from "fsy.common-library";
import { routes as Routing } from "./RoutesHelper";
import HomePage from "../components/home/HomePage";
import { LoginPage, LogoutPage } from "../components/login/LoginPage";
import ActivationPage from "../components/login/ActivationPage";
import Simulator from "../components/simulator/Simulator";
import Research from "../components/research/Research";
import ErrorPage from "../components/general/error/ErrorPage";
import Account from "../components/account/Account";
import { ActivateAccountPage } from "../components/login/ActivateAccountPage";
import SimulatorResults from "../components/simulator/SimulatorResults";
import AidDetail from "../components/aid/AidDetail";
import SimulatorHelp from "../components/simulator/SimulatorHelp";
import AidCatalog from "../components/aid/aidCatalog/AidCatalog";
import PrivacyPolicy from "../components/privacyPolicy/PrivacyPolicy";

function FrontRoutes(props) {
  return (
    <Routes>
      <Route
        path={Routing.app_home}
        element={
          <HomePage
            withCookie={props.withCookie}
            hasSimulationCookie={props.hasSimulationCookie}
            isCookieContentOpened={props.isCookieContentOpened}
            verifyCookieChoice={props.verifyCookieChoice}
          />
        }
      />
      <Route
        path={Routing.app_login}
        element={
          <LoginPage
            pageType={"login"}
            withCookie={props.withCookie}
            isCookieContentOpened={props.isCookieContentOpened}
            verifyCookieChoice={props.verifyCookieChoice}
            cookieContentHeight={props.cookieContentHeight}
          />
        }
      />
      <Route
        path={Routing.app_register}
        element={
          <LoginPage
            pageType={"register"}
            withCookie={props.withCookie}
            isCookieContentOpened={props.isCookieContentOpened}
            verifyCookieChoice={props.verifyCookieChoice}
            cookieContentHeight={props.cookieContentHeight}
          />
        }
      />
      <Route
        path={Routing.active_user}
        element={<ActivationPage pageType={"activate"} />}
      />
      <Route
        path={Routing.reset_password}
        element={<ActivationPage pageType={"reset-password"} />}
      />
      <Route
        path={Routing.activate_user_account}
        element={<ActivateAccountPage />}
      />

      {/** Simulateur en mode NORMAL */}
      <Route
        path={Routing.simulator}
        element={
          <Simulator
            withCookie={props.withCookie}
            redirectToHomePageIfNoCookie={props.redirectToHomePageIfNoCookie}
          />
        }
      />

      {/** Simulateur en mode BAC à SABLE */}
      <Route
        path={Routing.simulator_sand_box_mode}
        element={
          <Simulator
            mode={props.mode}
            userId={props.userIdInSandboxMode}
            organizationUuid={props.organizationUuidInSandboxMode}
            simulatorUuid={props.simulatorUuid}
          />
        }
      />

      <Route path={Routing.simulator_help} element={<SimulatorHelp />} />
      <Route
        path={Routing.simulator_results}
        element={
          <SimulatorResults
            onLogin={props.onLogin}
            withCookie={props.withCookie}
          />
        }
      />
      <Route path={Routing.research} element={<Research />} />
      <Route path={Routing.app_logout} element={<LogoutPage />} />
      <Route
        path={Routing.user_account}
        element={
          <SecureRoute
            minRole={Constants.ROLE_USER}
            component={Account}
            setUserName={props.setUserName}
          />
        }
      />
      <Route path={Routing.aid_detail} element={<AidDetail />} />
      <Route path={Routing.aids_catalogs} element={<AidCatalog />} />
      <Route path={Routing.privacy_policy} element={<PrivacyPolicy />} />
    </Routes>
  );
}

function SecureRoute({ component: Component, minRole, ...props }) {
  const user = Session.getUser();

  if (!Session.isLoggedIn() || user === null) {
    return <Navigate to={Routing.app_login} replace />;
  }

  if (minRole != null && !Roles.isGranted(user?.roles[0], minRole)) {
    return (
      <ErrorPage
        code="403"
        title="Accès refusé"
        message="Vous n'avez pas les droits suffisants pour accéder à cette page"
      />
    );
  }

  return <Component {...props} />;
}

export default FrontRoutes;
