import React from "react"
import {Api, Constants} from "fsy.common-library"
import {Loading} from "../general/form/Loading"
import {CountDown} from "./CountDown"
import {Button, TextField} from "@mui/material"
import LockOpenIcon from "@mui/icons-material/LockOpen"

import "./activationPage.css"
import {Circle} from "@mui/icons-material";

export default function ActivationPage(pageType) {
    return <section className="activate">
        <div className="activate-title">
            <h1>Activer votre compte</h1>
        </div>
        <ActivationForm pageType={pageType.pageType}/>
    </section>
}


export class ActivationForm extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)

        this.state = {
            token: null,
            user: null,
            password: "",
            passwordConfirm: "",
            passwordLength: false,
            passwordNumber: false,
            passwordLowerCase: false,
            passwordUppercase: false,
            passwordSpecialChar: false,
            passwordsMatchs: false,
            passwordValidity: false,
            loading: false,
            error: false,
            success: false,
            errorMessage: null,
            feedbackMessage: null,
            pageType: props.pageType
        }
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handlePasswordConfirmChange = this.handlePasswordConfirmChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.checkPasswordLength = this.checkPasswordLength.bind(this)
        this.checkPasswordNumber = this.checkPasswordNumber.bind(this)
        this.checkPasswordLowerCase = this.checkPasswordLowerCase.bind(this)
        this.checkPasswordUppercase = this.checkPasswordUppercase.bind(this)
        this.checkPasswordSpecialChar = this.checkPasswordSpecialChar.bind(this)
        this.checkPasswordsMatchs = this.checkPasswordsMatchs.bind(this)
        this.checkPasswordValidity = this.checkPasswordValidity.bind(this)
        this.handleActivateClick = this.handleActivateClick.bind(this)
    }

    handlePasswordChange(e) {
        const newPassword = e.target.value

        this.setState({
            password: newPassword
        }, () => {
            this.setState({
                passwordLength: this.checkPasswordLength(),
                passwordNumber: this.checkPasswordNumber(),
                passwordLowerCase: this.checkPasswordLowerCase(),
                passwordUppercase: this.checkPasswordUppercase(),
                passwordSpecialChar: this.checkPasswordSpecialChar(),
                passwordsMatchs: this.checkPasswordsMatchs()
            }, () => {
                this.setState({
                    passwordValidity: this.checkPasswordValidity()
                })
            })
        })
    }

    handlePasswordConfirmChange(e) {
        this.setState({
            passwordConfirm: e.target.value
        }, () => {
            this.setState({
                passwordLength: this.checkPasswordLength(),
                passwordNumber: this.checkPasswordNumber(),
                passwordLowerCase: this.checkPasswordLowerCase(),
                passwordUppercase: this.checkPasswordUppercase(),
                passwordSpecialChar: this.checkPasswordSpecialChar(),
                passwordsMatchs: this.checkPasswordsMatchs()
            }, () => {
                this.setState({
                    passwordValidity: this.checkPasswordValidity()
                })
            })
        })
    }

    handleFormSubmit() {
        (async function () {
            this.setLoading()

            // With library
            const response = await Api.user.resetUserPassword(this.state.user.id, this.state.password, this.state.passwordConfirm)

            let message = "Le mot de passe bien a été créé et votre compte est activé", success = true
            if (response.status !== Constants.HTTP_OK) {
                success = false
                switch (response.code) {
                    case Constants.HTTP_PASSWORD_DO_NOT_MATCH:
                        message = "Impossible d'activer le compte : les deux mots de passes doivent être identiques"
                        break
                    case Constants.HTTP_PASSWORD_TOO_WEAK:
                        message = "Impossible d'activer le compte : le mot de passe saisi ne respecte pas les règles de sécurité"
                        break
                    default:
                        message = "Une erreur est survenue, merci de rééssayer ou de contacter un administrateur"
                        break
                }
            }

            this.setState({success: success})
            this.displayFeedbackMessage(message, success)
        }).bind(this)()
    }

    handleActivateClick() {
        (async function () {
            this.setLoading()

            const response = await Api.user.activateUser(this.state.user.id)

            let message = "", success = true
            if (response.status === Constants.HTTP_OK) {
                message = "Un mail d'activation a été envoyé. Veuillez suivre les instructions contenues dans celui-ci"
            } else {
                message = "Une erreur est survenue, merci de rééssayer ou de contacter un administrateur"
                success = false
            }

            this.displayFeedbackMessage(message, success)
        }).bind(this)()
    }

    checkPasswordLength() {
        return this.state.password.length >= 8
    }

    checkPasswordNumber() {
        return (new RegExp(/[0-9]/)).test(this.state.password)
    }

    checkPasswordLowerCase() {
        return (new RegExp(/[a-z]/)).test(this.state.password)
    }

    checkPasswordUppercase() {
        return (new RegExp(/[A-Z]/)).test(this.state.password)
    }

    checkPasswordSpecialChar() {
        return (new RegExp(/[-_#@$%*!?&]/)).test(this.state.password)
    }

    checkPasswordsMatchs() {
        return this.state.password === this.state.passwordConfirm && this.state.password !== "" && this.state.passwordConfirm !== ""
    }

    checkPasswordValidity() {
        return this.state.passwordLength && this.state.passwordNumber && this.state.passwordLowerCase && this.state.passwordUppercase && this.state.passwordSpecialChar && this.state.passwordsMatchs
    }

    setLoading(isLoading = true) {
        this.setState({loading: isLoading})
    }

    displayFeedbackMessage(message, success) {
        this.setState({
            feedbackMessage: message,
            feedbackState: success
        }, () => {
            this.setLoading(false)
        })
    }

    componentDidMount() {
        // Evite de retourner une promesse avec l'utilisatation de await
        (async function () {
            this.setLoading()
            const queryString = window.location.search
            const token = new URLSearchParams(queryString).get("token")

            const response = await Api.user.checkActivationToken(token)

            if (response?.error) {
                let message = "", user = null
                switch (response.code) {
                    case Constants.HTTP_INVALID_TOKEN:
                        message = "Le token que vous avez fourni n'est pas valide. Vérifiez que vous avez bien copier le lien reçu par mail"
                        break
                    case Constants.HTTP_TOKEN_EXPIRED:
                        message = "Le token d'activation de votre compte a expiré. Veuillez en générer un nouveau en utilisant le boutton ci-dessous"
                        user = response.data.user
                        break
                    default:
                        message = "Une erreur est survenue, merci de rééssayer ou de contacter un administrateur"
                        break
                }
                this.setState({error: true, errorMessage: message, user: user}, () => {
                    this.setLoading(false)
                })
            } else {
                this.setState({token: token, user: response.data}, () => {
                    this.setLoading(false)
                })
            }
        }).bind(this)()
    }

    render() {
        return <div className="activate">
            {this.state.loading && <Loading/>}
            <div className="activate-description">
                {this.state.user &&
                    <span>{this.state.pageType === "activate" ?
                        `Pour activer le compte lié à l'adresse "${this.state.user?.email}", veuillez choisir un mot de passe robuste` :
                        `Veuillez choisir un nouveau mot de passe robuste pour le compte lié à l'adresse "${this.state.user?.email}"`}</span>
                }
            </div>
            <div className="activate-card">
                {this.state.error && <div className="activate-error">
                    <div>{this.state.errorMessage}</div>
                    {this.state.user && <div>
                        <Button type="button" name="activateButton" className="primary-btn" title="Activer mon compte"
                                variant="contained" onClick={this.handleActivateClick} color="success" size="large">
                            <LockOpenIcon/> <span>Activer mon compte</span>
                        </Button>
                    </div>}
                </div>
                }

                {(this.state.user && !this.state.error) && <>
                    <div className="activate-form">
                        <TextField name="password" type="password" label="Nouveau mot de passe" margin="normal"
                                   color="success" variant="outlined" size="small" title="Nouveau mot de passe"
                                   value={this.state.password} required onChange={this.handlePasswordChange}/>
                        <TextField name="passwordConfirm" type="password" label="Confirmer mot de passe" margin="normal"
                                   color="success" variant="outlined" size="small" title="Confirmer mot de passe"
                                   value={this.state.passwordConfirm} required
                                   onChange={this.handlePasswordConfirmChange}/>
                        <Button type="button" name="submitButton" className="primary-btn" title="Activer mon compte"
                                variant="contained" onClick={this.handleFormSubmit} size="large"
                                disabled={!this.state.passwordValidity}>
                            <LockOpenIcon/> <span>Activer mon compte</span>
                        </Button>
                    </div>
                    <div className="activate-security">
                        <div>Le mot de passe doit contenir :</div>
                        <div className="activate-security-requirements">
                        <span className={this.state.passwordLength ? "valid" : "not-valid"}>
                            <Circle/> Au moins 8 caractères
                        </span>
                            <span className={this.state.passwordLowerCase ? "valid" : "not-valid"}>
                            <Circle/> Au moins 1 minuscule
                        </span>
                            <span className={this.state.passwordUppercase ? "valid" : "not-valid"}>
                            <Circle/> Au moins 1 majuscule
                        </span>
                            <span className={this.state.passwordNumber ? "valid" : "not-valid"}>
                            <Circle/> Au moins 1 chiffre
                        </span>
                            <span className={this.state.passwordSpecialChar ? "valid" : "not-valid"}>
                            <Circle/> Au moins 1 caractère spécial (-_#@$%*!?&)
                        </span>
                            <span className={this.state.passwordsMatchs ? "valid" : "not-valid"}>
                            <Circle/> Les deux mots de passe doivent être identiques
                        </span>
                        </div>
                    </div>
                </>}
            </div>

            {(this.state.feedbackMessage != null && !this.state.feedbackState) && <div className="activate-feedback">
                <span className={"error"}>{this.state.feedbackMessage}</span>
            </div>}

            {(this.state.success) && <div className="activate-feedback">
                <>
                    <CountDown message={this.state.feedbackMessage} className={"success"}/>
                </>
            </div>}
        </div>
    }
}
