import React from "react"
import "./research.css"

export default function Research() {

    return <section className="research">
        <div className="home-title">
            <h1>W.I.P</h1>
        </div>
    </section>
}
