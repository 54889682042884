import React, {useState, useEffect, useRef} from "react"
import {IconButton, InputAdornment, OutlinedInput, Slider} from "@mui/material"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { BACKSPACE_KEY_LABEL, COMMA_CHAR_CODE, ENTER_KEY_LABEL, NINE_CHAR_CODE, POINT_CHAR_CODE, ZERO_CHAR_CODE } from "../../../services/Constants";

export default function AnswersNUM({question, onChange, defaultValue = null, nextButtonRef, answersFormContentRef}) {
    const [state, setState] = useState({
        question: question,
        shortName: question.criterion.shortName,
        unit: question.criterion.unit,
        step: question.criterion.step,
        valueMin: question.criterion.valueMin,
        valueMax: question.criterion.valueMax,
        answer: "",
        hasChanged: false,
        answerOnDelete: "",
        hasClickedToBackSpace: false,
        previousAnswer: "",
        lastCharCode: null,
        altValue: null
    })

    const formRef = useRef(null)
    const iconRef = useRef(null)
    const sliderRef = useRef(null)

    const _setState = values => {
        setState(previousState => {
            return {...previousState, ...values}
        })
    }

    useEffect(() => {
        _setState({
            answer: defaultValue !== null ? defaultValue.value : "",
            unit: question.criterion.unit,
            step: question.criterion.step,
            shortName: question.criterion.shortName,
            specific: question.criterion.specific,
            valueMin: question.criterion.valueMin,
            valueMax: question.criterion.valueMax
        })
    }, [question, defaultValue])

    const _handleValueChange = (newValue) => {
        _setState({hasChanged: true})
        onChange(newValue)
    }

    const _formatValueLabel = (value) => `${value} ${state.unit}`

    const _handleSliderChange = (event, newValue, activeThumb) => {
        _setState({answer: newValue})
        _handleValueChange(newValue)
    }

    const _handleInputChange = (event) => {
        let newValue = event.target.value === "" ? "" : Number(event.target.value)

        _setState({previousAnswer: state.answer, answer: newValue})
        _handleValueChange(newValue)
    }

    const _handleKeyPress = (event) => {
        const charCode = event.charCode
        const value = state.altValue ?? state.answer.toString()
        const condition1 = charCode >= ZERO_CHAR_CODE && charCode <= NINE_CHAR_CODE // Il faut accepter que les chiffres
        const condition2 = [COMMA_CHAR_CODE, POINT_CHAR_CODE].includes(charCode) && // Si on appuie sur "," ou "."
            value && // Et s'il y a déjà de valeur non vide
            !value.includes(',') && // Et si la valeur n'est pas décimale
            !value.includes('.') // Même condition que d'en haut

        const finalCondition = condition1 || condition2

        _setState({ lastCharCode: charCode })

        if(!finalCondition){
            event.preventDefault()
        }
    }

    const _getAnswerAfterCheckingMinMax = () => {
        let value = state.answer

        if (state.answer < state.valueMin) {
            value = state.valueMin
        } else if (state.answer > state.valueMax) {
            value = state.valueMax
        }

        return value
    }

    const _handleBlur = () => {
        const value = _getAnswerAfterCheckingMinMax()

        _setState({answer: value})
        _handleValueChange(value)
    }

    const _handleKeyDown = async event => {
        if(event.key === ENTER_KEY_LABEL && nextButtonRef?.current){
            if(state.hasChanged){
                const value = _getAnswerAfterCheckingMinMax()
        
                await new Promise(resolve => {
                    _setState({ answer: value })
                    _handleValueChange(value)
                    resolve()
                })
            }

            nextButtonRef.current.click()
        }

        if(event.key === BACKSPACE_KEY_LABEL){
            _setState({ answerOnDelete: Number(event.target.value) })
        }

        _setState({ hasClickedToBackSpace: event.key === BACKSPACE_KEY_LABEL })
    }

    // Dans les 2 useEffect qui suivent, le but c'est de déterminer l'enchaînement de chaîne saisie par l'utilisateur au cas où c'est un nombre décimal
    // Détection de l'appui sur "." ou "," dans le formulaire de type number car l'appui sur "15." est toujours considéré comme "15" dans le navigateur
    useEffect(() => {
        let altValue = state.answer.toString()
        const previousAnswer = state.previousAnswer.toString()

        const withoutDecimal = altValue && !altValue.includes(',') && !altValue.includes('.')
        const condition1 = withoutDecimal && [COMMA_CHAR_CODE, POINT_CHAR_CODE].includes(state.lastCharCode)
        const condition2 = withoutDecimal && (previousAnswer.includes('.') || previousAnswer.includes(','))

        if(condition1 || condition2){
            altValue = `${altValue}.`
        }

        _setState({ altValue })

    }, [ state.answer, state.lastCharCode, state.previousAnswer ])

    // Détection de suppression de la séparation décimale (, ou .)
    useEffect(() => {
        if(state.hasClickedToBackSpace && state.answerOnDelete && state.answer){
            let { answerOnDelete, answer: currentAnswer } = state
            answerOnDelete = answerOnDelete.toString()
            currentAnswer = currentAnswer.toString()

            if(
                answerOnDelete === currentAnswer && 
                !answerOnDelete.includes(',') &&
                !answerOnDelete.includes('.') && 
                !currentAnswer.includes(',') &&
                !currentAnswer.includes('.')
            )
            {
                let altValue = state.altValue

                altValue = altValue.replaceAll(',', '')
                altValue = altValue.replaceAll('.', '')

                _setState({ altValue })
            }

            _setState({ hasClickedToBackSpace: false })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ state.hasClickedToBackSpace, state.answerOnDelete, state.answer ])

    useEffect(() => {
        const formWidth = formRef.current?.offsetWidth
        const iconContentWidth = iconRef.current?.offsetWidth
        const sliderWidth = sliderRef.current?.offsetWidth

        if(formWidth && iconContentWidth && sliderWidth){
            const formMargin = window.getComputedStyle(formRef.current)?.getPropertyValue('margin-right')?.split('px')?.[0] || 0

            answersFormContentRef.current = {
                offsetWidth: formWidth + iconContentWidth + sliderWidth + (2 * Number(formMargin))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formRef.current?.offsetWidth,
        iconRef.current?.offsetWidth,
        sliderRef.current?.offsetWidth
    ])

    /* =================================== Render  =================================== */


    return <div className="flex align-items-center">
        <OutlinedInput
            ref={formRef}
            className="numeric-input"
            value={typeof state.answer === "number" ? state.answer : ""}
            size="small"
            onChange={_handleInputChange}
            onBlur={_handleBlur}
            inputProps={{
                step: state.step,
                min: state.valueMin,
                max: state.valueMax,
                type: "number",
                "aria-labelledby": "input-slider"
            }}
            endAdornment={<InputAdornment position="end">{state.unit}</InputAdornment>}
            onKeyDown={_handleKeyDown}
            onKeyPress={_handleKeyPress}
        />
        <div ref={iconRef}>
            <IconButton
                onClick={() => {
                    onChange("")
                }}
                aria-label="reset" size="small">
                <RestartAltIcon/>
            </IconButton>
        </div>
        <Slider
            ref={sliderRef}
            className="numeric-slider"
            value={typeof state.answer === "number" ? state.answer : state.valueMin}
            onChange={_handleSliderChange}
            getAriaValueText={_formatValueLabel}
            valueLabelFormat={_formatValueLabel}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
            min={state.valueMin}
            max={state.valueMax}
            step={state.step}
        />
    </div>
}