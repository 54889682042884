import React from "react";
import "./privacyPolicy.css";
import { NavLink } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";
import { ArrowBackIos } from "@mui/icons-material";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-root">
      {/** Partie de titre */}
      <div className="title">
        <NavLink
          to={Routing.app_home}
          title="Retour au simulateur"
          className="flex back-to-homepage"
          reloadDocument
        >
          <ArrowBackIos />
          <span>Retour au simulateur</span>
        </NavLink>
        <h1>Politique de confidentialité</h1>
      </div>

      {/** Contenu */}
      <div className="content">Contenu en cours de rédaction...</div>
    </div>
  );
};

export default PrivacyPolicy;
