import React from "react"
import {Link, Navigate} from "react-router-dom"

export class CountDown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            timer: 3,
            redirect: false
        }

        const interval = setInterval(() => {
            if (this.state.timer) {
                this.setState({
                    timer: (this.state.timer - 1)
                })

                if (this.state.timer === 1) {
                    clearInterval(interval)
                    this.setState({
                        redirect: true
                    })
                }
            }
        }, 1000)
    }

    render() {
        return <React.Fragment>
            <p style={{textAlign: "center", fontWeight: "bold"}}>
                <span className={this.props.className}>{this.props.message}</span>
            </p>
            <div style={{textAlign: "center", fontSize: "0.9rem"}}>
                Vous serez redirigé vers la page du login dans <span
                style={{display: "inline-block", width: "8px"}}>{this.state.timer}</span> secondes.
                <div style={{marginTop: "7px"}}>
                    <Link to={"/login"} style={{color: "white"}} reloadDocument>
                        Cliquez ici si la redirection ne fonctionne pas
                    </Link>
                </div>
            </div>
            {this.state.redirect && <Navigate to="/login" replace={true}/>}
        </React.Fragment>
    }
}
