import React, {useState, useEffect} from "react"
import {Api} from "fsy.common-library"
import Helper from "../../../services/Helper"
import {toast} from "react-toastify"
import {CRITERIA_CRIPLAN01, ERR_MSG_SIMULATION_LOADING} from "../../../services/Constants"
import {Loading} from "../../general/form/Loading"
import Criplan01 from "./Criplan01"
import _ from "lodash"
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import { Add } from "@mui/icons-material"

export default function AnswersSPEC({question, onChange, defaultValue = null, answersFormContentRef}) {
    const [state, setState] = useState({
        question: question,
        shortName: question.criterion.shortName,
        answers: [{specie: null, percent: 0}],
        speciesList: [],
        loading: true,
        error: false,
        errorMessage: ""
    })

    const _setState = values => {
        setState(previousState => {
            return {...previousState, ...values}
        })
    }

    useEffect(() => {
        Api.species.getSpecies().then(r => {
            const speciesResponse = Helper.isValidResponse(r)
            if (speciesResponse !== false) {
                const species = speciesResponse
                let answers = []
                if (defaultValue !== null) {
                    _.each(defaultValue.answers, v => {
                        answers.push({
                            specie: _.find(species, {"id": v?.specie}),
                            percent: v?.percent
                        })
                    })
                } else {
                    answers.push({specie: null, percent: 0, locked: true})
                }
                _setState({
                    speciesList: formatSelectOptions(species),
                    answers: formatSelectAnswer(answers),
                    loading: false
                })
            } else {
                toast.error(ERR_MSG_SIMULATION_LOADING, {autoClose: 4000, position: toast.POSITION.TOP_CENTER})
                _setState({
                    speciesList: [],
                    loading: false,
                    error: true,
                    errorMessage: `Imposssible de charger la liste des essences. Code: ${r?.code} - ${r?.message}`
                })
            }
        })
        // eslint-disable-next-line
    }, [question])

    const _addLine = () => {
        let answers = state.answers
        answers.push({specie: null, percent: 0})
        _setState({answers: answers})
    }

    const _addPercents = () => {
        let total = 0
        _.each(state.answers, a => {
            total += a?.percent ?? 0
        })
        return total
    }

    const _handleValuechange = (value, type, isFirstSpecieChange) => {
        let answers = state.answers

        if (value.specie === null) {
            value.percent = 0
        } else {
            const total = _addPercents()
            const expectedTotal = 100
            const currentValue = answers[value.lineNumber].percent
            let percent = value.percent

            if(type === "specie" && total <= 100 && isFirstSpecieChange){
                percent = expectedTotal - total
            }

            if(type === 'percentage' && ((total - currentValue) + value.percent > 100)){
                percent = currentValue
            }

            answers[value.lineNumber] = {
                specie: value.specie?.id, 
                percent: percent
            }
        }

        _setState({answers: answers})
        onChange(formatAnswer(answers))
    }

    const _handleLineDelete = number => {
        let answers = _.remove(state.answers, (v, k) => {
            return k !== number
        })

        //reset answers array to a defautl value
        if (_.isEmpty(answers)) {
            answers = [{specie: null, percent: 0, locked: true}]
            _setState({answers: answers})
            onChange(null)
        } else {
            _setState({answers: answers})
            onChange(formatAnswer(answers))
        }
    }

    /* =================================== Render  =================================== */
    if (state.loading) {
        return <div ref={answersFormContentRef} className="my-1 flex"><Loading/></div>
    }
    if (state.error) {
        return <div className="error my-1 flex">{state.errorMessage}</div>
    }
    if (state.shortName === CRITERIA_CRIPLAN01) {
        return <div className="question-specific flex flex-column" ref={answersFormContentRef}>
            <div className="percent-info">
                <span>Total pourcentages : </span><span>{_addPercents()}%</span>
            </div>
            <div className="flex flex-column align-items-start w-100">
                {state.answers.map((row, index) => (
                    <Criplan01
                        key={index}
                        lineNumber={index}
                        value={row}
                        locked={row?.locked}
                        speciesList={state.speciesList}
                        onChange={_handleValuechange}
                        onDelete={_handleLineDelete}
                        rowsValueTotal={_addPercents()}
                        rowsCount={state.answers?.length || 0}
                    />
                ))}
            </div>
            <div className="essence-creation-button-block">
                <div className="empty-button-icon-with-specie" />
                <LoadingButton 
                    variant="outlined" 
                    size="large" 
                    name="newEssenceButton"
                    startIcon={<Add />} 
                    title="Ajouter une essence"
                    onClick={_addLine}
                    loadingPosition="start"
                    disabled={state.loading}
                >
                    Ajouter une essence
                </LoadingButton>
            </div>
        </div>
    }

    return <div className="flex align-items-start">

    </div>
}

function formatSelectOptions(species) {
    return _.map(species, s => {
        return {...s, value: s?.id}
    })
}

function formatSelectAnswer(answers) {
    return _.map(answers, a => {
        if (a.specie !== null && a.specie !== undefined) {
            a.specie.value = a.specie?.id
            return {...a}
        }
        return a
    })
}

function formatAnswer(answers) {
    return _.map(answers, a => {
        if (a.specie !== null) {
            const id = a?.specie.hasOwnProperty("id") ? a.specie.id : a.specie
            return {specie: id, percent: a.percent}
        }
        return a
    })
}