import React, { useEffect, useRef, useState } from "react";
import DataTable from "../general/table/Table";
import { TextField, Button, Divider, FormGroup } from "@mui/material";
import { Api, Constants, Session } from "fsy.common-library";
import Helper from "../../services/Helper";
import moment from "moment";
import { Loading } from "../general/form/Loading";
import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  HTTP_NO_CONTENT,
  HTTP_OK,
  HTTP_PASSWORD_TOO_WEAK,
  HTTP_WRONG_OLD_PASSWORD,
} from "fsy.common-library/lib/env/Constants";
import Modal from "../general/form/Modal";
import { NavLink, useNavigate } from "react-router-dom";
import { routes as Routing } from "../../services/RoutesHelper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  COOKIE_RESULTS_SIMULATION,
  COOKIE_RESULTS_SIMULATION_TEMP,
  COOKIE_SESSION,
  SESSION_EXPIRATION_DAYS,
  STORAGE_ORG_UUID,
} from "../../services/Constants";

import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";

import "./account.css";
import ButtonToAidsCatalogs from "../home/ButtonToAidsCatalogs";
import { Save } from "@mui/icons-material";
import { toast } from "react-toastify";
import SessionHelper from "fsy.common-library/lib/helpers/SessionHelper";

const Account = (props) => {
  const user = Session.getSessionUser();

  const ITEMS_PER_PAGE = 10;

  const columns = [
    { field: "num", headerName: "Numéro", align: "center" },
    { field: "date", headerName: "Date" },
    { field: "name", headerName: "Nom", editable: true },
    { field: "nbHelp", headerName: "Nb d'aides", align: "center" },
    {
      field: "pdf",
      headerName: "PDF",
      component: (data) => (
        <div
          className="pdf-icon-content"
          onClick={() => _handleDownloadPDF(data)}
        >
          <PictureAsPdfIcon />
        </div>
      ),
      align: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      component: (data) => (
        <div className="action-content">
          <Button variant="outlined" onClick={() => _handleConsultResult(data)}>
            {data.state === Constants.SIMULATION_STATUS_IN_PROGRESS
              ? "Reprendre"
              : "Résultats"}
          </Button>
        </div>
      ),
      align: "center",
    },
  ];

  const simulationId = useRef(null);

  const navigate = useNavigate();

  const [state, setState] = useState({
    isPasswordUpdateDialogOpened: false,
    isAccountDeletionConfirmationOpened: false,
    isPasswordFormModified: false,
    pdfLoading: false,
    simulationLoading: false,
    userInfoLoading: false,
    passwordLoading: false,
    accountDeletionLoading: false,
    user: {
      name: "",
      surname: "",
      email: "-",
      accountCreationDate: "-",
    },
    password: {
      oldValue: "",
      value: "",
      valueForConfirmation: "",
    },
    isUpdatedUserInfo: {
      name: false,
      surname: false,
    },
    simulationTableConfig: {
      columns: columns,
      rows: [],
      rowPerPage: ITEMS_PER_PAGE,
      rowsCount: 0,
      nbPage: 0,
    },
    pdfConfig: {
      displayPDF: false,
      pdfHasError: false,
      pdfUrl: "",
    },
    isSimulationNameUpdated: false,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handlePasswordUpdateDialogDisplay = () => {
    const stateObject = {
      isPasswordUpdateDialogOpened: !state.isPasswordUpdateDialogOpened,
      isPasswordFormModified: false,
    };

    if (state.isPasswordUpdateDialogOpened) {
      stateObject.password = {
        value: "",
        valueForConfirmation: "",
      };
    }

    _setState(stateObject);
  };

  const _handleAccountDeletionModalDisplay = () => {
    _setState({
      isAccountDeletionConfirmationOpened:
        !state.isAccountDeletionConfirmationOpened,
    });
  };

  const _handleReinitPasswordInfo = () => {
    _setState({
      password: {
        oldValue: "",
        value: "",
        valueForConfirmation: "",
      },
    });
  };

  const _handlePasswordChange = (event) => {
    const { name, value } = event.target;

    _setState({
      isPasswordFormModified: true,
      password: {
        ...state.password,
        [name]: value,
      },
    });
  };

  const _formsStatus = () => {
    return (
      state.password.oldValue &&
      state.password.value &&
      state.password.valueForConfirmation
    );
  };

  const _passowrdsStatus = () => {
    return state.password.value === state.password.valueForConfirmation;
  };

  const _handleSaveNewPassword = () => {
    const isFormsCompleted = _formsStatus();
    const isSamePasswords = _passowrdsStatus();

    if (isFormsCompleted) {
      if (isSamePasswords) {
        _setState({ passwordLoading: true });
        Api.user
          .resetUserPassword(
            user.id,
            state.password.value,
            state.password.valueForConfirmation,
            state.password.oldValue
          )
          .then((response) => {
            if (response?.status !== HTTP_OK) {
              if (
                [HTTP_PASSWORD_TOO_WEAK, HTTP_WRONG_OLD_PASSWORD].includes(
                  response?.status
                )
              ) {
                let message = "";
                let duration = 0;

                if (response.status === HTTP_PASSWORD_TOO_WEAK) {
                  message =
                    "Le mot de passe doit contenir au moins, une lettre en majuscule, une lettre en minuscule, un chiffre et un caractère spécial";
                  duration = 5000;
                } else if (response.status === HTTP_WRONG_OLD_PASSWORD) {
                  message = "Ancien mot de passe incorect.";
                  duration = 2000;
                }

                toast.error(message, GenericHelper.getToastOptions(duration));
              } else {
                toast.error(
                  "Une erreur est survenue lors du changement de mot de passe",
                  GenericHelper.getToastOptions()
                );
              }
            } else {
              toast.success(
                "Mot de passe mis à jour avec succès.",
                GenericHelper.getToastOptions()
              );

              _handleReinitPasswordInfo();
              _handlePasswordUpdateDialogDisplay();
            }

            _setState({ passwordLoading: false });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        toast.error(
          "Le nouveau mot de passe et celui pour confirmation doivent être identiques",
          GenericHelper.getToastOptions()
        );
      }
    } else {
      toast.error(
        "Les formulaires doivent êtres renseignés pour la modification de mot de passe",
        GenericHelper.getToastOptions()
      );
    }
  };

  const _handleConsultResult = (data) => {
    if (data.state === Constants.SIMULATION_STATUS_IN_PROGRESS) {
    }
    if (data.state === Constants.SIMULATION_STATUS_REGISTERED) {
      Session.setLocalStorage(
        COOKIE_RESULTS_SIMULATION_TEMP,
        JSON.stringify({
          id: data.id,
          simulator: {
            id: data.realData.simulator.id,
            version: data.realData.simulator.versionNumber,
          },
        })
      );

      Session.setCookie(COOKIE_RESULTS_SIMULATION, {
        id: data.id,
        simulator: {
          id: data.realData.simulator.id,
          version: data.realData.simulator.versionNumber,
        },
        state: Constants.SIMULATION_STATUS_REGISTERED,
      });

      window.location.href = Routing.simulator_results + "?viewmode=temp";
    }
  };

  const _handlePageChange = (newPage) => {
    getSimulations(newPage + 1);
  };

  const handleNomSimulationUpdate = (row, index, newValue) => {
    const rows = [...state.simulationTableConfig.rows];
    const i = rows.findIndex((element) => element.id === row.id);

    if (i !== -1) {
      rows[i].name = newValue;
      const stateObject = {
        simulationTableConfig: {
          ...state.simulationTableConfig,
          rows,
        },
      };

      if (!state.isSimulationNameUpdated) {
        stateObject.isSimulationNameUpdated = true;
      }

      _setState(stateObject);
    }
  };

  const _handleSimulationNameFormBlur = async (event, row) => {
    if (state.isSimulationNameUpdated) {
      const { value } = event.target;
      const { id } = row;

      _setState({ simulationLoading: true });
      Api.simulation
        .updateSimulation(id, {
          label: !["", null].includes(value) ? value : null,
        })
        .then((response) => {
          const result = Helper.isValidResponse(response);

          if (result) {
            toast.success(
              "Modification sauvegardée",
              GenericHelper.getToastOptions()
            );
          }

          _setState({
            simulationLoading: false,
            isSimulationNameUpdated: false,
          });
        });
    }
  };

  const _handleUserInfoFormBlur = (event) => {
    const { name } = event.target;

    if (state.isUpdatedUserInfo[name]) {
      _setState({ userInfoLoading: true });
      Api.user
        .updateUser(user.id, { [name]: state.user[name] })
        .then((response) => {
          const result = Helper.isValidResponse(response);
          const stateObject = {
            userInfoLoading: false,
          };

          if (result) {
            if (result[name]) {
              const userToUpdate = {
                ...user,
                [name]: result[name],
              };

              Session.setCookie(
                COOKIE_SESSION,
                userToUpdate,
                SESSION_EXPIRATION_DAYS
              );

              if (name === "name") {
                props.setUserName(result[name]);
              }
            }

            stateObject.isUpdatedUserInfo = {
              name: false,
              surname: false,
            };

            toast.success(
              "Information mise à jour avec succès.",
              GenericHelper.getToastOptions()
            );
          }

          _setState(stateObject);
        });
    }
  };

  const _handleUserInfoChange = (event) => {
    const { value, name } = event.target;

    _setState({
      user: {
        ...state.user,
        [name]: value,
      },
      isUpdatedUserInfo: {
        ...state.isUpdatedUserInfo,
        [name]: true,
      },
    });
  };

  const _handleDeleteAccount = () => {
    _setState({ accountDeletionLoading: true });
    Api.user.deleteAccount(user.id).then((response) => {
      if ([HTTP_OK, HTTP_NO_CONTENT].includes(response?.status)) {
        _setState({ accountDeletionLoading: false });
        _handleAccountDeletionModalDisplay();
        // Suppression des cookies
        Session.expiresAllCookies(Constants.PREFIX_LOCAL_STORAGE);

        // Suppression de REFRESH TOKEN et JWT dans le localStorage
        SessionHelper.removeRefreshToken();
        SessionHelper.removeJwtToken();
        navigate(Routing.app_home);
      } else {
        _setState({ accountDeletionLoading: false });
        _handleAccountDeletionModalDisplay();
        toast.error(
          "Une erreur est survenue pendant la suppression du compte.",
          GenericHelper.getToastOptions()
        );
      }
    });
  };

  const getSimulations = (page) => {
    _setState({ simulationLoading: true });

    Api.simulation
      .getSimulationsForCurrentUser(Session.getLocalStorage(STORAGE_ORG_UUID))
      .then((response) => {
        if (response) {
          let resultObject = Helper.isValidResponse(response);
          const count = response?.data?.hasOwnProperty("hydra:totalItems")
            ? response.data["hydra:totalItems"]
            : resultObject?.length || 0;
          const rws = [];

          if (resultObject) {
            resultObject?.map((data) => {
              let { status } = data;

              rws.push({
                id: data["id"],
                num: `#${data["id"]}`,
                date: moment(data["date"]).format("DD-MM-YYYY HH:mm"),
                name: data["label"] || "",
                nbHelp: data["suggestedAids"]?.length,
                state: status,
                realData: data,
              });

              return null;
            });
          }

          _setState({
            simulationTableConfig: {
              ...state.simulationTableConfig,
              rows: rws,
              rowsCount: count,
            },
            simulationLoading: false,
          });
        }
      });
  };

  const _getUserInformation = () => {
    _setState({ userInfoLoading: true });
    Api.user.getUserByJwt(Session.getJwtToken()).then((response) => {
      if (response?.data) {
        _setState({
          userInfoLoading: false,
          user: {
            ...state.user,
            name: response.data.name,
            surname: response.data.surname,
            email: response.data.email,
            accountCreationDate: response.data.creationDate
              ? moment(response.data.creationDate).format("DD/MM/YYYY")
              : "-",
          },
        });
      } else {
        _setState({ userInfoLoading: false });
      }
    });
  };

  const _downloadPDF = (simuId) => {
    _setState({ pdfLoading: true });
    Api.simulation.generatePDF(simuId.current).then((response) => {
      const pdf = Helper.isValidResponse(response);
      const stateObject = {
        pdfLoading: false,
      };

      if (pdf?.success) {
        stateObject.pdfConfig = {
          displayPDF: true,
          pdfUrl: pdf.path,
        };
      } else {
        stateObject.pdfConfig = {
          displayPDF: false,
          pdfHasError: true,
        };
        Helper.displayApiToastResult(response);
      }

      _setState(stateObject);
    });
  };

  const _handleDownloadPDF = (data) => {
    simulationId.current = data.id;

    _downloadPDF(simulationId);
  };

  useEffect(() => {
    getSimulations(1);
    _getUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal
        title="Modification de mot de passe"
        isShowing={state.isPasswordUpdateDialogOpened}
        hide={_handlePasswordUpdateDialogDisplay}
        confirm={state.isPasswordFormModified}
        saveBeforeClose={_handleSaveNewPassword}
      >
        {state.passwordLoading && <Loading />}
        <div className="password-update-form-root">
          <FormGroup className="forms">
            <TextField
              name="oldValue"
              type="password"
              label="Ancien mot de passe"
              margin="normal"
              color="success"
              variant="standard"
              size="small"
              title="Ancien mot de passe"
              value={state.password.oldValue}
              onChange={_handlePasswordChange}
            />

            <TextField
              name="value"
              type="password"
              label="Nouveau mot de passe"
              margin="normal"
              color="success"
              variant="standard"
              size="small"
              title="Nouveau mot de passe"
              value={state.password.value}
              onChange={_handlePasswordChange}
            />

            <TextField
              name="valueForConfirmation"
              type="password"
              label="Confirmation de mot de passe"
              margin="normal"
              color="success"
              variant="standard"
              size="small"
              title="Confirmation de mot de passe"
              value={state.password.valueForConfirmation}
              onChange={_handlePasswordChange}
            />
          </FormGroup>
          <div className="form-actions">
            <button
              className="btn btn-success"
              type="submit"
              title="Enregistrer les données saisies"
              onClick={_handleSaveNewPassword}
            >
              <Save />
              <div>Enregistrer</div>
            </button>
          </div>
        </div>
      </Modal>
      <div className="to-homepage-content">
        <div className="button-to-homepage">
          <NavLink to={Routing.app_home}>
            <Button
              variant="outlined"
              size="small"
              title="Retourner à l'accueil du simulateur"
              startIcon={<ArrowBackIosIcon />}
            >
              Retour à l'accueil
            </Button>
          </NavLink>
        </div>
        <ButtonToAidsCatalogs className="aids-catalogs-from-account-page" />
      </div>
      <div className="flex-start align-items-start account">
        <Modal
          isShowing={state.isAccountDeletionConfirmationOpened}
          contentClassName="modal-confirmation-content"
          hide={_handleAccountDeletionModalDisplay}
        >
          <div className="modal-confirmation-text-content">
            {state.accountDeletionLoading && <Loading />}
            <p>
              Attention après suppression il vous sera <span>impossible</span>{" "}
              de récupérer vos anciennes simulations.
            </p>
            <p>
              Cette action est <span>définitive</span>
            </p>
            <p>
              <span>Etes-vous sûr de vouloir supprimer votre compte ?</span>
            </p>
          </div>
          <div className="modal-confirmation-button-content">
            <Button
              disabled={state.accountDeletionLoading}
              className="confirm-button"
              variant="text"
              children="Oui, supprimer"
              onClick={_handleDeleteAccount}
            />
            <Button
              className="back-button"
              variant="contained"
              children="Annuler"
              onClick={_handleAccountDeletionModalDisplay}
            />
          </div>
        </Modal>
        {state.pdfConfig.displayPDF && (
          <div className="simulator-pdf-container">
            <div className="simulator-pdf">
              <Button
                title="Quitter la vue"
                variant="contained"
                size="small"
                className="simulator-pdf-exit"
                onClick={() => {
                  _setState({
                    pdfConfig: {
                      ...state.pdfConfig,
                      displayPDF: false,
                      pdfUrl: "",
                    },
                  });
                }}
                children={<CancelIcon />}
              />
              <iframe src={state.pdfConfig.pdfUrl} title="PDF file view" />
            </div>
          </div>
        )}
        <div className="w-80">
          <div className="table-account-content">
            {(state.simulationLoading || state.pdfLoading) && <Loading />}
            <h3>Mes simulations</h3>
            <DataTable
              height="calc(100vh - 85px - 39px - 52px)"
              columns={state.simulationTableConfig.columns}
              rows={state.simulationTableConfig.rows}
              rowsCount={state.simulationTableConfig.rowsCount}
              onRequestPageChange={_handlePageChange}
              onRequestRowUpdate={(row, index, newValue) =>
                handleNomSimulationUpdate(row, index, newValue)
              }
              onRequestFormBlur={(event, row) =>
                _handleSimulationNameFormBlur(event, row)
              }
            />
          </div>
        </div>
        <div className="w-20">
          <div className="center information-account-content">
            {state.userInfoLoading && <Loading />}
            <h3>Mes informations</h3>
            <div className="parent-account-creation-content">
              <div className="account-creation-content">
                <div>Compte créé le :&nbsp;</div>
                <div>{state.user.accountCreationDate}</div>
              </div>
              <div className="account-email">
                <div>Email :&nbsp;</div>
                <div>{state.user.email}</div>
              </div>
            </div>

            <div className="identity-content">
              <FormGroup>
                <TextField
                  name="surname"
                  type="text"
                  label="Nom"
                  margin="normal"
                  color="success"
                  variant="standard"
                  size="small"
                  title="Nom"
                  value={state.user.surname}
                  onChange={_handleUserInfoChange}
                  onBlur={_handleUserInfoFormBlur}
                />

                <TextField
                  name="name"
                  type="text"
                  label="Prénom(s)"
                  margin="normal"
                  color="success"
                  variant="standard"
                  size="small"
                  title="Prénom(s)"
                  value={state.user.name}
                  onChange={_handleUserInfoChange}
                  onBlur={_handleUserInfoFormBlur}
                />
              </FormGroup>
            </div>

            <div className="divider-content">
              <Divider />
            </div>

            <div className="button-content">
              <Button
                variant="outlined"
                color="success"
                size="small"
                onClick={_handlePasswordUpdateDialogDisplay}
              >
                Changer de mot de passe
              </Button>
            </div>

            <div className="account-delete-button-content">
              <Button
                variant="outlined"
                size="small"
                onClick={_handleAccountDeletionModalDisplay}
                disabled={state.userInfoLoading}
              >
                Supprimer le compte
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
