import React from "react";
import CheckBox from "../general/form/CheckBox";
import "./homeCheckBox.css";

const HomeCheckBox = ({ type, isChecked, onRequestCheck }) => {
  return (
    <div className={`${type === "home" ? "mb-1" : ""} home-checkbox`}>
      <div className="checkBox-content">
        <CheckBox isChecked={isChecked} onRequestCheck={onRequestCheck} />
      </div>
      <div>
        <span>
          En cochant cette case, vous acceptez que les données collectées dans
          le cadre du simulateur soient utilisées à des fins de statistiques et
          de façon à pouvoir donner une suite à votre dossier.
        </span>
      </div>
    </div>
  );
};

export default HomeCheckBox;
