import React, { useEffect, useRef, useState } from "react";
import "./aidCatalog.css";
import AidCatalogMenu from "./AidCatalogMenu";
import AidCatalogContent from "./AidCatalogContent";
import { Api } from "fsy.common-library";
import AidHelper from "fsy.common-library/lib/helpers/AidHelper";
import Helper from "../../../services/Helper";
import {
  DEPARTEMENTAL_LOCATION_ID_VALUE,
  NATIONAL_LOCATION_ID_VALUE,
  PROJECT_ID_VALUE,
  PROJECT_TYPE_CRITERION_ID,
  REGIONAL_LOCATION_ID_VALUE,
} from "../../../services/Constants";
import { LoadingOverlay } from "../../general/form/Loading";

const AidCatalog = () => {
  const [state, setState] = useState({
    loading: true,
    partialLoading: false,
    aidsCount: 0,
    aids: [],
    regions: [],
    departments: [],
    criterionValues: [],
    filters: [],
    species: [],
    maxFiltersContentWidth: null,
    withFiltersPagination: false,
    additionalFiltersCount: 0,
    currentFilter: null,
  });

  const filtersContentRef = useRef(null);

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const handleElementParentChange = (element) => {
    _setState({ currentFilter: element });
  };

  const _getFilteredAids = (filters) => {
    const page = 1;

    const urlParams = {
      pagination: false,
    };

    const filtersValue = {
      location: [],
      project: [],
    };

    if (filters.length > 0) {
      filters.map((filter) => {
        // National
        if (filter.id === NATIONAL_LOCATION_ID_VALUE) {
          filtersValue.location.push({
            type: "national",
            answer: null,
          });
        }

        // Régional
        if (filter.id.includes(REGIONAL_LOCATION_ID_VALUE)) {
          filtersValue.location.push({
            type: "regional",
            answer: filter.realId,
          });
        }

        // Départemental
        if (filter.id.includes(DEPARTEMENTAL_LOCATION_ID_VALUE)) {
          filtersValue.location.push({
            type: "departmental",
            answer: filter.realId,
          });
        }

        // Project
        if (filter.id.includes(PROJECT_ID_VALUE)) {
          filtersValue.project.push({
            value: filter.realId,
          });
        }
        return null;
      });
    }

    urlParams.filtersData = JSON.stringify(filtersValue);

    _setState({ partialLoading: true });
    Api.aid.getFilteredAids(page, urlParams).then((response) => {
      let aids = Helper.isValidResponse(response);
      const stateObject = {
        partialLoading: false,
      };

      if (aids) {
        aids = Object.values(aids);
        stateObject.aids = AidHelper.getFormattedAids(aids);
        stateObject.aidsCount = aids.length;
      }

      _setState(stateObject);
    });
  };

  const _handleDeleteFilter = (element) => {
    const { id } = element;
    let { filters } = state;

    filters = filters.filter((element) => element.id !== id);

    const stateObject = {
      filters,
    };

    if (state.currentFilter?.id === id) {
      stateObject.currentFilter = null;
    }

    _getFilteredAids(filters);

    _setState(stateObject);
  };

  const _handleReinitFilters = () => {
    _setState({
      filters: [],
      currentFilter: null,
      additionalFiltersCount: 0,
    });

    _getFilteredAids([]);
  };

  useEffect(() => {
    const page = 1;

    const aidUrlParams = {
      pagination: false,
      filtersData: null,
    };

    const regionUrlParams = {
      pagination: false,
      active: true,
    };

    const departmentUrlParams = {
      pagination: false,
    };

    Promise.all([
      Api.aid.getFilteredAids(page, aidUrlParams),
      Api.region.getRegions(page, regionUrlParams),
      Api.department.getDepartments(page, departmentUrlParams),
      Api.criterionValue.getActiveCriterionCriteriaValues(
        PROJECT_TYPE_CRITERION_ID
      ),
      Api.species.getSpecies(),
    ])
      .then(
        ([
          aidsResponse,
          regionsResponse,
          departmentsResponse,
          criterionValuesResponse,
          speciesResponse,
        ]) => {
          const stateObject = {
            loading: false,
          };

          let aids = Helper.isValidResponse(aidsResponse);
          const regions = Helper.isValidResponse(regionsResponse);
          const departments = Helper.isValidResponse(departmentsResponse);
          const criterionValues = Helper.isValidResponse(
            criterionValuesResponse
          );
          const species = Helper.isValidResponse(speciesResponse);

          if (aids) {
            aids = Object.values(aids);
            stateObject.aids = AidHelper.getFormattedAids(aids);
            stateObject.aidsCount = aids.length;
          }

          if (regions) {
            stateObject.regions = regions;
          }

          if (departments) {
            stateObject.departments = departments;
          }

          if (criterionValues) {
            stateObject.criterionValues = criterionValues;
          }

          if (species) {
            stateObject.species = species;
          }

          _setState(stateObject);
        }
      )
      .catch((error) => {
        console.error(error);
        _setState({ loading: false });
      });
  }, []);

  useEffect(() => {
    if (
      state.currentFilter &&
      ![REGIONAL_LOCATION_ID_VALUE, DEPARTEMENTAL_LOCATION_ID_VALUE].includes(
        state.currentFilter.id
      ) &&
      !state.filters.find((element) => element.id === state.currentFilter.id)
    ) {
      const { filters } = state;
      filters.push(state.currentFilter);

      _getFilteredAids(filters);

      _setState({ filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentFilter]);

  // Largeur de contenu de filtre
  useEffect(() => {
    const currentFiltersContent = filtersContentRef.current;

    if (currentFiltersContent?.offsetWidth) {
      const currentWidth = currentFiltersContent?.offsetWidth;
      const maxWidth = state.maxFiltersContentWidth;
      const stateObject = {
        additionalFiltersCount: state.additionalFiltersCount,
      };

      if (!maxWidth) {
        stateObject.maxFiltersContentWidth = currentWidth;
      } else {
        const diff = maxWidth - currentWidth;

        if (diff < 0) {
          stateObject.additionalFiltersCount += 1;
        } else if (stateObject.additionalFiltersCount > 0) {
          stateObject.additionalFiltersCount -= 1;
        }

        stateObject.withFiltersPagination = diff < 0;
      }

      _setState(stateObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters.length]);

  return !state.loading ? (
    <div className="aid-catalog-root">
      <AidCatalogMenu
        loading={state.partialLoading}
        aidsCount={state.aidsCount}
        regions={state.regions}
        departments={state.departments}
        currentFilter={state.currentFilter}
        criterionValues={state.criterionValues}
        onRequestElementParentChange={handleElementParentChange}
      />
      <AidCatalogContent
        loading={state.partialLoading}
        aids={state.aids}
        filters={state.filters}
        species={state.species}
        filtersContentRef={filtersContentRef}
        additionalFiltersCount={state.additionalFiltersCount}
        onRequestDeleteFilter={_handleDeleteFilter}
        onRequestReinitFilters={_handleReinitFilters}
      />
    </div>
  ) : (
    <LoadingOverlay />
  );
};

export default AidCatalog;
