import React from "react";
import {
  CRITERION_TYPE_DTE,
  CRITERION_TYPE_LOC,
  CRITERION_TYPE_NUM,
  CRITERION_TYPE_OBG,
  CRITERION_TYPE_TXT,
} from "fsy.common-library/lib/env/Constants";
import AnswersTXT from "./answerTypes/AnswersTXT";
import AnswersNUM from "./answerTypes/AnswersNUM";
import AnswersOBG from "./answerTypes/AnswersOBG";
import AnswersLOC from "./answerTypes/AnswersLOC";
import AnswersDTE from "./answerTypes/AnswersDTE";
import AnswersSPEC from "./answerTypes/AnswersSPEC";
import _ from "lodash";
import {
  CRITERIA_CRIPLAN01,
  CRITERION_TYPE_SPE,
} from "../../services/Constants";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";

import "./simulator.css";

export default function SimulatorQuestion({
  question,
  onAnswer,
  defaultValue = null,
  nextButtonRef,
  answersFormContentRef,
}) {
  const specific = isSpecific(question?.criterion);
  const _answerChange = (selectedAnswers) => {
    onAnswer({
      questionId: question.id,
      type: getType(question.criterion),
      specific: question.criterion?.specific,
      shortName: question.criterion?.shortName,
      answers: selectedAnswers,
      isMulti: question.criterion?.multi,
    });
  };

  return (
    <div>
      <div className="flex-sb">
        <h4 className="question-label-content">
          {GenericHelper.textWithStyles(
            question?.questionText,
            question?.mandatory
          )}
        </h4>
      </div>
      <div className="simulator-answers">
        {question?.criterion.multi && (
          <>
            <span
              className="simulator-infoText"
              title="Plusieurs choix possibles"
            >
              Plusieurs choix possibles,{" "}
            </span>
          </>
        )}

        {!question?.mandatory && (
          <span className="simulator-infoText">Question facultative</span>
        )}
        {question?.mandatory && (
          <span className="simulator-infoText error">Question obligatoire</span>
        )}

        <div></div>
        {specific ? (
          <AnswersSPEC
            question={question}
            onChange={_answerChange}
            defaultValue={defaultValue}
            answersFormContentRef={answersFormContentRef}
          />
        ) : (
          <>
            {question?.criterion.type.shortName === CRITERION_TYPE_TXT && (
              <AnswersTXT
                question={question}
                onChange={_answerChange}
                defaultValue={defaultValue}
                isMulti={question?.criterion.multi}
                answersFormContentRef={answersFormContentRef}
              />
            )}
            {question?.criterion.type.shortName === CRITERION_TYPE_NUM && (
              <AnswersNUM
                question={question}
                onChange={_answerChange}
                defaultValue={defaultValue}
                nextButtonRef={nextButtonRef}
                answersFormContentRef={answersFormContentRef}
              />
            )}
            {question?.criterion.type.shortName === CRITERION_TYPE_OBG && (
              <AnswersOBG
                question={question}
                onChange={_answerChange}
                defaultValue={defaultValue}
              />
            )}
            {question?.criterion.type.shortName === CRITERION_TYPE_LOC && (
              <AnswersLOC
                question={question}
                onChange={_answerChange}
                defaultValue={defaultValue}
                nextButtonRef={nextButtonRef}
              />
            )}
            {question?.criterion.type.shortName === CRITERION_TYPE_DTE && (
              <AnswersDTE
                question={question}
                onChange={_answerChange}
                defaultValue={defaultValue}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

function isSpecific(criteria) {
  const criterion = [CRITERIA_CRIPLAN01];
  return _.includes(criterion, criteria?.shortName);
}

function getType(criteria) {
  return isSpecific(criteria) ? CRITERION_TYPE_SPE : criteria.type?.shortName;
}
