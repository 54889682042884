import React from "react"
import {Link, NavLink} from "react-router-dom"
import {Session} from "fsy.common-library"
import {routes as Routing} from "../../services/RoutesHelper"
import {Avatar, Button, IconButton} from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"

import "./header.css"

export default class Header extends React.Component {
    static defaultProps = {
        isLogged: false,
        user: null
    }

    constructor(props) {
        super(props)
        this.state = {
            loggedIn: this.props.isLogged
        }

        this.disconnect = this.disconnect.bind(this)
    }

    componentDidMount() {
        this.setState({loggedIn: this.props.isLogged})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLogged !== this.props.isLogged) {
            this.setState({loggedIn: this.props.isLogged})
        }
    }

    disconnect() {
        Session.clearSessionAndRedirectToHome()
        this.setState({loggedIn: false})
    }

    render() {
        return <div className="header-menu">
            {/*
            <div className="logo">

                <a href={Routing.app_home} title="Accueil">
                    <img src="/logo192.png" title={Constants.DOCUMENT_TITLE_FRONTOFFICE} alt="Accueil logo"/>
                </a>
            </div>

            <div className="menu">
                <NavLink to={Routing.app_home} title="Accueil"><HomeIcon/> Accueil</NavLink>
                <NavLink to={Routing.simulator} title="Accéder au simulateur"><CalculateIcon/> Simulateur</NavLink>
                <NavLink to={Routing.research}
                         title="Accéder à la recherche d'aides"><ScreenSearchDesktopIcon/> Recherche
                    d'aides</NavLink>
            </div>
            */}
            <div className="user">
                {(this.state.loggedIn && Session.getSessionUser()?.email)
                    ? <>
                        <Avatar sx={{ bgcolor:"var(--footer-background)" }} alt={Session.getSessionUser().email}></Avatar>
                        <div className="flex flex-column align-items-start mx-1">
                            <span>Bonjour {this.props.userName ?? Session.getSessionUser().email} !</span>
                            <NavLink to={Routing.user_account} className="nav-item" title="Accéder à votre compte">
                                <Button variant="text" className="account-button" children="Mon compte"/>
                            </NavLink>
                        </div>
                        <div className="flex flex-column mx-1">
                            <Link to={Routing.app_logout} className="nav-item logout-icon" title="Déconnexion"
                                  reloadDocument>
                                <IconButton aria-label="Logout" children={<LogoutIcon/>}/>
                            </Link>
                        </div>
                    </>
                    : <>
                        <Link to={Routing.app_login} className="nav-item login-icon flex-end" reloadDocument
                              title="Connectez vous à votre compte pour accéder à plus de fonctionnalités">
                            <Button variant="outlined" size="small" children="Connexion"/>
                        </Link>
                    </>
                }
            </div>
        </div>
    }
}
