import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { routes as Routing } from "../../../services/RoutesHelper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  DEPARTEMENTAL_LOCATION_ID_VALUE,
  NATIONAL_LOCATION_ID_VALUE,
  PROJECT_ID_VALUE,
} from "../../../services/Constants";

const AidCatalogMenu = ({
  loading,
  aidsCount,
  regions,
  departments,
  currentFilter,
  criterionValues,
  onRequestElementParentChange,
}) => {
  const [state, setState] = useState({
    elements: [],
    openingStatus: {},
    searchFormValue: {},
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFormChange = (event, id) => {
    const { value } = event.target;
    const { searchFormValue } = state;

    searchFormValue[id] = value;

    _setState({ searchFormValue });
  };

  const _handleElementParentChange = (element, isFirstLevel = true) => {
    if (isFirstLevel) {
      const { openingStatus } = state;

      if (!openingStatus[element.id]) {
        Object.keys(openingStatus).map((id) => {
          if (id !== element.id) {
            openingStatus[id] = false;
          }

          return null;
        });
      }

      openingStatus[element.id] = !Boolean(openingStatus[element.id]);

      _setState({ openingStatus });
    }

    onRequestElementParentChange(element);
  };

  useEffect(() => {
    const filterElements = [
      {
        id: "location",
        title: "Ma localisation",
        elements: [
          {
            id: NATIONAL_LOCATION_ID_VALUE,
            title: "National",
          },
        ],
      },
    ];

    /*if(regions.length > 0){
            const regionElements = regions.map((region) => {
                return {
                    ...region,
                    realId: region.id,
                    id: `${REGIONAL_LOCATION_ID_VALUE}${region.id}`,
                    title: region.label
                }
            })

            filterElements[0].elements.push({
                id: REGIONAL_LOCATION_ID_VALUE,
                title: "Régional",
                elements: regionElements
            })
        }*/

    if (departments.length > 0) {
      const departmentElements = departments.map((department) => {
        return {
          ...department,
          realId: department.id,
          id: `${DEPARTEMENTAL_LOCATION_ID_VALUE}${department.id}`,
          title: `(${department.number}) ${department.label}`,
        };
      });

      filterElements[0].elements.push({
        id: DEPARTEMENTAL_LOCATION_ID_VALUE,
        title: "Départemental",
        elements: departmentElements,
      });
    }

    if (criterionValues.length > 0) {
      const criterionValueElements = criterionValues.map((criterionValue) => {
        return {
          ...criterionValue,
          realId: criterionValue.id,
          id: `${PROJECT_ID_VALUE}${criterionValue.id}`,
          title: criterionValue.value,
        };
      });

      filterElements.push({
        id: PROJECT_ID_VALUE,
        title: "Mon projet",
        elements: criterionValueElements,
      });
    }

    _setState({ elements: filterElements });
  }, [regions, departments, criterionValues]);

  return (
    <div className="aid-catalog-menu">
      <NavLink to={Routing.app_home} className="link-to-homepage">
        <Button
          variant="outlined"
          size="small"
          title="Retourner à l'accueil du simulateur"
          startIcon={<ArrowBackIosIcon />}
        >
          Retour à l'accueil
        </Button>
      </NavLink>
      <div className="aids-count-content">
        <div>
          {!loading ? (
            <span>{aidsCount}</span>
          ) : (
            <CircularProgress color="success" size={22} />
          )}
        </div>
        <span>{`Aide${aidsCount > 1 ? "s" : ""}`}</span>
      </div>
      <div className="filter-content">
        {state.elements.map((filterElement, index) => {
          return (
            <div
              key={`FilterElement${index + 1}`}
              className="filter-content-element"
            >
              <h3>{filterElement.title}</h3>
              {filterElement.elements.map((element, i) => {
                let hasActiveElement =
                  element?.elements?.find(
                    (element) => element.id === currentFilter?.id
                  ) ?? false;
                let childrens = element?.elements || null;

                // On fait ici l'opération de recherche plutôt que de mettre en place un autre state si le formulaire contient quelques choses
                if (childrens && state.searchFormValue[element.id]) {
                  const formValue =
                    state.searchFormValue[element.id].toLowerCase();
                  childrens = childrens.filter((element) =>
                    element.title.toLowerCase().includes(formValue)
                  );
                }

                return (
                  <div
                    key={`Element${index + 1}${i + 1}`}
                    className={`filter-parent ${
                      currentFilter?.id === element.id || hasActiveElement
                        ? "active"
                        : ""
                    }`}
                  >
                    <span
                      className="title"
                      onClick={() => _handleElementParentChange(element)}
                    >
                      {element.title}
                    </span>
                    {state.openingStatus[element.id] && childrens && (
                      <div>
                        <TextField
                          name="surname"
                          type="text"
                          color="success"
                          variant="outlined"
                          size="small"
                          title="Nom"
                          value={state.searchFormValue[element.id] || ""}
                          onChange={(event) =>
                            _handleFormChange(event, element.id)
                          }
                        />
                        <div className="element-list">
                          {childrens?.map((currentElement, ii) => {
                            return (
                              <span
                                key={`Element${index + 1}${i + 1}${ii + 1}`}
                                className={`list-item ${
                                  currentFilter?.id === currentElement.id
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  _handleElementParentChange(
                                    currentElement,
                                    false
                                  )
                                }
                              >
                                {currentElement.title}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="menu-logo">
        <img src="/img/logo-fsy.png" alt="Fransylva logo" />
        <b style={{ marginLeft: "1rem" }}>
          <small>Financé par</small>
        </b>
        <img src="/img/logo-fbf2.png" alt="France Bois Forêt logo" />
      </div>
    </div>
  );
};

export default AidCatalogMenu;
