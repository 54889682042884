import React, { useEffect, useState } from "react";
import AidRow from "./aidRow/AidRow";
import AidFolder from "./aidFolder/AidFolder";
import CloseIcon from "@mui/icons-material/Close";
import { PROJECT_ID_VALUE } from "../../../services/Constants";
import { Loading } from "../../general/form/Loading";
import { Button, Popover } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const FilterElement = ({ filter, onRequestDeleteIconClick }) => {
  return (
    <div
      className={`filter-element ${
        filter.id.includes(PROJECT_ID_VALUE) ? "red" : "green"
      }-filter-element`}
    >
      <span>{filter.title}</span>
      <CloseIcon
        fontSize="small"
        className="close-icon"
        onClick={onRequestDeleteIconClick}
      />
    </div>
  );
};

const AidCatalogContent = ({
  loading,
  aids,
  filters,
  species,
  filtersContentRef,
  additionalFiltersCount,
  onRequestDeleteFilter,
  onRequestReinitFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [aidsHeights, setAidsHeights] = useState([]);
  // const [folderId, setFolderId] = useState(null); // Permettant de savoir quel dossier/sous-dossier est ouvert/fermé => Pour prendre la hauteur
  const isOpened = Boolean(anchorEl);
  const firstFilters =
    additionalFiltersCount > 0
      ? filters.slice(0, -additionalFiltersCount)
      : filters;
  // const additionalFilters = additionalFiltersCount > 0 ? filters.slice(-additionalFiltersCount) : []

  const _handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _handleClose = () => {
    setAnchorEl(null);
  };

  const _handleFolderDisplay = (id) => {
    // setFolderId(id);
  };

  /*const _setAidMaxHeight = () => {
    const aidRows = document.getElementsByClassName("aid-row-root");
    const heights = [];

    Array.from(aidRows).forEach((element) => {
      heights.push(element.offsetHeight);
    });

    const maxHeight = Math.max(...heights);

    setAidHeight(maxHeight);
  };*/

  useEffect(() => {
    if (additionalFiltersCount === 0) {
      _handleClose();
    }
  }, [additionalFiltersCount]);

  /*useEffect(() => {
    _setAidMaxHeight();
  }, [aids?.length]);*/

  return (
    <div className="aid-catalog-content">
      <div className="title-and-filters-content">
        <h2>Aides</h2>
        <div ref={filtersContentRef} className="filters-content">
          {firstFilters.map((filter, index) => {
            return (
              <FilterElement
                key={`Filter${index + 1}`}
                filter={filter}
                onRequestDeleteIconClick={() => onRequestDeleteFilter(filter)}
              />
            );
          })}

          {additionalFiltersCount > 0 && (
            <div
              title="Voir les filtres"
              className="flex additional-filters-count"
            >
              <span onClick={_handleOpen}>{`+${additionalFiltersCount}`}</span>
            </div>
          )}
          {filters?.length > 0 && (
            <div
              title="Réinitialiser les filtres"
              className={`flex reinit-filters-button ${
                additionalFiltersCount > 0 ? "button-with-margin" : ""
              }`}
              onClick={onRequestReinitFilters}
            >
              <RefreshIcon fontSize="small" />
            </div>
          )}

          {additionalFiltersCount > 0 && (
            <Popover
              id="simple-popover"
              anchorEl={anchorEl}
              open={isOpened}
              onClose={_handleClose}
              className="popover-content"
              anchorReference="anchorPosition"
              anchorPosition={{
                top: filtersContentRef.current?.getBoundingClientRect()?.top,
                left: filtersContentRef.current?.getBoundingClientRect()?.left,
              }}
            >
              <div className="additional-filters">
                {filters.map((additionalFilter, index) => {
                  return (
                    <FilterElement
                      key={`AdditionalFilter${index + 1}`}
                      filter={additionalFilter}
                      onRequestDeleteIconClick={() =>
                        onRequestDeleteFilter(additionalFilter)
                      }
                    />
                  );
                })}
              </div>
              <div className="w-100 flex-end">
                <Button
                  variant="contained"
                  className="additional-filters-close-button"
                  onClick={_handleClose}
                >
                  Fermer
                </Button>
              </div>
            </Popover>
          )}
        </div>
      </div>
      {
        <div className="aids-list">
          {loading ? (
            <Loading />
          ) : (
            <>
              {aids?.map((data) => {
                let _handleGetAidHeight = (aidRow) => {
                  let heights = [...aidsHeights];

                  heights.push(aidRow.height);

                  setAidsHeights(heights);
                };

                return data.type === "folder" ? (
                  <AidFolder
                    folder={data}
                    species={species}
                    aidHeight={Math.max(...aidsHeights)}
                    onRequestFolderDisplay={_handleFolderDisplay}
                  />
                ) : (
                  <AidRow
                    isOutOfFolder={true}
                    aid={data}
                    species={species}
                    height={Math.max(...aidsHeights)}
                    onRequestGetAidRow={_handleGetAidHeight}
                  />
                );
              })}
            </>
          )}
        </div>
      }
    </div>
  );
};

export default AidCatalogContent;
