import {useState, useEffect} from "react"
import {LinearProgress, Box, Tooltip} from "@mui/material"

export default function ProgressBar({value = 0, max = 1}) {
    const [progress, setProgress] = useState(value)

    useEffect(() => {
        // Calcul du progress qui doit être compris entre 1 et 100
        setProgress(Math.trunc((value / max) * 100))
    }, [value, max])

    return <Tooltip title={`Progression du simulateur: ${value} / ${max} questions`} placement="bottom">
        <Box sx={{width: "100%"}} className="simulator-progress">
            <LinearProgress variant="determinate" value={progress}/>
        </Box>
    </Tooltip>
}