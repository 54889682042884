import React, { useState, useEffect, useRef } from "react";
import { IconButton, Slider } from "@mui/material";
import { SelectField } from "../../general/form/Select";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import _ from "lodash";
import {
  SPECIE_TYPE_HARDWOOD,
  SPECIE_TYPE_SOFTWOOD,
} from "../../../services/Constants";

export default function Criplan01({
  lineNumber,
  value,
  locked,
  onChange,
  onDelete,
  speciesList,
  rowsValueTotal,
  rowsCount,
}) {
  const [state, setState] = useState({
    specie: value?.specie,
    percent: value?.percent,
    lineNumber: lineNumber,
    locked: locked ?? false,
  });

  const selectFormContentRef = useRef(null);
  const sliderContentRef = useRef(null);

  useEffect(() => {
    if (value?.specie !== state.specie || value?.percent !== state.percent) {
      const specieId =
        typeof value?.specie === "object" ? value?.specie?.id : value?.specie;
      setState({
        specie: _.find(speciesList, (s) => s.id === specieId),
        percent: value?.percent,
        lineNumber: lineNumber,
        locked: locked ?? false,
      });
    }
    // eslint-disable-next-line
  }, [value?.specie, value?.percent, lineNumber]);

  const _formatValueLabel = (value) => `${value} %`;

  const _handleSliderChange = (event, newValue, activeThumb) => {
    const expectedTotal = 100;
    const newTotal = rowsValueTotal - state.percent + newValue;

    if (
      state.specie !== null &&
      state.specie !== undefined &&
      newTotal <= expectedTotal
    ) {
      setState((previousState) => {
        const s = { ...previousState, percent: newValue };
        _handleValueChange(s, "percentage");
        return s;
      });
    }
  };

  const _handleSpecieChange = (specie) => {
    setState((previousState) => {
      const s = { ...previousState, specie: specie };
      _handleValueChange(s, "specie", [null, undefined].includes(state.specie));
      return s;
    });
  };

  const _handleValueChange = (answers, type, isFirstSpecieChange = false) => {
    onChange(answers, type, isFirstSpecieChange);
  };
  const _deleteLine = () => {
    onDelete(state.lineNumber);
  };

  return (
    <div className="specie-row">
      <div ref={selectFormContentRef} className="select-form-content">
        {!locked ? (
          <IconButton
            aria-label="remove"
            size="large"
            className="specie-remove"
            title="Supprimer la ligne"
            onClick={_deleteLine}
          >
            <DisabledByDefaultIcon />
          </IconButton>
        ) : (
          <div className="empty-button-icon-with-specie" />
        )}
        <SelectField
          className="specie-selector"
          options={[
            {
              label: "Feuillus",
              options:
                speciesList?.filter(
                  (element) =>
                    element.speciesGroup.shortName === SPECIE_TYPE_HARDWOOD
                ) || [],
            },
            {
              label: "Résineux",
              options:
                speciesList?.filter(
                  (element) =>
                    element.speciesGroup.shortName === SPECIE_TYPE_SOFTWOOD
                ) || [],
            },
          ]}
          value={state.specie}
          isMulti={false}
          closeMenuOnSelect={true}
          isClearable={false}
          placeholder=""
          onChange={_handleSpecieChange}
        />
      </div>
      <span
        className="percent-value"
        style={{
          width: `calc(100% - ${
            selectFormContentRef.current?.offsetWidth +
            sliderContentRef.current?.offsetWidth
          }px)`,
        }}
      >
        {state.percent} %
      </span>
      <div ref={sliderContentRef} className="slider-content">
        <Slider
          className="percent-selector"
          value={typeof state.percent === "number" ? state.percent : 0}
          onChange={_handleSliderChange}
          getAriaValueText={_formatValueLabel}
          valueLabelFormat={_formatValueLabel}
          aria-labelledby="input-slider"
          valueLabelDisplay="auto"
          min={0}
          max={100}
          step={1}
        />
      </div>
    </div>
  );
}
