import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const SimulatorError = ({
  title,
  secondTitle,
  description,
  redirectingData,
}) => {
  return (
    <div className="simulator-error flex flex-column">
      <span>{title}</span>
      <span>{secondTitle}</span>
      <span>{description}</span>
      {redirectingData && (
        <NavLink to={redirectingData.route}>
          <Button variant="outlined" size="small" title={redirectingData.title}>
            {redirectingData.text}
          </Button>
        </NavLink>
      )}
    </div>
  );
};

export default SimulatorError;
