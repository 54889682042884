import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { routes as Routing } from "../../services/RoutesHelper";
import Helper from "../../services/Helper";
import {
  SIMULATION_STATUS_COMPLETED,
  SIMULATION_STATUS_IN_PROGRESS,
  SIMULATION_STATUS_REGISTERED,
} from "fsy.common-library/lib/env/Constants";
import { Api } from "fsy.common-library";
import { ERR_MSG_SIMULATION_LOADING } from "../../services/Constants";
import { toast } from "react-toastify";
import HomeCheckBox from "./HomeCheckBox";
import "./home.css";
import ButtonToAidsCatalogs from "./ButtonToAidsCatalogs";

export default function HomePage(props) {
  const runningSimulation = Helper.getRunningSimulation();
  const finishedSimulation = Helper.getFinishedSimulation();
  let [simulatorOK, setSimulatorOK] = useState(false);
  let [hasInprogressSimulation, setInprogressSimulation] = useState(false);
  let [hasCompletedSimulation, setCompletedSimulation] = useState(false);
  let [isChecked, setIsChecked] = useState(false);
  const withActiveButtons = simulatorOK && isChecked; /*&& props.withCookie*/
  const tooltipText =
    "Pour accéder à cette fonctionnalité, vous devez accepter les cookies.";

  useEffect(() => {
    props.verifyCookieChoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Api.simulator.getPublishedSimulator().then((r) => {
      const simulator = Helper.isValidResponse(r);
      if (!simulator) {
        setSimulatorOK(false);
        toast.error(ERR_MSG_SIMULATION_LOADING, {
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        setSimulatorOK(true);
        // there is a saved simulation that was made on the same simulator version
        if (
          runningSimulation?.id !== null &&
          simulator?.versionNumber === runningSimulation?.simulator?.version
        ) {
          setInprogressSimulation(
            runningSimulation?.state === SIMULATION_STATUS_IN_PROGRESS
          );
        }
        // there is a finished saved simulation that was made on the same simulator version
        if (
          finishedSimulation?.id !== null &&
          simulator?.versionNumber === finishedSimulation?.simulator?.version
        ) {
          setCompletedSimulation(
            [
              SIMULATION_STATUS_REGISTERED,
              SIMULATION_STATUS_COMPLETED,
            ].includes(finishedSimulation?.state)
          );
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home-content">
      <div className="home-title">
        <h1>
          Un projet de renouvellement forestier ? Découvrez vite les aides dont
          vous pouvez bénéficier
        </h1>
        <h2>
          Ce simulateur vous permet de rechercher les aides auxquelles vous êtes
          éligible pour votre projet de renouvellement forestier (Boisement,
          Reboisement post aléa, adaptation, etc.). <br />
          <div style={{ fontSize: "0.9rem", marginTop: "12px" }}>
            Temps estimé : 10 à 15 minutes
          </div>
        </h2>
      </div>
      <div className="flex center checkbox-content">
        <HomeCheckBox
          type="home"
          isChecked={isChecked}
          onRequestCheck={() => setIsChecked(!isChecked)}
        />
      </div>
      <div className="home flex align-items-end mb-1">
        <div className="mx-2">
          <NavLink to={withActiveButtons ? Routing.simulator_help : ""}>
            <Tooltip
              title={
                isChecked
                  ? "Accéder au simulateur d'aide"
                  : "Pour accéder à cette fonctionnalité, vous devez cocher la case à cocher juste au dessus."
              }
            >
              <div>
                <Button
                  variant="contained"
                  size="large"
                  name="discoverButton"
                  title="Accéder au simulateur d'aide"
                  disabled={!withActiveButtons}
                >
                  Accéder au simulateur
                </Button>
              </div>
            </Tooltip>
          </NavLink>
        </div>
        <div className="flex-sb">
          {props.withCookie &&
            hasInprogressSimulation &&
            props.hasSimulationCookie.runningSimulation && (
              <div className="flex flex-column mx-1">
                {/*<span className="home-helptext">Vous avez une simulation en cours ! Vous pouvez la reprendre en cliquant sur le bouton ci-dessous</span>*/}
                <NavLink
                  to={withActiveButtons ? `${Routing.simulator}?resSim=1` : ""}
                >
                  <Tooltip
                    placement="top"
                    title={
                      !props.withCookie
                        ? tooltipText
                        : "Vous avez une simulation en cours ! Vous pouvez la reprendre en cliquant sur le bouton ci-dessous"
                    }
                  >
                    <div>
                      <Button
                        variant={withActiveButtons ? "outlined" : "contained"}
                        size="large"
                        name="discoverButton"
                        title="Accéder au simulateur d'aide"
                        disabled={!withActiveButtons}
                      >
                        Reprendre ma simulation
                      </Button>
                    </div>
                  </Tooltip>
                </NavLink>
              </div>
            )}
          {props.withCookie &&
            hasCompletedSimulation &&
            props.hasSimulationCookie.resultsSimulation && (
              <div className="flex flex-column mx-1">
                {/*<span
                            className="home-helptext">Vous pouvez revoir les résultats de votre dernière simulation</span>*/}
                <NavLink
                  to={withActiveButtons ? `${Routing.simulator_results}` : ""}
                  reloadDocument
                >
                  <Tooltip
                    placement="top"
                    title={
                      !props.withCookie
                        ? tooltipText
                        : "Vous pouvez revoir les résultats de votre dernière simulation en cliquant sur le bouton ci-dessous"
                    }
                  >
                    <div>
                      <Button
                        variant={withActiveButtons ? "outlined" : "contained"}
                        size="large"
                        name="discoverButton"
                        title="Accéder aux précédents résultats"
                        disabled={!withActiveButtons}
                      >
                        Consulter les précédents résultats
                      </Button>
                    </div>
                  </Tooltip>
                </NavLink>
              </div>
            )}
        </div>
      </div>

      <div className="home-support">
        <i>
          Afin de soutenir la filière forêt-bois, les dispositifs d’aide au
          renouvellement et à la pérennisation des forêts se sont multipliés.{" "}
          <br />
          Pour que vous puissiez trouver les aides qui correspondent à votre
          situation, Fransylva (avec le soutien de France Bois Forêt) vous
          propose ce simulateur destiné à tous les propriétaires. <br />
          Vous pouvez utiliser ce simulateur de manière anonyme, ou bien en vous
          créant un compte utilisateur qui vous permettra d’accéder à
          l’historique de vos différentes simulations.
        </i>
      </div>
      <ButtonToAidsCatalogs className="aids-catalogs-from-homepage" />
    </div>
  );
}
