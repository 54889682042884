import React from 'react'
import './simulatorHelp.css'
import { routes as Routing } from '../../services/RoutesHelper'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

const SimulatorHelp = () => {
    const navigate = useNavigate()

    const handleStartSimulation = () => {
        navigate(Routing.simulator)
    }

    return (
        <div className='simulator-help-root'>
            <div className='button-to-homepage-content'>
                <NavLink to={Routing.app_home}>
                    <Button variant="outlined" size="small" title="Retourner à l'accueil du simulateur"
                        startIcon={<ArrowBackIosIcon />}>
                        Retour à l'accueil
                    </Button>
                </NavLink>
            </div>
            <div className='instructions-content'>
                <p>Cette simulation se divise en <span className='bold'>5 catégories</span> décrivant votre situation et votre projet. </p>
                <p>
                    <div>Certaines <span className='bold'>questions</span> sont <span>obligatoires</span> (indiquées par un <b>astérisque rouge</b>) : vous êtes obligés d’y répondre pour avancer dans la simulation.</div>
                    <div>Les autres sont <span className='bold'>facultatives</span> et vous pouvez <span className='bold'>cliquer sur le bouton “passer”</span> sans y avoir répondu.</div>
                    <div><span>Après avoir répondu ou passé une question, vous pourrez revenir en arrière et corriger ou apporter une réponse.</span></div>
                </p>
                <p>Plus vous répondez aux questions, plus les résultats de la simulation seront fins et adaptés à votre situation spécifique.</p>
                <p>La simulation couvre actuellement des <span className='bold'>projets d’un seul tenant</span>. Si votre projet est constitué de plusieurs îlots, nous vous recommandons la création d’un compte utilisateur pour effectuer et conserver des simulations pour chacun des îlots.</p>
                <p>Il est à noter qu’il vous revient de <span className='bold'>consulter les cahiers des charges des aides</span> pour vous assurer que votre projet est bien en conformité avec l’aide que vous souhaitez requérir. Le simulateur a pour objectif de vous renseigner sur les dispositifs existants mais doit être complété par vos propres vérifications.</p>
                <Button
                    variant="contained"
                    size="large"
                    title="Commencer ma simulation"
                    onClick={handleStartSimulation}
                >Commencer ma simulation</Button>
            </div>
        </div>
    )
}

export default SimulatorHelp