import { Button } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { routes as Routing } from '../../services/RoutesHelper'
import './buttonToAidsCatalogs.css'

const ButtonToAidsCatalogs = ({className}) => {
    return (
        <div className={`text-to-aids-catalogs ${className ?? ''}`}>
            <span>Pour découvrir toutes les aides référencées en vue catalogue</span>
            <NavLink to={Routing.aids_catalogs}>
                <Button variant="contained">Cliquez ici</Button>
            </NavLink>
        </div>
    )
}

export default ButtonToAidsCatalogs