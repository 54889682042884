import React, {useEffect, useState} from "react"
import {ToggleButton, ToggleButtonGroup} from "@mui/material"
import _ from "lodash"
import QuestionHelper from "fsy.common-library/lib/helpers/QuestionHelper";

export default function AnswersOBG({question, onChange, defaultValue = null}) {
    const [state, setState] = useState({
        question: question,
        shortName: question.criterion.shortName,
        answers: {0: {id: 0, checked: false, text: "Oui"}, 1: {id: 1, checked: false, text: "Non"}},
        selectedAnswer: null
    })

    const _setState = values => {
        setState(previousState => {
            return {...previousState, ...values}
        })
    }

    useEffect(() => {
        let answers = []
        _.each(question.criterion.criterionValues, v => {
            if (v.active) {
                answers.push({id: v.id, checked: defaultValue?.answer === v.id, text: v.value})
            }
        })

        _setState({answers: answers, selectedAnswer: defaultValue?.answer})
    }, [question, defaultValue?.answer])

    const _handleAnswerChange = (event, selectedAnswer) => {
        _setState({selectedAnswer: selectedAnswer})
        onChange(selectedAnswer)
    }

    /* =================================== Render  =================================== */

    return <ToggleButtonGroup orientation="horizontal" value={state.selectedAnswer} exclusive
                              onChange={_handleAnswerChange} aria-label="answers" className="simulator-OBGanswers">
        <ToggleButton key={state.answers[0].id} value="yes" children={QuestionHelper.getObgText(question, "yes")}
                      size="small"/>
        <ToggleButton key={state.answers[1].id} value="no" children={QuestionHelper.getObgText(question, "no")}
                      size="small"/>
    </ToggleButtonGroup>
}