import React from "react";
import moment from "moment";
import _ from "lodash";
import GenericHelper from "fsy.common-library/lib/helpers/GenericHelper";
import {
  AID_FUNDING_ESTIM_BILL,
  AID_FUNDING_SCALE,
} from "fsy.common-library/lib/env/Constants";

class AidDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
    };

    this.getRows = this.getRows.bind(this);
  }

  createLine(label, value) {
    return {
      label,
      value,
    };
  }

  getAidAmount(minimum, maximum) {
    if (minimum !== null && maximum !== null) {
      return { min: minimum, max: maximum };
    }

    return null;
  }

  async componentDidMount() {
    let aid = this.props.aidDetail;
    let amoutValue = this.props.aidDetail.amount?.value;
    let unit = this.props.aidDetail.amount?.unit;

    let amount = "";

    // Cas depuis les catalogues d'aides
    if (!this.props.aidDetail.amount) {
      aid = this.props.aidDetail;
      const {
        minimumRate,
        maximumRate,
        minimumAmountPerPlant,
        maximumAmountPerPlant,
        minimumAmountPerHectare,
        maximumAmountPerHectare,
      } = aid;

      const amountRate = this.getAidAmount(minimumRate, maximumRate);
      const amountPerPlant = this.getAidAmount(
        minimumAmountPerPlant,
        maximumAmountPerPlant
      );
      const amountPerHectare = this.getAidAmount(
        minimumAmountPerHectare,
        maximumAmountPerHectare
      );

      if (amountRate !== null) {
        amoutValue = amountRate;
        unit = "%";
      }

      if (amountPerPlant !== null) {
        amoutValue = amountPerPlant;
        unit = "€/Plant";
      }

      if (amountPerHectare !== null) {
        amoutValue = amountPerHectare;
        unit = "€/Ha";
      }
    }

    if (_.isObject(amoutValue)) {
      const minValue = `${GenericHelper.digitWithDecimalPrecision(
        amoutValue.min
      )} ${unit ?? "€"}`;
      const maxValue = `${GenericHelper.digitWithDecimalPrecision(
        amoutValue.max
      )} ${unit ?? "€"}`;

      if (amoutValue.min === 0 && amoutValue.max > 0) {
        amount = `Jusqu'à ${maxValue}`;
      } else {
        if (aid.funding.shortName === AID_FUNDING_SCALE && unit === "%") {
          amount = `${minValue} à ${maxValue} ${
            amoutValue.hasOwnProperty("baseAmount")
              ? `de ${GenericHelper.digitWithDecimalPrecision(
                  amoutValue.baseAmount.amount
                )} ${amoutValue.baseAmount.unit}`
              : "du montant de base"
          }`;
        } else if (
          aid.funding.shortName === AID_FUNDING_ESTIM_BILL &&
          unit === "%"
        ) {
          amount = `${minValue} à ${maxValue} du devis/facture`;
        } else {
          amount = `${minValue} à ${maxValue}`;
        }
      }
    } else {
      amount =
        amoutValue > 0
          ? `${GenericHelper.digitWithDecimalPrecision(amoutValue)} ${
              unit ?? "€"
            }`
          : "Non calculable";
    }

    this.setState({
      rows: [
        this.createLine("Libellé", aid.label || "-"),
        this.createLine("Dispositif", aid.scheme ? aid.scheme.name : "-"),
        this.createLine(
          "Nom de l'organisme porteur",
          aid.organization ? aid.organization.name : "-"
        ),
        this.createLine("Description", aid.description || "-"),
        this.createLine(
          "Date maximale de dépôt",
          aid.depositDate ? moment(aid.depositDate).format("DD/MM/YYYY") : "-"
        ),
        this.createLine("Forme de l'aide", aid.form ? aid.form.name : "-"),
        this.createLine(
          "Mode de financement",
          aid.funding ? aid.funding.label : "-"
        ),
        this.createLine(
          "URL d'origine",
          aid.originUrl ? (
            <a target="_blank" rel="noreferrer" href={aid.originUrl}>
              {aid.originUrl}
            </a>
          ) : (
            "-"
          )
        ),
        this.createLine(
          "URL du document descriptif",
          aid.documentUrl ? (
            <a target="_blank" rel="noreferrer" href={aid.documentUrl}>
              {aid.documentUrl}
            </a>
          ) : (
            "-"
          )
        ),
        this.createLine(
          "URL pour demander l'aide",
          aid.requestUrl ? (
            <a target="_blank" rel="noreferrer" href={aid.requestUrl}>
              {aid.requestUrl}
            </a>
          ) : (
            "-"
          )
        ),
        this.createLine("Nom du contact", aid.contactName || "-"),
        this.createLine("Adresse mail du contact", aid.contactEmail || "-"),
        this.createLine(
          "Type de projets",
          this.getValueByShortName(aid.aidCriterions, ["general_02"]) || "-"
        ),
        this.createLine(
          "Surfaces min et max",
          this.getValueByShortName(aid.aidCriterions, ["general_01"]) || "-"
        ),
        this.createLine(
          "Opérations sylvicoles",
          this.getValueByShortName(aid.aidCriterions, ["descrip_15"]) || "-"
        ),
        this.createLine(
          "Essences exclues/éligibles",
          this.getValueByShortName(aid.aidCriterions, [
            "criPlan_04",
            "criPlan_05",
          ]) || "-"
        ),
        this.createLine(
          "Remarques complémentaires",
          aid.additionalRemark || "-"
        ),
        this.createLine("Remarques détaillées", aid.detailedRemark || "-"),
        this.createLine("Durée du contrat", aid.contractDuration ?? "-"),
        this.createLine(
          "Cumul max d'aides publiques",
          this.getValueByShortName(aid.aidCriterions, ["cumul_01"]) || "-"
        ),
        this.createLine(
          "Cumul max d'aides privées",
          this.getValueByShortName(aid.aidCriterions, ["cumul_02"]) || "-"
        ),
        this.createLine("Délai d'obtention en mois", aid.leadtime ?? "-"),
        this.createLine(
          "Document de gestion durable",
          this.getValueByShortName(aid.aidCriterions, ["general_03"]) || "-"
        ),
        this.createLine(
          "% de crédit d'impôt",
          GenericHelper.digitWithDecimalPrecision(aid.taxCreditPercent) ?? "-"
        ),
        this.createLine(
          "Regroupement",
          this.getValueByShortName(aid.aidCriterions, ["general_12"]) || "-"
        ),
        this.createLine("Fourchettes d'aides", amount),
      ],
    });
  }

  getValueByShortName(aidCriterions, shortName) {
    let result = "-";

    aidCriterions.forEach((ac) => {
      if (shortName.includes(ac.criterion.shortName)) {
        switch (ac.criterion.type.shortName) {
          case "NUM":
            result = `Minimum: ${GenericHelper.digitWithDecimalPrecision(
              ac.value.min
            )} ${
              ac.criterion.unit
            } - Maximum: ${GenericHelper.digitWithDecimalPrecision(
              ac.value.max
            )} ${ac.criterion.unit}`;
            break;

          case "OBG":
            ac.criterion.criterionValues.forEach((cv) => {
              if (ac.value.answer === cv.id) {
                result = cv.value;
              }
            });
            break;

          default:
            const values = [];

            if (["criPlan_04", "criPlan_05"].includes(ac.criterion.shortName)) {
              this.props.species.forEach((specie) => {
                if (ac.value.answers?.includes(specie.id)) {
                  values.push(specie.label);
                }
              });
            } else {
              ac.criterion.criterionValues.forEach((cv) => {
                if (
                  ac.value.answers?.includes(cv.id) ||
                  ac.value.answers?.find((element) => element.id === cv.id)
                ) {
                  values.push(cv.value);
                }
              });
            }

            if (values.length) {
              result = values.join(", ");
            }
            break;
        }
      }
    });

    return result;
  }

  getRows() {
    return this.state.rows.map((item, index) => {
      let isEvenIndexRow = index % 2 === 0;

      return (
        <div
          key={`AidDetail${index + 1}`}
          className={`aid-detail-element ${
            isEvenIndexRow ? "odd-row" : "even-row"
          }`}
        >
          <span>{`${item.label} : `}</span>
          <span>{item.value}</span>
        </div>
      );
    });
  }

  render() {
    return <div>{this.getRows()}</div>;
  }
}

export default AidDetail;
