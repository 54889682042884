import React, {useState, useEffect} from "react"
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/fr"
import dayjs from "dayjs"

export default function AnswersDTE({onChange, defaultValue = null}) {
    const [state, setState] = useState({
        answer: null,
        hasChanged: false
    })

    const _setState = values => {
        setState(previousState => {
            return {...previousState, ...values}
        })
    }

    useEffect(() => {
        if (defaultValue !== null) {
            _setState({answer: dayjs(defaultValue.answer)})
        }
    }, [defaultValue])

    const _handleValueChange = v => {
        _setState({answer: v, hasChanged: true})
        onChange(v.format("YYYY-MM-DD"))
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker onChange={_handleValueChange}
                    value={state.answer}
                    defaultValue={defaultValue != null ? dayjs(defaultValue.answer) : null}/>
    </LocalizationProvider>

}
