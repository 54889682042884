import {toast} from "react-toastify"
import {Session} from "fsy.common-library"
import {HTTP_CREATED, HTTP_OK, SIMULATION_STATUS_IN_PROGRESS} from "fsy.common-library/lib/env/Constants"
import {COOKIE_RESULTS_SIMULATION, COOKIE_RUNNING_SIMULATION} from "./Constants"
import _ from "lodash"

// Use to add a 'capitalize' method to a string object, but :
// String prototype is read only, properties should not be added  no-extend-native
// Object.defineProperty(String.prototype, "capitalize", {
//     value: function () {
//         return this.charAt(0).toUpperCase() + this.slice(1)
//     },
//     enumerable: false
// })

/**
 * @param val {number}
 * @param min {number}
 * @param max {number}
 *
 * @return {boolean}
 */
function isBetween(val, min, max) {
    return min <= val && val <= max
}

/**
 *
 * @param length {number}
 * @return {string}
 */
function generateUUID(length = 5) {
    const uuid = crypto.randomUUID()
    return uuid.slice(uuid.length - 5)
}

/**
 *
 * @param responseObject
 * @return {boolean|Object}
 */
function isValidResponse(responseObject) {
    let response = false
    if (responseObject.hasOwnProperty("data") && _.includes(["object", "string"], typeof responseObject.data)) {
        response = responseObject.data
        if (responseObject.data?.hasOwnProperty("@type") && responseObject.data?.["@type"] === "hydra:Collection") {
            response = responseObject.data["hydra:member"]
        }
    }
    return response
}


function displayApiToastResult(response, message = "OK") {
    if (response?.status !== HTTP_OK && response?.status !== HTTP_CREATED) {
        message = "Oops ! Une erreur est survenue. Merci de rééssayer ultérieurement et veuillez d'avertir l'administrateur si le problème persiste"
        if (response["@type"] === "ConstraintViolationList") {
            message = response.violations[0].message
        }
        toast.error(message)
    } else {
        toast.success(message)
    }
}

function displayGenericErrorToast() {
    toast.error("Oops ! Une erreur est survenue pendant le chargement des informations." +
        "Réessayez et veuillez avertir l'administrateur si le problème persiste")
}

function displayErrorMessage(message, type = "error") {
    if (type === "error") {
        toast.error(message, {autoClose: 4000, position: toast.POSITION.TOP_CENTER})
    } else if (type === "warning") {
        toast.warning(message, {autoClose: 4000, position: toast.POSITION.TOP_CENTER})
    }
}

function getRunningSimulation() {
    return Session.getCookie(COOKIE_RUNNING_SIMULATION)
}

function getFinishedSimulation() {
    return Session.getCookie(COOKIE_RESULTS_SIMULATION)
}

function getResultsSimulationId() {
    const sim = Session.getCookie(COOKIE_RESULTS_SIMULATION)
    if (sim?.state === SIMULATION_STATUS_IN_PROGRESS) {
        return null
    }
    return sim?.id
}

function saveRunningSimulation(simulationId, simulator = null, date = null, nbQuestion = 0, currentStep = null, currentQuestion = null, state = SIMULATION_STATUS_IN_PROGRESS) {
    Session.setCookie(COOKIE_RUNNING_SIMULATION, {
        id: simulationId,
        simulator: {id: simulator?.id, version: simulator?.versionNumber},
        state: state,
        step: currentStep,
        question: currentQuestion,
        date: date,
        nbQuestion: nbQuestion
    })
}

function updateRunningSimulation(key, value) {
    let runSim = Session.getCookie(COOKIE_RUNNING_SIMULATION)
    runSim[key] = value
    Session.setCookie(COOKIE_RUNNING_SIMULATION, runSim)
}

const exportedFunctions = {
    generateUUID,
    isBetween,
    isValidResponse,
    displayApiToastResult,
    displayGenericErrorToast,
    displayErrorMessage,
    getRunningSimulation,
    getFinishedSimulation,
    getResultsSimulationId,
    saveRunningSimulation,
    updateRunningSimulation
}

export default exportedFunctions