import React from "react";
import { NavLink } from "react-router-dom";

import { Api, Constants, Session } from "fsy.common-library";
import {
  HTTP_ERROR_SEND_MAIL,
  HTTP_OK,
  HTTP_USER_ALREADY_EXIST,
} from "fsy.common-library/lib/env/Constants";
import { LoadingOverlay, Loading } from "../general/form/Loading";
import { TextField, Button, Alert } from "@mui/material";
import { routes as Routing } from "./../../services/RoutesHelper";

import "./login.css";
import Helper from "../../services/Helper";
import { STORAGE_ORG_UUID } from "../../services/Constants";
import { ArrowBackIos } from "@mui/icons-material";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      withCookie: false,
      isSubmited: false,
      isRequestDone: false,
      surname: "",
      name: "",
      identifier: "",
      password: "",
      passwordConfirm: "",
      pageType: props.pageType,
      error: {
        status: false,
        message: "",
      },
      notification: {
        status: false,
        message: "",
      },
      successRegistration: false,
      registrationEnabled: false,
      loadingType: "full",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgottenPassword = this.handleForgottenPassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordConfirmChange =
      this.handlePasswordConfirmChange.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.register = this.register.bind(this);
    this.displayError = this.displayError.bind(this);
    this.handleNameOrSurnameChange = this.handleNameOrSurnameChange.bind(this);
    this.formsStatus = this.formsStatus.bind(this);
  }

  componentDidMount() {
    const uuid = Session.getLocalStorage(STORAGE_ORG_UUID);
    Api.parameter
      .getParameterByKey(
        "front_account_creation",
        uuid === null || uuid === "" ? 1 : uuid
      )
      .then((result) => {
        const parameters = Helper.isValidResponse(result);
        if (parameters) {
          this.setState({
            registrationEnabled: parameters?.propValue === "true",
          });
        }
      });

    if (typeof this.props.verifyCookieChoice === "function") {
      this.props.verifyCookieChoice();
    }
  }

  async authenticate() {
    const response = await Api.user.authenticateUser(
      this.state.identifier,
      this.state.password
    );

    if (response.status === Constants.HTTP_OK) {
      const data = response.data;
      const user = {
        id: data.id,
        email: data.email,
        pseudo: data.pseudo,
        name: data.name,
        surname: data.surname,
      };
      Session.handleLogin({
        user: user,
        jwtToken: data.token,
        refreshToken: data.refreshToken,
      });
      this.setState({
        isRequestDone: true,
        isSubmited: false,
      });

      if (this.props.redirectCallBack) {
        this.props.redirectCallBack(user);
        return;
      }

      window.location.href = Routing.app_home; // Redirect to home page
    } else {
      let message;
      if (response.code === Constants.HTTP_USER_NOT_FOUND) {
        message = "Identifiant inconnu";
      } else if (response.code === Constants.HTTP_INVALID_PASSWORD) {
        message = "Mot de passe incorrect";
      } else if (response.code === Constants.HTTP_INVALID_TOKEN) {
        message =
          "Ce compte est désactivé. Réinitialisez votre mot de passe ou contactez votre administrateur";
      } else if (response.code === Constants.HTTP_TOKEN_EXPIRED) {
        message =
          "Ce compte a été banni. Contactez votre administrateur pour plus de details";
      } else {
        message = response.message;
      }
      this.setState({
        isRequestDone: false,
        isSubmited: false,
        error: {
          status: true,
          message: message,
        },
        notification: {
          status: false,
        },
      });
    }
  }

  async register() {
    this.setState({
      isSubmited: false,
    });

    if (this.state.password.length < 8) {
      this.displayError("Le mot de passe doit contenir au moins 8 caractères");
      return;
    }

    if (!new RegExp(/[a-z]/).test(this.state.password)) {
      this.displayError("Le mot de passe doit contenir au moins 1 minuscule");
      return;
    }

    if (!new RegExp(/[A-Z]/).test(this.state.password)) {
      this.displayError("Le mot de passe doit contenir au moins 1 majuscule");
      return;
    }

    if (!new RegExp(/[0-9]/).test(this.state.password)) {
      this.displayError("Le mot de passe doit contenir au moins 1 chiffre");
      return;
    }

    if (!new RegExp(/[-_#@$%*!?&]/).test(this.state.password)) {
      this.displayError(
        "Le mot de passe doit contenir au moins 1 caractère spécial (-_#@$%*!?&)"
      );
      return;
    }

    if (
      !(
        this.state.password === this.state.passwordConfirm &&
        this.state.password !== "" &&
        this.state.passwordConfirm !== ""
      )
    ) {
      this.displayError("Les deux mots de passe doivent être identiques");
      return;
    }

    this.setState({
      isSubmited: true,
    });

    Api.user
      .createSimulatorUser(
        this.state.identifier,
        this.state.password,
        this.props.simulation,
        this.state.surname,
        this.state.name
      )
      .then((response) => {
        if (response.status === HTTP_OK) {
          if (!response.data.error) {
            this.setState(
              {
                successRegistration: true,
                error: {
                  status: false,
                },
              },
              () => {
                if (this.props.redirectCallBack) {
                  this.props.redirectCallBack(response.data);
                }
              }
            );
          } else {
            this.displayError(response.data.message);
          }
        } else {
          let message = response["hydra:description"];
          if (response.code && response.code === HTTP_ERROR_SEND_MAIL) {
            message = "Échec de l'envoi de l'email";
          }

          if (response?.code === HTTP_USER_ALREADY_EXIST) {
            message = "Un utilisateur avec cet email existe déjà";
          }

          this.displayError(message);
        }
      })
      .finally(() => {
        this.setState({
          isSubmited: false,
        });
      });
  }

  formsStatus() {
    if (this.state.pageType === "register") {
      return (
        this.state.surname &&
        this.state.identifier &&
        this.state.password &&
        this.state.passwordConfirm
      );
    } else {
      return this.state.identifier && this.state.password;
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const formsCompleted = this.formsStatus();

    if (formsCompleted) {
      this.setState({ isSubmited: true });

      if (this.state.pageType === "login") {
        await this.authenticate();
      } else {
        await this.register();
      }
    } else {
      this.setState({
        error: {
          status: true,
          message:
            this.state.pageType === "register"
              ? "Nom, Adresse email, mot de passe et confirmation requis"
              : "Identifiant et mot de passe requis",
        },
        notification: {
          status: false,
        },
      });
    }

    /*if (this.state.identifier === "" || this.state.password === "") {
      this.setState({
        error: {
          status: true,
          message: "Identifiant et mot de passe requis",
        },
        notification: {
          status: false,
        },
      });
    } else {
      this.setState({ isSubmited: true });

      if (this.state.pageType === "login") {
        await this.authenticate();
      } else {
        await this.register();
      }
    }*/
  }

  async handleForgottenPassword(e) {
    e.preventDefault();
    if (this.state.identifier === "") {
      this.setState({
        error: {
          status: true,
          message: "Identifiant requis",
        },
        notification: {
          status: false,
        },
      });
    } else {
      this.setState({ isSubmited: true });

      const response = await Api.user.checkUserIdentifier(
        this.state.identifier
      );

      if (response.status === HTTP_OK) {
        const data = response.data;

        const forgotResponse = await Api.user.userPasswordForgotten(data.id);

        if (forgotResponse.status === HTTP_OK) {
          this.setState({
            isRequestDone: true,
            isSubmited: false,
            notification: {
              status: true,
              message: "Email de réinitialisation de mot de passe envoyé",
            },
            error: {
              status: false,
            },
          });
        } else {
          this.setState({
            isRequestDone: false,
            isSubmited: false,
            error: {
              status: true,
              message:
                "Une erreur est survenue, merci de rééssayer ou de contacter un administrateur",
            },
            notification: {
              status: false,
            },
          });
        }
      } else {
        let message = "";
        if (response.status === Constants.HTTP_USER_NOT_FOUND) {
          message = "Identifiant inconnu";
          this.setState({
            isRequestDone: false,
            isSubmited: false,
            error: {
              status: true,
              message: message,
            },
            notification: {
              status: false,
            },
          });
        } else {
          this.displayError(
            "Une erreur est survenue, merci de rééssayer ou de contacter un administrateur"
          );
        }
      }
    }
  }

  handleIdentifierChange(e) {
    this.setState({
      identifier: e.target.value,
      error: { status: false },
      notification: { status: false },
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
      error: { status: false },
      notification: { status: false },
    });
  }

  handlePasswordConfirmChange(e) {
    this.setState({
      passwordConfirm: e.target.value,
    });
  }

  handleNameOrSurnameChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  displayError(message) {
    this.setState({
      isRequestDone: false,
      isSubmited: false,
      error: {
        status: true,
        message: message,
      },
      notification: {
        status: false,
      },
    });
  }

  render() {
    return (
      <>
        {this.state.isSubmited && !this.state.isRequestDone ? (
          this.props.loadingType === "full" ? (
            <LoadingOverlay />
          ) : (
            <Loading />
          )
        ) : (
          <form
            onSubmit={this.handleSubmit}
            className={`login-form ${
              this.props.isCookieContentOpened &&
              this.state.pageType === "register"
                ? "login-form-with-cookie-demand"
                : ""
            }`}
          >
            {this.props.showSimulationLink && (
              <NavLink
                to="/"
                className="nav-item login-back flex-start back-to-simulator-link"
                title="Retour au simulateur"
                reloadDocument
              >
                <ArrowBackIos />
                <span>Retour au simulateur</span>
              </NavLink>
            )}

            <div
              className="text-primary"
              style={{
                textAlign: "center",
                fontSize: this.state.successRegistration ? "1rem" : "1.75rem",
                // marginBottom: "2rem",
                color: this.state.successRegistration ? "#0B3D21" : "#007a31",
              }}
            >
              {this.state.pageType !== "register" && (
                <span>Connectez-vous</span>
              )}

              {this.state.pageType === "register" &&
                !this.state.successRegistration && (
                  <span>Créer votre compte</span>
                )}

              {this.state.successRegistration && (
                <span>Votre compte a été créé</span>
              )}
            </div>

            {this.state.error.status && (
              <Alert style={{ marginBottom: "7px" }} severity={"error"}>
                {this.state.error.message}
              </Alert>
            )}
            {this.state.notification.status && (
              <p className="login-notification">
                {this.state.notification.message}
              </p>
            )}

            {this.state.successRegistration && (
              <Alert style={{ marginBottom: "7px" }} severity={"success"}>
                <div>
                  Un email contenant la procédure d'activation de votre compte
                  vous a été envoyé sur l'adresse suivante :{" "}
                  <span className="bold">{this.state.identifier}.</span>
                </div>
                <div style={{ color: "#AE0F11" }}>
                  Si vous ne trouvez pas cet email, veuillez vérifier dans votre
                  dossier spams ou courriers indésirables.
                </div>
              </Alert>
            )}

            {!this.state.successRegistration && (
              <div style={{ padding: "0 2rem" }}>
                {this.state.pageType === "register" && (
                  <>
                    {/** Nom */}
                    <TextField
                      error={this.state.error.status}
                      name="surname"
                      type="text"
                      fullWidth
                      label="Nom"
                      margin="dense"
                      variant="standard"
                      autoComplete="surname"
                      value={this.state.surname}
                      required
                      onChange={this.handleNameOrSurnameChange}
                    />

                    {/** Prénom(s) */}
                    <TextField
                      error={this.state.error.status}
                      name="name"
                      type="text"
                      fullWidth
                      label="Prénom(s)"
                      margin="dense"
                      variant="standard"
                      autoComplete="name"
                      value={this.state.name}
                      onChange={this.handleNameOrSurnameChange}
                    />
                  </>
                )}

                {/** Adresse email */}
                <TextField
                  error={this.state.error.status}
                  name="identifier"
                  type="email"
                  fullWidth
                  label="Adresse email"
                  margin="dense"
                  variant="standard"
                  autoComplete="username"
                  value={this.state.identifier}
                  required
                  onChange={this.handleIdentifierChange}
                />

                {/** Mot de passe */}
                <TextField
                  error={this.state.error.status}
                  name="password"
                  type="password"
                  fullWidth
                  label="Mot de passe"
                  margin="dense"
                  variant="standard"
                  autoComplete="current-password"
                  value={this.state.password}
                  required
                  onChange={this.handlePasswordChange}
                />

                {/** Confirmation de mot de passe */}
                {this.state.pageType === "register" && (
                  <TextField
                    error={this.state.error.status}
                    name="password_confirm"
                    type="password"
                    fullWidth
                    label="Confirmation de Mot de passe"
                    margin="dense"
                    variant="standard"
                    autoComplete="current-password"
                    value={this.state.passwordConfirm}
                    required
                    onChange={this.handlePasswordConfirmChange}
                  />
                )}

                {this.state.pageType === "login" && (
                  <a
                    href="/login"
                    onClick={this.handleForgottenPassword}
                    className="login-forgotten-password"
                  >
                    Mot de passe oublié ?
                  </a>
                )}

                <div style={{ textAlign: "center", marginTop: "2rem" }}>
                  <div>
                    <Button
                      type="submit"
                      name="loginButton"
                      className="primary-btn"
                      title="Se connecter"
                      variant="contained"
                      size="large"
                    >
                      {this.state.pageType === "register"
                        ? "Créer un compte"
                        : "Connexion"}
                    </Button>
                  </div>
                </div>

                {this.state.pageType === "login" &&
                  this.state.registrationEnabled && (
                    <div>
                      <div
                        style={{
                          marginTop: "2rem",
                          textAlign: "center",
                          fontSize: "1.75rem",
                        }}
                        className="text-primary"
                      >
                        Vous n'avez pas encore créé votre compte ?
                      </div>
                      <div style={{ marginTop: "1rem", textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            this.setState({
                              pageType: "register",
                            });
                          }}
                          style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                        >
                          Créer un compte
                        </Button>
                      </div>
                    </div>
                  )}
              </div>
            )}

            {!this.state.successRegistration &&
              this.state.pageType === "register" && (
                <div>
                  <div
                    style={{ marginTop: "2rem", textAlign: "center" }}
                    className="text-primary"
                  >
                    Vous avez déjà un compte ?
                  </div>
                  <div style={{ marginTop: "1rem", textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        this.setState({
                          pageType: "login",
                        });
                      }}
                      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                      Connexion
                    </Button>
                  </div>
                </div>
              )}
          </form>
        )}
      </>
    );
  }
}
