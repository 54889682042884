import React from "react";
import {Loading} from "../general/form/Loading";
import {Alert} from "@mui/material";
import {CountDown} from "./CountDown";
import {Api, Constants} from "fsy.common-library";

export class ActivateAccountPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            feedBackMessage: {
                success: false,
                message: ''
            }
        }
    }

    componentDidMount() {
        const queryString = window.location.search
        const token = new URLSearchParams(queryString).get("token")
        let message = 'Votre compte a bien été activé'
        Api.user.changeUserStatus(token).then(response => {
            if (response.error) {
                switch (response.code) {
                    case Constants.HTTP_INVALID_TOKEN:
                        message = "L'utilisateur n'existe pas"
                        break
                    case Constants.HTTP_TOKEN_EXPIRED:
                        message = "Le token d'activation a éxpiré"
                        break
                    default:
                        message = "Une erreur est survenue, merci de rééssayer ou de contacter un administrateur"
                        break
                }
            }

            this.setState({
                feedBackMessage: {
                    success: !response.error,
                    message: message
                }
            })
        }).catch(error => {
            this.setState({
                feedBackMessage: {
                    success: false,
                    message: "Une erreur est survenue, merci de rééssayer ou de contacter un administrateur"
                }
            })
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        return <section className="activate">
            {this.state.loading && <Loading/>}

            <div className="activate-title">
                <h1>Activation de compte</h1>
            </div>
            <div className="activate">
                {this.state.loading &&
                    <div className="activate-description">
                        Activation en cours ...
                    </div>
                }

                <div className="activate-card">
                    {!this.state.loading &&
                        <div className="activate-feedback">
                            <Alert severity={this.state.feedBackMessage.success ? 'success' : 'error'}>
                                {this.state.feedBackMessage.message}</Alert>
                            {this.state.feedBackMessage.success &&
                                <CountDown className={"success"}/>
                            }
                        </div>
                    }
                </div>
            </div>
        </section>
    }
}
