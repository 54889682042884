import React, { useEffect, useState } from "react";
import "./aidFolder.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AidRow from "../aidRow/AidRow";

const AidFolder = ({
  folder,
  species,
  aidHeight,
  onRequestFolderDisplay,
  isResult,
}) => {
  const [state, setState] = useState({
    isFolderOpened: false,
    subfolderOpeningStatus: {},
    aidsCount: 0,
    isFolderContainsSubfolders: false,
  });

  const _setState = (newValue) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newValue,
      };
    });
  };

  const _handleFolderIconClick = () => {
    _setState({ isFolderOpened: !state.isFolderOpened });
    onRequestFolderDisplay(folder.id);
  };

  const _handleSubfolderIconClick = (id) => {
    const { subfolderOpeningStatus } = state;

    subfolderOpeningStatus[id] = !Boolean(subfolderOpeningStatus[id]);

    _setState({ subfolderOpeningStatus });
    onRequestFolderDisplay(id);
  };

  useEffect(() => {
    if (folder) {
      let aidsCount = 0;
      let isFolderContainsSubfolders =
        folder.elements?.filter((element) => element.type === "subfolder")
          ?.length > 0 || false;

      folder.elements?.map((element) => {
        aidsCount +=
          element.type === "subfolder" ? element.aids?.length || 0 : 1;

        return null;
      });

      _setState({ aidsCount, isFolderContainsSubfolders });
    }
  }, [folder]);

  let folderIcon = "";

  if (!isResult) {
    folderIcon = state.isFolderOpened ? "opened-folder-icon" : "folder-icon";
  } else {
    folderIcon = state.isFolderOpened ? "folder-icon" : "opened-folder-icon";
  }

  return (
    <div
      className={`aid-folder-root ${
        state.isFolderOpened ? "aid-folder-with-bottom-padding" : ""
      }`}
    >
      {/** Nom du dossier et nombre d'aides contenues dans ledit dossier */}
      <div className="aid-folder-title">
        <KeyboardArrowRightIcon
          className={folderIcon}
          onClick={_handleFolderIconClick}
        />
        <span className="folder-name">{folder.name}</span>
        <span>{`${state.aidsCount !== 0 && state.aidsCount < 10 ? "0" : ""}${
          state.aidsCount
        }`}</span>
      </div>
      {/** Sous-dossier et/ou aides */}
      {(!isResult ? state.isFolderOpened : !state.isFolderOpened) &&
        folder?.elements?.map((element, index) => {
          let subfolderIcon = "";

          if (!isResult) {
            subfolderIcon = state.subfolderOpeningStatus[element.id]
              ? "opened-folder-icon"
              : "folder-icon";
          } else {
            subfolderIcon = state.subfolderOpeningStatus[element.id]
              ? "folder-icon"
              : "opened-folder-icon";
          }

          return element.type === "subfolder" ? (
            <div key={`Subfolder${index + 1}`} className="aid-subfolder-root">
              <div>
                <KeyboardArrowRightIcon
                  className={subfolderIcon}
                  onClick={() => _handleSubfolderIconClick(element.id)}
                />
                <span className="subfolder-name">{element.name}</span>
                <span>{`${
                  element.aids?.length !== 0 && element.aids?.length < 10
                    ? "0"
                    : ""
                }${element.aids?.length}`}</span>
              </div>
              {(!isResult
                ? state.subfolderOpeningStatus[element.id]
                : !state.subfolderOpeningStatus[element.id]) && (
                <div>
                  {element.aids?.map((aid, i) => {
                    return (
                      <div
                        key={`SubfolderAid${index + 1}${i + 1}`}
                        className="aid-with-divider-content"
                      >
                        <AidRow
                          isResult={isResult}
                          isInSubfolder={true}
                          aid={aid}
                          species={species}
                          height={aidHeight}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <div
              key={`AidInFolder${index + 1}`}
              className="aid-with-divider-content"
            >
              <AidRow
                isResult={isResult}
                isInFolder={true} // Permettant de savoir que c'est une aide à l'intérieur d'un dossier UNIQUEMENT, pas les sous-dossiers
                aid={element}
                species={species}
                height={aidHeight}
              />
            </div>
          );
        })}
    </div>
  );
};

export default AidFolder;
