import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { MEDIAQUERY_MOBILE } from "../../services/Constants";

import "./simulator.css";

export default function SimulatorMenu({
  simulatorSteps = null,
  currentStep,
  loading,
  onQuestionClick,
  mandatoryQuestions = [],
  maxStepAvailable,
  maxQuestionAvailable,
  isSandboxMode,
  onRequestAchieveTest,
  currentQuestionIndex,
  questionsCount,
}) {
  const [mobileDisplay, setMobileDisplay] = useState(
    window.matchMedia(MEDIAQUERY_MOBILE).matches
  );
  const [activeStep, setActiveStep] = useState(currentStep);
  const [steps, setSteps] = useState([]);
  const [maxStep, setMaxStep] = useState(currentStep);
  const [maxQuestion, setMaxQuestion] = useState(maxQuestionAvailable);
  const [questionsList, setQuestionsList] = useState(mandatoryQuestions);
  const menuFooterRef = useRef(null);

  useEffect(() => {
    if (simulatorSteps !== null) {
      setSteps(_.orderBy(simulatorSteps, ["position"], "asc"));
    }

    const _handleResize = () => {
      let mql = window.matchMedia(MEDIAQUERY_MOBILE);
      if (mql.matches) {
        // if media query matches
        setMobileDisplay(true);
      } else {
        setMobileDisplay(false);
      }
    };

    window.addEventListener("resize", _handleResize, false);

    return function () {
      window.removeEventListener("resize", _handleResize, false);
    };
  }, [simulatorSteps]);

  useEffect(() => {
    setActiveStep(currentStep - 1);
  }, [currentStep]);

  useEffect(() => {
    setMaxStep(maxStepAvailable);
  }, [maxStepAvailable]);

  useEffect(() => {
    setMaxQuestion(maxQuestionAvailable);
  }, [maxQuestionAvailable]);

  useEffect(() => {
    setQuestionsList(mandatoryQuestions);
  }, [mandatoryQuestions]);

  const _handleStepClick = (stepPosition) => {
    if (stepPosition <= maxStep) {
      setActiveStep(stepPosition - 1);
    }
  };

  return (
    <div className="simulator-menu">
      {isSandboxMode && (
        <div className="finishing-simulation-button-content">
          <Button
            disabled={loading}
            variant="contained"
            children="Terminer le test"
            onClick={onRequestAchieveTest}
          />
        </div>
      )}
      <div className="menu-title">
        <h3>Ma simulation</h3>
        <div className="question-counter-trace">{`Question ${currentQuestionIndex}/${questionsCount}`}</div>
      </div>

      <Stepper
        className="menu-list"
        role="menu"
        activeStep={activeStep}
        orientation={mobileDisplay ? "horizontal" : "vertical"}
        style={{
          maxHeight: `calc(100vh - 10vh - ${menuFooterRef.current?.offsetHeight}px)`,
          background: "red !important",
        }}
      >
        {steps.map((step, key) => (
          <Step
            role="menuitem"
            key={step.position}
            title={`Accéder à la catégorie ${step.name}`}
            className={`menu-item ${
              step.position > maxStep && "step-disabled"
            } ${step.position === activeStep + 1 ? "step-active" : ""}`} //+1 because position 1= index 0
            disabled={step.position > maxStep}
            onClick={() => _handleStepClick(step.position)}
          >
            <StepLabel>{step.name}</StepLabel>
            {!mobileDisplay && ( // content can only work with vertical stepper
              <StepContent className="simulator-menu-content">
                {/*TODO: uncomment to only display mandatory questions*/}
                {/*{_.map(questionsList[key], q => (*/}
                {_.map(questionsList[key]?.questions, (q) => (
                  <div
                    className="simulator-menu-content-item"
                    key={q.id}
                    title={q.questionText}
                  >
                    <span>{q.title}</span>
                    {step.position < maxQuestion?.step ||
                    (step.position === maxQuestion?.step &&
                      q.position <= maxQuestion?.question) ? (
                      <span onClick={() => onQuestionClick(q.id)}>
                        <EditIcon className="question-edit-icon" />{" "}
                        <span className="question-edit-text">Modifier</span>
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                ))}
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>

      <div ref={menuFooterRef} className="menu-logo">
        <img src="/img/logo-fsy.png" alt="Fransylva logo" />
        <b style={{ marginLeft: "1rem" }}>
          <small>Financé par</small>
        </b>
        <img src="/img/logo-fbf2.png" alt="France Bois Forêt logo" />
      </div>
    </div>
  );
}
