import {CircularProgress} from "@mui/material"

import "./loading.css"

export function LoadingOverlay({message= "Chargement"}) {
    return <div className="loading-overlay">
        <div className="overlay-content">
            <CircularProgress color="success"/>
            <div>{message ?? "Chargement"}</div>
        </div>

        <div className="overlay-brand">
            <img src="/img/Nextaura2.png" alt="Nextaura brand logo"/>
        </div>
    </div>
}


export function Loading({message= "Chargement", className}) {
    return <div className={"loading " + className ?? ""}>
        <div className="loading-content">
            <CircularProgress color="success"/>
            <div>{message ?? "Chargement"}</div>
        </div>
    </div>
}