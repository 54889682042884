import React, { useEffect, useState, useRef } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { Api, Constants, Session } from "fsy.common-library";
import { Loading, LoadingOverlay } from "../general/form/Loading";
import { routes as Routing } from "../../services/RoutesHelper";
import Login from "../login/Login";
import Helper from "../../services/Helper";
import {
  COOKIE_RESULTS_SIMULATION_TEMP,
  ERR_MSG_SIMULATION_RESULTS_LOADING,
  ERR_MSG_SIMULATION_RESULTS_PDF,
  MEDIAQUERY_MOBILE,
} from "../../services/Constants";
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Dialog,
  DialogContent,
  Snackbar,
  Alert,
  Tooltip,
  StepContent,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import moment from "moment";
import {
  CRITERION_TYPE_DTE,
  CRITERION_TYPE_LOC,
  CRITERION_TYPE_NUM,
  CRITERION_TYPE_OBG,
  CRITERION_TYPE_TXT,
  SIMULATION_STATUS_IN_PROGRESS,
} from "fsy.common-library/lib/env/Constants";

import "./simulator.css";
import QuestionHelper from "fsy.common-library/lib/helpers/QuestionHelper";
import AidDetailDialog from "../aid/AidDetailDialog";
import SimulatorError from "./simulatorError/SimulatorError";
import AidHelper from "fsy.common-library/lib/helpers/AidHelper";
import AidFolder from "../aid/aidCatalog/aidFolder/AidFolder";
import AidRow from "../aid/aidCatalog/aidRow/AidRow";

export default function SimulatorResults(props) {
  const resultsTitleRef = useRef(null);
  const resultsActionRef = useRef(null);
  const simulationWarningMessageRef = useRef(null);

  const [state, setState] = useState({
    loading: true,
    invalid: false,
    simulation: null,
    openAuthDialog: false,
    openAidDialog: false,
    showMessage: false,
    saveSimulation: false,
    aidDetail: null,
    species: [],
    aidIndex: 0,
    maxAlertThreshold: -1,
    minOptionalQuestionsResponsePercentage: -1,
    pdfLoading: false,
    displayPDF: false,
    pdfUrl: "",
    simulator: null,
    simulatorLive: null,
    simulatorError: false,
    criterionValues: [],
    testLoading: false,
    aidsListHeight: undefined,
  });
  // const user = SessionHelper.getSessionUser()
  let simulationId = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const viewmode = urlParams.get("viewmode");
  const sandboxSimulationId = urlParams.get("simulationId");
  const simulatorUuid = urlParams.get("uuid");
  const mode = urlParams.get("mode");
  const answeredOptionalQuestionsPercentage = +urlParams.get(
    "answeredOptionalQuestionsPercentage"
  );
  const simulationIdWithoutCookieMode = urlParams.get(
    "simulationIdWithoutCookieMode"
  );

  const isSandboxMode = mode && sandboxSimulationId && simulatorUuid;

  const _setState = (values, callback = null) => {
    setState((previousState) => {
      return { ...previousState, ...values };
    });
    if (callback !== null && typeof callback === "function") {
      callback();
    }
  };

  const handleClickSaveResults = () => {
    const user = Session.getUser();

    if (!user) {
      _setState({ openAuthDialog: true });
      return;
    }

    _setState({ saveSimulation: true });

    Api.simulation.setSimulationRegistered(simulationId.current).then(() => {
      _setState({ showMessage: true, saveSimulation: false });
    });
  };

  const handleClose = () => {
    _setState({ openAuthDialog: false });
  };

  const handleCloseMessage = () => {
    _setState({ showMessage: false, pdfHasError: false });
  };

  const handleAidDialogClose = () => {
    _setState({ openAidDialog: false });
  };

  const _downloadPDF = () => {
    _setState({ pdfLoading: true });
    Api.simulation.generatePDF(simulationId.current).then((response) => {
      const pdf = Helper.isValidResponse(response);
      if (pdf && pdf.success) {
        _setState({ pdfLoading: false, displayPDF: true, pdfUrl: pdf.path });
      } else {
        _setState({ pdfLoading: false, displayPDF: false, pdfHasError: true });
      }
    });
  };

  const _handleSetLoading = (status) => {
    _setState({ testLoading: status });
  };

  useEffect(() => {
    if (isSandboxMode) {
      simulationId.current = sandboxSimulationId;
    } else {
      if (props.withCookie) {
        if (viewmode !== null && viewmode === "temp") {
          simulationId.current = JSON.parse(
            Session.getLocalStorage(COOKIE_RESULTS_SIMULATION_TEMP)
          )?.id;
          Session.removeLocalStorage(COOKIE_RESULTS_SIMULATION_TEMP);
          window.history.replaceState({}, "", Routing.simulator_results);
        } else {
          simulationId.current = Helper.getResultsSimulationId();
        }
      } else {
        simulationId.current = simulationIdWithoutCookieMode;
      }

      if (simulationId.current === null || simulationId.current === undefined) {
        _setState({ invalid: true, loading: false });
        return;
      }
    }

    Promise.all([
      Api.simulation.getSimulation(simulationId.current),
      Api.species.getSpecies(),
      Api.criteria.getCriterion(),
      isSandboxMode
        ? Api.simulator.getSimulatorByUuid(simulatorUuid)
        : Api.simulator.getPublishedSimulator(),
    ]).then(([sr, speciesData, cr, sir]) => {
      const simulation = Helper.isValidResponse(sr);
      const species = Helper.isValidResponse(speciesData);
      const criterion = Helper.isValidResponse(cr);
      const currentSimulator = Helper.isValidResponse(sir);

      let simErr = state.simulatorError;
      let sim = state.simulator;
      let currSim = state.simulatorLive;
      let param = state.maxAlertThreshold;
      let minOptionalPercentage = state.minOptionalQuestionsResponsePercentage;
      let spec = state.species;
      let crit = [];

      if (currentSimulator && !_.isEqual(currentSimulator, {})) {
        currSim = currentSimulator;
      }

      if (species !== false) {
        spec = species;
      }

      if (criterion !== false) {
        _.each(criterion, (c) => {
          crit = _.concat(crit, c.criterionValues);
        });
      }

      if (!simulation || !currSim) {
        Helper.displayErrorMessage(ERR_MSG_SIMULATION_RESULTS_LOADING);
        _setState({ simulation: null, loading: false, invalid: true });
      } else {
        const uuid =
          simulation.uuid === null || simulation.uuid === ""
            ? 1
            : simulation.uuid;

        Promise.all([
          Api.parameter.getParameterByKey("max_alert_threshold", uuid),
          Api.parameter.getParameterByKey(
            Constants.MIN_OPTIONAL_QUESTIONS_RESPONSE_PERCENTAGE,
            uuid
          ),
        ])
          .then(
            ([maxAlertThresholdResponse, minOptionalPercentageResponse]) => {
              const parameter1 = Helper.isValidResponse(
                maxAlertThresholdResponse
              );
              const parameter2 = Helper.isValidResponse(
                minOptionalPercentageResponse
              );

              if (parameter1) {
                param = +parameter1["propValue"];
              }

              if (parameter2) {
                minOptionalPercentage = +parameter2["propValue"];
              }

              _setState({
                maxAlertThreshold: param,
                minOptionalQuestionsResponsePercentage: minOptionalPercentage,
                simulation: simulation,
                simulatorError: simErr,
                simulator: sim,
                simulatorLive: currSim,
                species: spec,
                criterionValues: crit,
                loading: false,
              });
            }
          )
          .catch((error) => console.error(error));
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      resultsTitleRef.current &&
      resultsActionRef.current &&
      state.simulation?.suggestedAids?.length > 0 &&
      !state.loading
    ) {
      const resultsTitleHeight =
        resultsTitleRef.current.getBoundingClientRect().height;
      const resultsActionHeight =
        resultsActionRef.current.getBoundingClientRect().height;
      const resultsActionBottomMargin = 10;
      const simulationWarningMessageHeight =
        simulationWarningMessageRef.current?.getBoundingClientRect()?.height ||
        0;
      const simulationWarningMessageMargin =
        simulationWarningMessageHeight > 0 ? 20 : 0;

      // 97vh => Hauteur totale (100vh) - Padding bottom dans la partie des logos (3vh)
      // 4rem => Totale de marge haute et basse du titre
      // 3vh => Padding bottom dans le menu sur les logos
      const aidsListHeight = `calc(97vh - ${
        resultsTitleHeight +
        resultsActionHeight +
        resultsActionBottomMargin +
        simulationWarningMessageHeight +
        simulationWarningMessageMargin
      }px - 4rem)`;

      _setState({ aidsListHeight });
    }
    // eslint-disable-next-line
  }, [
    resultsTitleRef.current,
    resultsActionRef.current,
    simulationWarningMessageRef.current,
    state.loading,
    state.simulation?.suggestedAids?.length,
  ]);

  return (
    <section className="simulator flex align-items-start">
      {state.loading && <LoadingOverlay message="Chargement des résultats" />}

      {/* ====================================== ERROR MESSAGE ====================================== */}
      {state.invalid && (
        <SimulatorError
          title="OOPS ! Une erreur est survenue lors de la récupération des résultats"
          secondTitle="Impossible de récupérer les données de la simulation"
          description="Essayez de rafraîchir la page. Si le problème persiste, merci de contacter un administrateur afin de lui remonter le problème"
          redirectingData={{
            route: Routing.app_home,
            title: "Retourner à l'accueil du simulateur",
            text: "Retour à l'accueil du simulateur",
          }}
        />
      )}

      {/* ====================================== RESULTS ====================================== */}

      {state.testLoading && <Loading />}
      {state.displayPDF && (
        <div className="simulator-pdf-container">
          <div className="simulator-pdf">
            <Button
              title="Quitter la vue"
              variant="contained"
              size="small"
              className="simulator-pdf-exit"
              onClick={() => _setState({ displayPDF: false, pdfUrl: "" })}
              children={<CancelIcon />}
            />
            <iframe src={state.pdfUrl} title="PDF file view" />
          </div>
        </div>
      )}

      <Snackbar
        open={state.pdfHasError}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseMessage} severity="error">
          {ERR_MSG_SIMULATION_RESULTS_PDF}
        </Alert>
      </Snackbar>

      {!state.loading && !state.invalid && (
        <>
          <SimulatorQuickView
            simulation={state.simulation}
            currentSimulator={state.simulatorLive}
            species={state.species}
            criterion={state.criterionValues}
            simulatorError={state.simulatorError}
            testLoading={state.testLoading}
            isSandboxMode={isSandboxMode}
            simulatorUuid={simulatorUuid}
            onRequestSetLoading={_handleSetLoading}
          />

          <div className="simulator-results">
            {isSandboxMode && (
              <div className="sandbox-content">
                <div>
                  <Alert
                    icon={false}
                    severity="warning"
                    className="sandbox-banner"
                  >
                    {mode === Constants.SIMULATOR_SANDBOX_MODE
                      ? `Simulateur de test Version ${state.simulatorLive?.versionNumber}`
                      : "Test incluant les versions d'aides non publiées"}
                  </Alert>
                </div>
              </div>
            )}
            <div ref={resultsTitleRef} className="results-title">
              <h1>
                Les <span className="bold">aides possibles</span> auxquelles
                vous pouvez prétendre
              </h1>
            </div>
            <div
              ref={resultsActionRef}
              className="results-actions flex flex-column"
            >
              <div className="action-buttons flex">
                <LoadingButton
                  loading={state.pdfLoading}
                  loadingPosition="start"
                  onClick={_downloadPDF}
                  startIcon={<PictureAsPdfIcon />}
                  title="Télécharger le détails des aides auxquelles vous pouvez prétendre"
                >
                  Télécharger le récapitulatif
                </LoadingButton>

                {!isSandboxMode && (
                  <Tooltip
                    title={
                      !props.withCookie
                        ? "Pour accéder à cette fonctionnalité, vous devez accepter les cookies."
                        : ""
                    }
                  >
                    <div>
                      <LoadingButton
                        onClick={handleClickSaveResults}
                        loadingPosition="start"
                        loading={state.saveSimulation}
                        disabled={!props.withCookie}
                        startIcon={<SaveIcon />}
                        title="Sauvegarder ces résultats dans votre compte pour les retrouver plus tard !"
                      >
                        Sauvegarder les résultats
                      </LoadingButton>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            {state.simulation?.suggestedAids?.length > 0 &&
              state.maxAlertThreshold >= 0 &&
              state.simulation?.suggestedAids?.length >
                state.maxAlertThreshold &&
              state.minOptionalQuestionsResponsePercentage >= 0 &&
              answeredOptionalQuestionsPercentage <
                state.minOptionalQuestionsResponsePercentage && (
                <div
                  ref={simulationWarningMessageRef}
                  className="simulation-warning-message"
                >
                  <div>
                    <Alert severity="warning">
                      <small>
                        Votre simulation est peut-être trop généraliste. Affinez
                        vos réponses en revenant sur votre simulation afin
                        d'obtenir un résultat plus précis.
                      </small>
                    </Alert>
                  </div>
                </div>
              )}
            <div
              className="results-list"
              style={{ height: `${state.aidsListHeight}` }}
            >
              {AidHelper.getFormattedAids(
                state.simulation?.suggestedAids?.map((element) => {
                  return { ...element.aid, amount: element.amount };
                })
              ).map((data, index) => {
                return data.type === "folder" ? (
                  <AidFolder
                    key={`AidFolder${index + 1}`}
                    isResult={true}
                    folder={data}
                    species={state.species}
                    onRequestFolderDisplay={() => {}}
                  />
                ) : (
                  <AidRow
                    key={`AidRow${index + 1}`}
                    isResult={true}
                    isOutOfFolder={true}
                    aid={data}
                    species={state.species}
                  />
                );
              })}
            </div>
          </div>

          <Dialog open={state.openAuthDialog} onClose={handleClose}>
            <DialogContent>
              <Login
                pageType="login"
                showSimulationLink={false}
                simulation={simulationId.current}
                loadingType="simple"
                withCookie={props.withCookie}
                redirectCallBack={(user) => {
                  if (user.id) {
                    Api.simulation
                      .setSimulationRegistered(simulationId.current)
                      .then(() => {
                        props.onLogin();
                        handleClose();
                      });
                    _setState({ showMessage: true });
                  }
                }}
              />
            </DialogContent>
          </Dialog>

          <AidDetailDialog
            isOpened={state.openAidDialog}
            aidDetail={state.aidDetail}
            species={state.species}
            onRequestDialogClose={handleAidDialogClose}
          />

          <Snackbar
            open={state.showMessage}
            autoHideDuration={6000}
            onClose={handleCloseMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleCloseMessage} severity="success">
              La simulation a été sauvegardée et pourra être consultée dans
              votre compte utilisateur
            </Alert>
          </Snackbar>
        </>
      )}
    </section>
  );
}

function SimulatorQuickView(props) {
  const [mobileDisplay, setMobileDisplay] = useState(
    window.matchMedia(MEDIAQUERY_MOBILE).matches
  );
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isVersionMatch, setVersionMatch] = useState(false);
  const [cloneLoading, setCloneLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [newSimulationId, setNewSimulationId] = useState(null);
  const simulatorMenuCloneRef = useRef(null);
  const mySimulationRef = useRef(null);
  const menuFooterRef = useRef(null);

  useEffect(() => {
    setSteps(_.orderBy(props.simulation.simulator.steps, ["position"], "asc"));
    setVersionMatch(
      props.currentSimulator.id === props.simulation.simulator.id
    );

    const _handleResize = () => {
      let mql = window.matchMedia(MEDIAQUERY_MOBILE);
      if (mql.matches) {
        // if media query matches
        setMobileDisplay(true);
      } else {
        setMobileDisplay(false);
      }
    };
    window.addEventListener("resize", _handleResize, false);
    return function () {
      window.removeEventListener("resize", _handleResize, false);
    };
  }, [props.simulation, props.currentSimulator.id]);

  const _handleStepClick = (stepPosition) => {
    setActiveStep(stepPosition - 1);
  };

  const _getValueString = (answer) => {
    const specificCriterion = ["criPlan_01"];
    if (_.includes(specificCriterion, answer.question.criterion.shortName)) {
      if (answer.question.criterion.shortName === "criPlan_01") {
        let speciesList = "";
        _.each(answer.value.answers, (a) => {
          speciesList += `${a.label}, `;
        });
        return speciesList;
      }
    }
    if (answer.type === CRITERION_TYPE_LOC) {
      return answer.value.answer;
    }
    if (answer.type === CRITERION_TYPE_DTE) {
      return moment(answer.value.answer).format("DD/MM/YYYY");
    }
    if (answer.type === CRITERION_TYPE_NUM) {
      return `${answer.value.value} ${answer.question.criterion.unit}`;
    }
    if (answer.type === CRITERION_TYPE_OBG) {
      return QuestionHelper.getObgText(answer.question, answer.value.answer);
    }
    if (answer.type === CRITERION_TYPE_TXT) {
      if (answer.question.criterion.multi) {
        let answersList = "";
        _.each(answer.value.answers, (a) => {
          answersList += `${_.find(props.criterion, { id: a })?.value}, `;
        });
        return answersList;
      } else {
        return _.find(props.criterion, { id: answer.value.answer })?.value;
      }
    }
  };

  const _cloneSimulationAndRedirectToSimulator = () => {
    setCloneLoading(true);
    Api.simulation.cloneSimulation(props.simulation.id).then((response) => {
      const newSimulation = Helper.isValidResponse(response);
      if (newSimulation) {
        // Overwrite simulation cookie with the cloned simulation
        if (!props.isSandboxMode) {
          Helper.saveRunningSimulation(
            newSimulation.id,
            newSimulation.simulator,
            moment().format(),
            newSimulation.simulationAnswers?.length,
            1,
            1,
            SIMULATION_STATUS_IN_PROGRESS
          );
        } else {
          setNewSimulationId(newSimulation.id);
        }
        setRedirect(true);
      } else {
        //TODO: better error handling with specific http codes and messages
        Helper.displayErrorMessage(
          "Désolé, il est impossible de dupliquer la simulation actuelle",
          "warning"
        );
        setCloneLoading(false);
      }
    });
  };

  const _handleAchieveTest = () => {
    props.onRequestSetLoading(true);
    Api.simulator
      .achieveSandboxTest(props.simulatorUuid)
      .then((response) => {
        const result = Helper.isValidResponse(response);

        if (result) {
          window.close();
        }

        props.onRequestSetLoading(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="simulator-menu menu-results">
      {redirect && (
        <>
          {props.isSandboxMode ? (
            <Navigate
              to={`${Routing.simulator_sand_box_mode}?simulationId=${newSimulationId}&uuid=${props.simulatorUuid}`}
            />
          ) : (
            <Navigate to={`${Routing.simulator}?resSim=1`} replace={true} />
          )}
        </>
      )}
      {cloneLoading && (
        <LoadingOverlay message="Création de la simulation en cours..." />
      )}
      {props.isSandboxMode ? (
        <div className="finishing-simulation-button-content">
          <Button
            disabled={props.testLoading}
            variant="contained"
            children="Terminer le test"
            onClick={_handleAchieveTest}
          />
        </div>
      ) : (
        <div className="menu-back">
          <NavLink to={Routing.app_home}>
            <Button
              variant="outlined"
              size="small"
              title="Retourner à l'accueil du simulateur"
              startIcon={<ArrowBackIosIcon />}
            >
              Retour à l'accueil
            </Button>
          </NavLink>
        </div>
      )}
      <div ref={mySimulationRef} className="menu-title">
        <h3>Ma simulation</h3>
      </div>
      {props.simulatorError && (
        <div className="menu-error-message">
          <Alert severity="error">
            Désolé, une erreur est survenue lors du chargement du menu.
            Réessayez et veuillez avertir l'administrateur si le problème
            persiste.
          </Alert>
        </div>
      )}

      <Stepper
        className="menu-list"
        role="menu"
        activeStep={activeStep}
        orientation={mobileDisplay ? "horizontal" : "vertical"}
        style={{
          maxHeight: `calc(100vh - 10vh - ${simulatorMenuCloneRef.current?.offsetHeight}px - ${mySimulationRef.current?.offsetHeight}px - ${menuFooterRef.current?.offsetHeight}px)`,
        }}
      >
        {steps?.map((step, key) => (
          <Step
            role="menuitem"
            key={step.position}
            title={`Accéder à la catégorie ${step.name}`}
            className={`menu-item ${
              step.position === activeStep + 1 ? "step-active" : ""
            }`} //+1 because position 1= index 0
            onClick={() => _handleStepClick(step.position)}
          >
            <StepLabel>{step.name}</StepLabel>
            {!mobileDisplay && ( // content can only work with vertical stepper
              <StepContent className="simulator-menu-content">
                {_.map(props.simulation.simulationAnswers, (a) => {
                  if (a.question.step.position === step.position) {
                    return (
                      <div
                        className="simulator-menu-content-item flex flex-column"
                        key={a.id}
                        title={a.question.questionText}
                      >
                        <span>{a.question.title}</span>
                        <span>{_getValueString(a)}</span>
                      </div>
                    );
                  }
                })}
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>

      <div
        ref={simulatorMenuCloneRef}
        className="simulator-menu-clone"
        title="Modifier la simulation"
      >
        <Button
          variant="contained"
          size="small"
          disabled={!isVersionMatch}
          onClick={_cloneSimulationAndRedirectToSimulator}
        >
          Nouvelle simulation à partir de ces réponses
        </Button>
      </div>

      <div ref={menuFooterRef} className="menu-logo">
        <img src="/img/logo-fsy.png" alt="Fransylva logo" />
        <b style={{ marginLeft: "1rem" }}>
          <small>Financé par</small>
        </b>
        <img src="/img/logo-fbf2.png" alt="France Bois Forêt logo" />
      </div>
    </div>
  );
}
