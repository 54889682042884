import React, {useState} from "react"
import {TextField} from "@mui/material"
import { ENTER_KEY_LABEL, NINE_CHAR_CODE, ZERO_CHAR_CODE } from "../../../services/Constants"

export default function AnswersLOC({question, onChange, defaultValue = null, nextButtonRef}) {
    const [state, setState] = useState({
        question: question,
        shortName: question.criterion.shortName,
        answer: defaultValue !== null ? defaultValue.answer : "",
        hasChanged: false
    })
    const postalCodeRegex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/

    const _setState = values => {
        setState(previousState => {
            return {...previousState, ...values}
        })
    }

    const _handleInputChange = event => {
        const newValue = event.target.value.slice(0, 5)
        _setState({answer: newValue, hasChanged: true})
        onChange(newValue)
    }

    const _handleKeyDown = event => {
        if(event.key === ENTER_KEY_LABEL && state.answer && nextButtonRef){
            nextButtonRef.current.click()
        }
    }

    const _handleKeyPress = (event) => {
        const { charCode } = event
        const condition = charCode >= ZERO_CHAR_CODE && charCode <= NINE_CHAR_CODE

        if(!condition){
            event.preventDefault()
        }
    }

    /* =================================== Render  =================================== */

    return <TextField
        // label="Code postal"
        title="Code postal sur 5 chiffres"
        placeholder="ex: 75001"
        className="text-input"
        value={state.answer}
        size="small"
        autoFocus
        required={state.question.mandatory}
        onChange={_handleInputChange}
        inputProps={{pattern: postalCodeRegex}}
        onKeyDown={_handleKeyDown}
        onKeyPress={_handleKeyPress}
    />
}