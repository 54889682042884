import React, {useEffect, useState} from "react"
import {
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from "@mui/material"
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown"
import {CRITERION_DISPLAY_SELECT} from "fsy.common-library/lib/env/Constants"
import InfoIcon from "@mui/icons-material/Info"
import _ from "lodash"
import {MEDIAQUERY_MOBILE} from "../../../services/Constants"
import {SelectField} from "../../general/form/Select";

export default function AnswersTXT({question, onChange, isMulti = false, defaultValue = null, answersFormContentRef}) {
    const [mobileDisplay, setMobileDisplay] = useState(window.matchMedia(MEDIAQUERY_MOBILE).matches)
    const [state, setState] = useState({
        question: question,
        displayMode: question.criterion.displayMode,
        isMulti: isMulti,
        shortName: question.criterion.shortName,
        answers: [],
        selectedAnswers: question.criterion.displayMode === CRITERION_DISPLAY_SELECT ? (isMulti ? [] : "") : [],
        selectedAnswerOptions: question.criterion.displayMode === CRITERION_DISPLAY_SELECT ? (isMulti ? [] : "") : [],
        displayArrow: false,
        environmentIsTablet: window.matchMedia('(hover: none)').matches,
        tooltipOpen: {}
    })

    const _setState = values => {
        setState(previousState => {
            return {...previousState, ...values}
        })
    }

    useEffect(compareFn => {
        let answers = []
        const criterion = question.availableAnswers ? question.availableAnswers : question.criterion.criterionValues

        _.each(criterion, v => {
            if (v.active) {
                const checked = isMulti ? _.includes(defaultValue?.answer, v.id) : defaultValue?.answer === v.id
                answers[v.id] = {
                    id: v.id,
                    value: v.id,
                    checked: checked,
                    text: v.value,
                    label: v.value,
                    info: v.info,
                    position: v.position
                }
            }
        })

        answers = answers.sort((a, b) => {
            return a.position - b.position
        })

        _setState({
            answers: answers,
            displayMode: question.criterion.displayMode
        })

        if (defaultValue) {
            _setState({
                selectedAnswers: isMulti ? (defaultValue.answers ? defaultValue.answers : []) : defaultValue.answer
            })
        } else {
            _setState({
                selectedAnswers: question.criterion.displayMode === CRITERION_DISPLAY_SELECT ? (isMulti ? [] : "") : []
            })
        }
    }, [question, defaultValue, isMulti])

    useEffect(() => {
        _setState({isMulti: isMulti}) //Fix refresh bug with 'isMulti' value
    }, [isMulti])

    useEffect(() => {
        const _handleResize = () => {
            let mql = window.matchMedia(MEDIAQUERY_MOBILE)
            if (mql.matches) { // if media query matches
                setMobileDisplay(true)
            } else {
                setMobileDisplay(false)
            }
        }

        window.addEventListener("resize", _handleResize, false)

        return function () {
            window.removeEventListener("resize", _handleResize, false)
        }
    }, [])

    useEffect(() => {
        const answersBox = document.querySelector(".simulator-answersList")
        //TODO: Change value here (0.4 or 0.5 for mobile) according to height set in "simulator-answersList" css class
        _setState({displayArrow: answersBox?.scrollHeight > window.innerHeight * (mobileDisplay ? "0.5" : "0.5")})
    }, [state.answers, mobileDisplay])

    const _handleAnswerChange = (event, selectedAnswers) => {
        if(selectedAnswers?.length === 0){
            selectedAnswers = null
        }

        _setState({selectedAnswers: selectedAnswers})
        onChange(selectedAnswers)
    }

    const _handleAnswerChangeSelect = (event) => {
        const answers = _.isArray(event) ? event.map(v => v.id) : event.id

        _setState({selectedAnswers: answers})
        onChange(answers)
    }

    const _handleDisplayTooltip = (type, id) => {
        _setState({ 
            tooltipOpen: {
                ...state.tooltipOpen,
                [id]: !state.environmentIsTablet ? type === 'open' : false
            }
        })
    }

    const _handleInfoIconClick = (event, id) => {
        event.stopPropagation()

        _setState({ 
            tooltipOpen: {
                ...state.tooltipOpen,
                [id]: !state.tooltipOpen[id]
            }
        })
    }

    /* =================================== Render  =================================== */
    if (state.displayMode === CRITERION_DISPLAY_SELECT) {
        const selectedAnswerOptions = state.answers.filter(a => {
            return _.isArray(state.selectedAnswers) ? state.selectedAnswers.includes(a.id) : state.selectedAnswers === a.id
        })

        return <div className="flex flex-column align-items-start answer-txt">
            <SelectField
                className="w-100"
                options={state.answers}
                isMulti={state.isMulti}
                value={selectedAnswerOptions}
                placeholder=""
                closeMenuOnSelect={!state.isMulti}
                onChange={_handleAnswerChangeSelect}
            />
        </div>
    }

    return <>
        <ToggleButtonGroup ref={answersFormContentRef} orientation="vertical" value={state.selectedAnswers} exclusive={!state.isMulti}
                           onChange={_handleAnswerChange} className="simulator-answersList"
                           aria-label="answers" fullWidth
                           title={state.displayArrow ? "Défilez vers le bas pour voir toutes les réponses" : ""}>
            {_.map(state.answers, item => {
                    if (item !== undefined) {
                        return (
                            <ToggleButton key={item.id} value={item.id} size="small">
                                {item.text}
                                {
                                    item.info !== null && (
                                        <Tooltip
                                            open={Boolean(state.tooltipOpen[item.id])}
                                            onOpen={() => _handleDisplayTooltip('open', item.id)}
                                            onClose={() => _handleDisplayTooltip('close', item.id) }
                                            title={item.info}
                                            placement="top-end"
                                            className="answer-info"
                                            children={
                                                <InfoIcon 
                                                    onClick={(event) => state.environmentIsTablet ? _handleInfoIconClick(event, item.id) : {}} 
                                                />
                                            }
                                        />
                                    )
                                }
                            </ToggleButton>
                        )
                    }
                }
            )}
        </ToggleButtonGroup>
        {state.displayArrow && <ExpandCircleDownIcon className="simulator-answersScroll"/>}
    </>
}