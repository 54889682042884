import React from "react"
import { useParams } from "react-router-dom"
import _ from "lodash"

export const routes = {
    app_home: "/",
    app_login: "/login",
    app_register: "/register",
    app_logout: "/logout",
    active_user: "/activate",
    activate_user_account: "/activate-account",
    reset_password: "/reset-password",
    user_account: "/account",
    simulator: "/simulator",
    simulator_sand_box_mode: "/simulator-sandbox-mode",
    simulator_test_with_no_published_aid: "/test-with-no-published-aid",
    simulator_help: "/simulator/help",
    simulator_results: "/simulator/results",
    aids_catalogs: "/aids-catalogs",
    research: "/research",
    aid_detail: "/aid/:id",
    privacy_policy: "/privacy-policy"
}

/**
 * Return a route string url with parameters
 *
 * @param route {string} - the original route with param
 * @param params {object}
 * @return {string} - the formatted route
 */
export function setRouteParameters(route, params) {
    for (const [key, value] of Object.entries(params)) {
        route = route.replace(`:${key}`, value)
    }
    return route
}

export const withRouter = WrappedComponent => props => {
    const params = useParams()

    return (
        <WrappedComponent
            {...props}
            params={params}
        />
    )
}


/**
 *
 * @param path
 * @return {boolean}
 */
export function hasMenuRoute(path) {
    const routesWithoutMenu = ["/login", "/register", "/logout", "/activate", "activate-account", "/reset-password"]
    return !_.includes(routesWithoutMenu, path)
}